<template>
  <!-- 底图 -->
  <div class="basemap">
    <img
      class="baseimg"
      src="../../../assets/img/imgdevelopment/moderntimes/basemap.jpg"
      alt=""
    />
    <!-- 设置按钮 -->
    <div class="set" @click="show = !show" style="cursor: pointer">
      <img
        src="../../../assets/img/imgdevelopment/moderntimes/set.png"
        alt=""
      />
    </div>
    <!-- 对照表 -->
    <div style="display: flex">
      <transition name="el-fade-in-linear">
        <div v-show="show" class="transition-box">
          <img
            src="../../../assets/img/imgdevelopment/moderntimes/form.png"
            alt=""
            style="
              height: 36%;
              position: absolute;
              left: 83%;
              width: 14%;
              top: 14%;
              z-index: 100;
            "
          />
        </div>
      </transition>
    </div>
    <!-- 摩斯密码按钮 -->
    <div class="Buttonsbox">
      <img
        src="../../../assets/img/imgdevelopment/moderntimes/dot.png"
        alt=""
        @click="imgelement('.')"
      />
      <img
        src="../../../assets/img/imgdevelopment/moderntimes/across.png"
        alt=""
        @click="imgelement2('-')"
      />
      <img
        src="../../../assets/img/imgdevelopment/moderntimes/empty.png"
        alt=""
        @click="imgelement3('_')"
      />
      <img
        src="../../../assets/img/imgdevelopment/moderntimes/slash.png"
        alt=""
        @click="imgelement4('/')"
      />
      <img
        src="../../../assets/img/imgdevelopment/moderntimes/delete.png"
        alt=""
        @click="imgelement5()"
      />
    </div>
    <!-- 打印机 -->
    <div class="printer">
      <img :src="imgsrc" alt="" />
    </div>
    <!-- 打字机 -->
    <div class="typewriter">
      <img :src="imgsrcb" alt="" />
      <div
        class="typewriterb"
        v-show="writer"
        v-for="(item, index) in picturelist"
        :key="index"
      >
        {{ typewritertext }}
      </div>
    </div>
    <!-- 卡通人 -->
    <div class="cartoon">
      <img
        src="../../../assets/img/imgdevelopment/moderntimes/cartoon.png"
        alt=""
      />
    </div>
    <!-- 消息框 -->
    <div class="message">
      <img :src="imgsrcbox" alt="" />
      <!-- 对话框卡片 -->
      <el-card class="box-card">
        <textarea
          class="box-cardb"
          name=""
          id=""
          cols="30"
          rows="5"
          maxlength="200"
          v-model="cardcontent"
          readonly
        ></textarea>
      </el-card>
      <span class="zifu" v-show="hint">可以输入200个字符</span>

      <!-- 发消息 -->
      <div class="messagea" ref="chatContent">
        <div
          v-show="writer"
          v-for="(item, index) in picturelist"
          :key="index"
          class="incotext"
        >
          <div style="width: 100%">
            <img
              class="imginco"
              v-show="writer"
              src="../../../assets/img/imgdevelopment/moderntimes/head2.png"
              alt=""
            />
            <el-tooltip
              class="item"
              effect="light"
              :content="lefttip"
              placement="top"
              transition="true"
              hide-after="500"
            >
              <span class="incoleft">{{ item.left }}</span>
            </el-tooltip>
          </div>
          <div style="width: 100%">
            <img
              class="imaginco"
              style="position: relative; left: 0%; float: right"
              v-show="writer"
              src="../../../assets/img/imgdevelopment/moderntimes/head.png"
              alt=""
            />
            <el-tooltip
              class="item"
              effect="light"
              :content="righttip"
              placement="top"
              transition="true"
              hide-after="500"
            >
              <span class="incoright">{{ item.right }}</span>
            </el-tooltip>
          </div>
        </div>
      </div>

      <!-- 发送按钮 -->
      <el-button
        type="success"
        class="send"
        :disabled="disa"
        @click="buttonsend"
      >
        <span style="position: absolute; top: 20%; right: 32%"
          >发&nbsp;&nbsp;&nbsp;&nbsp;送</span
        >
      </el-button>
    </div>

    <div>
      <!-- 小唐人logo -->
      <img
        class="dialong3"
        @click="showif"
        src="../../../assets/img/imgdevelopment/ancientpeople/logo.png"
        alt=""
      />
      <!-- 小唐人弹出框 -->
      <div class="logodialogue" v-show="dialogVisible">
        <span>
          近代人们发现电脉冲可在短时间内沿铜线远距离传输，这种信息传递方式的典型应用就是电报，有线电报的出现具有划时代的意义。请你作为一名军事电报员通过查询界面右侧的摩尔斯电码表，向作战指挥部发送摩尔斯电码，传递电报信息。为保证电报正确接收，每条报文需要重复发送<span
            style="color: red"
            >2次</span
          >！
          <h3>电报内容为:</h3>
          <h2>({{ hinttext.question_en }})</h2>
          发送方式：<img
            style="width: 4%"
            align="absbottom"
            src="../../../assets/img/imgdevelopment/moderntimes/dot.png"
            alt=""
          />代表电码表中的点，<img
            style="width: 4%"
            align="absbottom"
            src="../../../assets/img/imgdevelopment/moderntimes/across.png"
            alt=""
          />代表电码表横杠，<img
            style="width: 4%"
            align="absbottom"
            src="../../../assets/img/imgdevelopment/moderntimes/empty.png"
            alt=""
          />代表空格，<img
            style="width: 4%"
            align="absbottom"
            src="../../../assets/img/imgdevelopment/moderntimes/slash.png"
            alt=""
          />代表换行，每个字母之间需要一个空格来划分，每个单词之间需要一个换行划分。
        </span>
        <div class="logodialoguex"></div>
      </div>
    </div>
    <!-- 结束 -->
    <!-- <div class="logodialogue" v-show="indialogVisible">
      <span>
        &nbsp; &nbsp; &nbsp; &nbsp;
        1844年5月24日举行了电报机启用仪式，莫尔斯在国会议事厅里发出了世界上第一封电报”What
        hath God wrought
        “。有线电报的出现具有划时代的意义——它让人类获得了一种全新的信息传递方式，把人们想要传递的信息以每秒30万公里的速度传向远方。这种方式“看不见”、“摸不着”、“听不到”，完全不同于以往的书信、锣鼓、烽火。
        然而发送电报的手续非常繁杂，我们要先拟好电报稿，再译成电码，发送出去，收到报文后把再把电码译成英文。单向的电报每做一次收发都需要等很长时间，不能进行即时的通信，双方如何才能进行双向实时的通信呢？
      </span>
      <div class="logodialoguex"></div>
    </div> -->

    <!-- 答题全部正确时弹框 -->
    <el-dialog
      v-model="dialogVisibleshow"
      title="小唐人提示"
      width="30%"
      :before-close="handleClose"
    >
      <img style="width: 50%; margin-left: 24%" :src="whether" alt="" />
      <hr style="width: 90%; background-color: #e4e4e3; margin: 10px auto" />
      <p
        style="
          font-size: 20px;
          text-align: center;
          color: #0074c5;
          margin: 16px;
        "
      >
        <span
          >您答题次数为<span style="color: red">{{ sum }}</span
          >次,</span
        >
        <span
          >正确次数为<span style="color: red">{{ num }}</span
          >次,</span
        >
        <span
          >错误次数为<span style="color: red">{{ sumerr }}</span
          >次!</span
        >
      </p>
      <h3 style="margin: 2%" v-if="showifse">
        <p style="color: #ec4515; margin-left: 12.5%;font-size: calc(100vw * 20 / 1920);">电报通信优点：</p>
        <p style="margin-left: 40%; margin-top: -4%">
          实现信息以每秒30万公里的速度传输。看不见、摸不着、听不到
        </p>
      </h3>
      <h3 v-if="showifse">
        <span style="color: #40b489; margin-left: 14%;font-size: calc(100vw * 20 / 1920);">电报通信缺点：</span>
        <p style="margin-left: 40%; margin-top: -4%">
          发送电报手续繁杂，双方不能进行及时进行双工通信
        </p>
      </h3>

      <template #footer>
        <span class="dialog-footer">
          <el-button
            style="
              background: #0074c5;
              width: 28%;
              margin: auto;
              display: block;
            "
            type="primary"
            @click="confirmbut"
            >确&ensp;&ensp;&ensp;定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { getModern } from "../../../api/topics";
import { getModerntimes } from "../../../api/topics";
import { readCommunication } from "../../../api/topics";
import { holographic } from "../../../api/topics";

export default {
  data() {
    return {
      whether: require("../../../assets/img/imgdevelopment/ancientpeople/failure.png"),
      dialogVisible: true,
      finishshow: false, //结束按钮
      dialogVisibleshow: false, //弹框显示隐藏
      indialogVisible: false, //结束语显示隐藏
      show: true,
      beginshow: true,
      imgsrc: require("../../../assets/img/imgdevelopment/moderntimes/typewriter.png"),
      imgsrcb: require("../../../assets/img/imgdevelopment/moderntimes/typewriter00.png"),
      imgsrcbox: require("../../../assets/img/imgdevelopment/moderntimes/messagebox.png"),
      sendshow: false,
      writer: false,
      cardcontent: "", //发送的内容
      kky: "",
      lefttip: "", //左提示
      righttip: "", //右提示
      picturelist: [],
      hinttext: [], //获取接口数据
      typewritertext: "",
      disa: false, //发送按钮禁用
      hint: true, //字符提示
      question: "0", //能够翻译
      answer: "0", //答题正确
      sum: "0", //点击发送次数总数
      sumerr: "0", //发送失败
      num: "0", //发送成功
      showifse: false, //优缺点
    };
  },

  components: {},
  // 页面初始化请求
  mounted() {
    // getModern().then((res) => {
    //   this.hinttext = JSON.parse(res.data.data); //这个是正确的转换
    // });
    this.scrollToBottom();
    //调用接口
    this.interface();
  },
  methods: {
    //接口数据
    interface() {
      let datatimes = new FormData();
      datatimes.append("user_id", this.$store.state.user_id);
      datatimes.append("data_sign", "communication_history");
      datatimes.append("task_id", this.$store.state.taskId);
      datatimes.append("template_id", this.$store.state.template_id);
      datatimes.append("value_sign", "early_communication");
      readCommunication(datatimes).then((res) => {
        this.hinttext =res.data.data; //这个是正确的转换
        if (this.hinttext.input_mose == null) {
          this.hinttext.input_mose = "";
        }
        console.log(this.hinttext);
        // console.log(this.cardcontent);
        this.cardcontent = this.hinttext.input_mose;
      });
    },
    //点击小唐人
    showif() {
      this.dialogVisible = !this.dialogVisible;
      this.indialogVisible = false;
      this.beginshow = false;
      this.sendshow = true;
      this.finishshow = true;
      this.imgsrc = require("../../../assets/img/imgdevelopment/moderntimes/typewriter001.gif");
      if(this.sum == '0'){
      this.imgsrcb = require("../../../assets/img/imgdevelopment/moderntimes/typewriter002.gif");
      }
    },
    //小唐人重新开始
    finishclick() {
      this.$router.go(0);
    },
    // 小唐人弹框确定按钮
    confirmbut() {
      this.dialogVisibleshow = false;
      this.dialogVisible = false;

      if(this.num == 2){
      let data = "Replay?4;"
      let obj = new FormData();
      obj.append("data", data);
      holographic(obj).then((res) => {
        console.log(res)
      });
      }

    },
    // 点击发送按钮
    buttonsend() {
      if (
        this.cardcontent.length != 0 &&
        this.cardcontent != "/" &&
        this.cardcontent != " "
      ) {
        this.sum++;
        this.hinttext.input_mose = this.cardcontent;
        // console.log(this.hinttext)
        //转换接口传输格式
        let datatimes = new FormData();
        datatimes.append("data", JSON.stringify(this.hinttext));
        //调用接口
        getModerntimes(datatimes).then((res) => {
          const obj = JSON.parse(res.data.data);
          if (JSON.parse(res.data.data).question_sign == true) {
            this.question++;
            // console.log(this.question);
          }
          if (JSON.parse(res.data.data).answer_sign == true) {
            this.answer++;
            // console.log(this.answer);
          }
          // 调用VUEX保存数据
          this.$store.commit("sum_modern", [
            this.question,
            this.answer,
            this.hinttext,
          ]);
          this.picturelist.push({
            left: obj.input_en,
            right: obj.output_en,
          });
          // console.log(this.picturelist);
          this.lefttip = obj.input_mose;
          this.righttip = obj.output_mose;
          this.typewritertext = obj.input_en;
          // console.log(this.typewritertext);
          if (
            obj.output_en != "error" &&
            obj.output_en != "" &&
            obj.output_en != null
          ) {
            this.num++;
            this.whether = require("../../../assets/img/imgdevelopment/ancientpeople/succeed.png");
            this.showifse = true;
          } else if (
            obj.output_en == "error" ||
            obj.output_en == "" ||
            obj.output_en == null
          ) {
            this.whether = require("../../../assets/img/imgdevelopment/ancientpeople/failure.png");
            this.sumerr++;
            this.showifse = false;
          }
        });
        this.writer = true;
        localStorage.setItem("insuranceCode", JSON.stringify(this.cardcontent));
        this.cardcontent = "";
        this.kky = JSON.parse(localStorage.getItem("insuranceCode"));
        this.imgsrcb = require("../../../assets/img/imgdevelopment/moderntimes/typewriter22.png");
        this.imgsrcbox = require("../../../assets/img/imgdevelopment/moderntimes/messagebox2.png");
        // this.beginshow = true;
        setTimeout(() => {
          this.dialogVisibleshow = true;
        }, 3000);
        // this.disa = true;
      }
    },
    // 点击图片按钮
    imgelement(val) {
      if (this.cardcontent.length < 200) {
        this.cardcontent = this.cardcontent + "" + val;
        this.hint = false;
      }
    },
    imgelement2(val) {
      if (this.cardcontent.length < 200) {
        this.cardcontent = this.cardcontent + "" + val;
        this.hint = false;
      }
    },
    imgelement3(val) {
      if (this.cardcontent.length < 200) {
        this.cardcontent = this.cardcontent + "" + val;
        this.hint = false;
      }
    },
    imgelement4(val) {
      if (this.cardcontent.length < 200) {
        this.cardcontent = this.cardcontent + "" + val;
        this.hint = false;
      }
    },
    imgelement5() {
      if (this.cardcontent.length < 200 && this.cardcontent.length > 0) {
        this.cardcontent = this.cardcontent.slice(0, -1);
      } else {
        this.hint = true;
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
      });
    },
  },

  updated() {
    this.scrollToBottom();
  },
};
</script>
<style  scoped lang="scss">
.basemap {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
}
/* 底图 */
.baseimg {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
/* 小唐人 */
.dialong3 {
  position: absolute;
  left: 1.5%;
  top: 4%;
  width: 6%;
  cursor: pointer;
}
/* 设置按钮 */
.set > img {
  width: 6%;
  // height: 12%;
  position: absolute;
  float: right;
  top: 2%;
  right: 1%;
}
.begin {
  display: inline;
  width: 100%;
  height: 100%;
}
/* 开始按钮 */
.begin > span {
  width: 10%;
  height: 6%;
  float: right;
  position: absolute;
  top: 7%;
  right: 7%;
  cursor: pointer;
}
/* 打印机 */
.printer > img {
  width: 22%;
  height: 18%;
  position: absolute;
  left: 16%;
  top: 28%;
}
/* 打字机 */
.typewriter > img {
  width: 23%;
  height: 45%;
  position: absolute;
  left: 60%;
  top: 12%;
}
/* 打字机字条 */
.typewriterb {
  width: 8%;
  height: 15%;
  display: inline-block;
  position: absolute;
  /* border: 1px solid #000; */
  left: 67.5%;
  top: 15.8%;
  font-size: 12px;
  color: rgb(61, 61, 61);
  font-weight: 500;
}
/* 卡通人 */
.cartoon > img {
  width: 16%;
  height: 61%;
  position: absolute;
  left: 70%;
  top: 37%;
}
/* 消息框 */
.message > img {
  width: 56%;
  height: 52%;
  position: absolute;
  left: 7%;
  top: 45%;
  z-index: 10;
}
/* 按钮框 */
.Buttonsbox {
  position: absolute;
  left: 7%;
  top: 16%;
  display: inline-block;
}
/* 按钮 */
.Buttonsbox > img {
  width: 11%;
  margin-left: 4%;
  cursor: pointer;
}
/* 对话框卡片 */
.box-card {
  width: 52%;
  height: 21.6%;
  display: inline-block;
  border-radius: 10px;
  z-index: 100 !important;
  position: absolute;
  left: 9%;
  top: 68%;
}
.box-cardb {
  width: 98%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
  left: 12px;
  top: 0;
  border: none;
  font-size: 48px;
  font-weight: 900;
  color: #000;
  outline: none;
}
/* 发送按钮 */
.send {
  width: 8%;
  height: 4%;
  position: absolute;
  z-index: 100;
  left: 52.6%;
  bottom: 5%;
  background: #058f4a;
}
/* 字符提示 */
.zifu {
  font-size: 14px;
  font-weight: 500;
  color: gray;
  position: absolute;
  top: 86%;
  left: 52%;
  z-index: 100;
}
/* 对话框A */
.messagea {
  width: 52%;
  height: 23%;
  // background: rgba(255, 39, 39, 0.3);
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
  position: absolute;
  z-index: 100;
  left: 9%;
  top: 45%;
  border-radius: 10px;
  font-size: 16px;
  color: rgba(2, 2, 2, 0.6);
  font-weight: 600;
}

.incotext {
  width: 100%;
  height: 100%;
  font-size: 18px;
}
// 奇数
.incoleft {
  padding: 4px;
  background: white;
  position: relative;
  top: 0%;
  left: 0%;
  margin: 10px 10px;
  border-radius: 4px;
  text-align: left;
  line-height: 34px;
  animation-name: mymove;
  animation-duration: 2s;
  animation-timing-function: linear;
}
@keyframes mymove {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.imginco {
  animation-name: mymove;
  animation-duration: 2s;
  animation-timing-function: linear;
}
.imaginco {
  animation-name: mymove2;
  animation-duration: 4s;
  animation-timing-function: linear;
}

//偶数
.incoright {
  float: right;
  padding: 4px;
  position: relative;
  background: #85cbf5;
  top: 0%;
  left: 0%;
  margin: 10px 10px;
  border-radius: 4px;
  text-align: right;
  line-height: 34px;
  animation-name: mymove2;
  animation-duration: 4s;
  animation-timing-function: linear;
}
@keyframes mymove2 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
/deep/ .el-dialog__header {
  background: #d7edfb !important;
  margin-right: 0 !important;
}
/deep/ .el-button {
  /* padding: 0; */
  color: rgb(255, 255, 255);
}
/* 滚动条样式 */
div::-webkit-scrollbar {
  height: 6px;
  width: 4px;
}
div::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #667bb4;
}
div::-webkit-scrollbar-track {
  background: skyblue;
}

.logodialogue {
  position: absolute;
  right: 49.5%;
  top: 11%;
  width: 40%;
  background-color: #d7edfb;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 12px;
  font-size: 16px;
  letter-spacing: 1px;
  z-index: 999;
  text-indent: 2em;
}
.logodialoguex {
  position: absolute;
  top: 10%;
  right: 100%;
  width: 0px;
  height: 0px;
  border-top: 10px solid #d7edfb;
  border-left: 10px solid #d7edfb;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transform: rotateY(180deg); /* 垂直镜像翻转 */
}
.logodialogue > span > h2 {
  color: red;
  font-size: calc(100vw * 30 / 1920);
}

/* 滚动条样式 */
textarea::-webkit-scrollbar {
  height: 6px;
  width: 4px;
}
textarea::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #667bb4;
}
textarea::-webkit-scrollbar-track {
  background: skyblue;
}
h3{
  font-size: calc(100vw * 16 / 1920);
}
h3>span{
  font-size: 16px;
}
h3>p{
  font-size: calc(100vw * 16 / 1920);
}
*{
   font-size: calc(100vw * 20 / 1920);
}
</style>
