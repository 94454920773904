<template>
  <div>
        <div class="sweepingRobotProgressBarBorder">
            <div class="ChildElement">
                <span > %{{sweepingRobotProgressBarName}}</span>
            </div>
        </div>
  </div>
</template>
<script>
    export default {
        name: "progressBar",
        data() {
            return {
                }
            },
            props:{
                sweepingRobotProgressBarName:String,
            }
   
    }
</script>
<style  scoped>
/* 进度条样式 */
.sweepingRobotProgressBarBorder{
   /* width: 10%; */
    height: 10px;
    position: relative;
    top: 0;
    left: -20px;
     background-color: var(--el-color-primary);
     
    border-radius: 10px;
    line-height: 10px;
    color: #fff;
}
/* .sweepingRobotProgressBarBorder .ChildElement{
    height: 8px;
    background-color: var(--el-color-primary);
    border-radius: 10px;
    line-height: 8px;
    color: #fff;
} */
.sweepingRobotProgressBarBorder span{
    position: absolute;
    right: 0;
}
</style>
