import { createRouter, createWebHashHistory } from 'vue-router'
import CognitionView from '../views/CognitionView.vue'
// import Index from '../views/Index.vue'
// import Test from '../views/Test.vue'//ts 引入测试
const routes = [
/*   {
    path:'/',
    component:Test
}, */
/*   {
    path:'/',
    component:Index
},
{
    path:'/detail',
    component:()=> import('../views/Detail.vue')
} */
  {
    path: '/',
    name: 'CognitionView',
    component: CognitionView,
    meta: {
      keepAlive: true, //此组件需要被缓存
  }
  },
  {
    path: '/telecom',
    name: 'TelecomView',
    component: () => import(/* webpackChunkName: "about" */ '../views/TelecomView.vue')
  },
  {
    path: '/engineering_view',
    name: 'EngineeringView',
    component: () => import(/* webpackChunkName: "about" */ '../views/EngineeringView.vue')
  },
  {
    path: '/home',
    name: 'home',
    // component: HomeView,
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/Intelligent',
    name: 'Intelligent',
    component: () => import('../views/Intelligent/Intelligent.vue'),
    meta: {
      keepAlive: true, //此组件需要被缓存
    }
  },
  {
    path: '/ancientunication',
    name: 'ancientunication',
    component: () => import('../views/unicatioHomePage/childrenPage/ancientunication.vue')
  },
  {
    path: '/ancient',
    name: 'ancient',
    component: () =>import ('../views/unicatioHomePage/childrenPage/ancient.vue')
},
{
  path: '/original',
  name: 'original',
  component: () =>import ('../views/unicatioHomePage/childrenPage/original.vue')
},
{
  path: '/ancientpage',
  name: 'gudaipage',
  component: () =>import ('../views/unicatioHomePage/childrenPage/ancientpage.vue')
},
{
  path: '/modern',
  name: 'modern',
  component: () =>import ('../views/unicatioHomePage/childrenPage/modern.vue')
},
{
  path: '/modernTimes',
  name: 'modernTimes',
  component: () =>import ('../views/unicatioHomePage/childrenPage/modernTimes.vue')
},
{
  path: '/modernCommuni',
  name: 'modernCommuni',
  component: () =>import ('../views/unicatioHomePage/childrenPage/modernCommuni.vue')
},
{
  path: '/EngineeringPractice',
  name: 'EngineeringPractice',
  component: () =>import ('../views/EngineeringPracticeFolder/EngineeringPractice.vue')
},
{
  path: '/progressBar',
  name: 'progressBar',
  component: () =>import ('../components/common/progressBar.vue')
},

]

const router = createRouter({
  history: createWebHashHistory(),
  routes

})

router.beforeEach((to, from, next) => {
  if(to.name == 'about'){
    sessionStorage.setItem("key", "false");
  }
  if(to.name != 'about'){
    sessionStorage.setItem("key", "true");
  }
next()
})

export default router
