<template>
	<div>
		<div class="Intelligent">
			<img class="backIqmg" src="../../assets/img/imgHomePage/back1.jpg" alt="" />
			<div class="qwerty" v-if="widuis">
				<div></div>
				<span v-if="obj.base_param">
					小唐人提示：红军在长征途中紧急召开一次重要会议。
					请你作为本次会议的指导员快速完成智能作战指挥部通信系统的搭建。
					<p>
						1、 用对讲机调到频道<span>{{ obj.base_param.channel }}</span>
						通知<span>{{ obj.base_param.distance }}</span>公里外（功率1瓦对应1公里传输距离）的电话局搭建电话系统
					</p>
					<p>
						2、 完成电话配置，拨打 <span>{{ obj.base_param.phone_number }}</span>号码通知广电部门安装电视系统和wifi系统
					</p>
					<p>3、 将PAD设备连接到wifi网络，打开蓝牙功能完成扫地机器人联网</p>
					<p>
						4、
						搭建移动通信网络，用手机远程控制扫地机器人清扫会议室，保障会议顺利召开。
					</p>
				</span>
			</div>
			<div class="preservationClassName" @click="check">校 验</div>
			<div class="checkclassName" @click="preservationBtnName(1)">保 存</div>
			<div class="returnClassName" @click="returnHome">返 回</div>
			<img draggable="false" class="Imageds" @click="crnmedua" src="../../assets/img/imgHomePage/xr.png" alt="" />
			<!-- <img class="backIqmg" src="../../assets/img/imgHomePage/back1.jpg" alt=""> -->
			<!-- 扫地机器人 -->
			<div class="rightItem" @click="rightBtnItem">
				<img v-if="editCoNanber == 0" ref="calaShake" class="IntelligentMachine"
					src="../../assets/img/imgHomePage/robotdefaultpicture.png" alt="" />
				<img v-if="machinevariable == 1" ref="calaShake" class="IntelligentMachine sapdij"
					src="../../assets/img/imgHomePage/Sweepingthefloor.gif" alt="" />
				<img v-if="stateTips[5]" ref="calaShake" class="Sweepingrobotdynamics sweepingRobotSignalWave"
					src="../../assets/img/imgHomePage/radio.gif" alt="" />
				<img v-if="stateTips[5] == false" ref="calaShake" class="IntelligentMachine"
					src="../../assets/img/imgHomePage/errsdjqr.png" alt="" />
				<img v-if="
            stateTips[0] &&
            stateTips[1] &&
            stateTips[2] &&
            stateTips[3] &&
            stateTips[4] &&
            stateTips[5] &&
            stateTips[6]
          " ref="calaShake" class="IntelligentMachine sapdij" src="../../assets/img/imgHomePage/Sweepingthefloor2.png"
					alt="" />
			</div>
			<el-dialog draggable="true" @dragstart="rightqwwItem($event)" @dragend="draqwdragenddgend($event)"
				@close="dialogClose()" :style="`left:${elLeqltx}px;top:${elLeqlty}px`" :modal="false" title="扫地机器人"
				width="850px" height="460px" :before-close="handleCldose_two" :model-value="editContentbox">
				<div class="PadVariableclassname">
					<progressBar v-if="sweepingRobotProgressBarName > 1"
						:style="`width:${sweepingRobotProgressBarName}%`"
						:sweepingRobotProgressBarName="sweepingRobotProgressBarName"></progressBar>
				</div>
				<!-- <div class="progressBarStyleName">
          <div class="progressChildElement" v-if="sweepingRobotProgressBarName >1" :style="`width:${sweepingRobotProgressBarName}%`">
            <span> %{{sweepingRobotProgressBarName}}</span>
          </div>
      </div> -->

				<div class="dispcenter">
					<div class="flexbtw" style="width: 80%">
						<div class="wsldsujp">
							<p>功能：深度清扫</p>
							<p>续航时间：100分钟</p>
							<p>机身大小：9cm-10cm</p>
							<p>产品名：N8</p>
							<p>型号：DLN12-11EA</p>
							<p>产品颜色：白色</p>
							<p>充电电池：锂电池</p>
							<p>电池容量：3200</p>
							<p>定制功能：有</p>
							<p>是否有遥控器：有</p>
							<p>WLAN频率：5GHz</p>
							<p>WLAN协议：802.11ac</p>
							<p>蓝牙连接：支持</p>
						</div>
						<div style="width: 320px;
    text-align: left;">
							<!-- <img class="qwiwwwv" src="../../assets/img/imgHomePage/开.png" alt="">
                            <img  class="qwiwwwv" src="../../assets/img/imgHomePage/关.png" alt=""> -->
							<el-form>
								<el-form-item>
									蓝牙：
									<el-switch v-model="bluetoothSwitchOfSweepingRobot" class="ml-2" inline-prompt
										@change="monitorBluetooth" active-color="#0074c5" inactive-color="#ff4949"
										active-text="打开" inactive-text="关闭" />
								</el-form-item>
								<el-form-item v-show="displaydevice" label="设备名称" prop="code">
									<el-select filterable @change="bluetoothNevicebtn" v-model="floorRobot"
										placeholder="请选择内容" class="poiuy" disabled>
										<!-- <el-option
                      v-for="(item, index) in BluetoothequipmentName"
                      :key="index"
                      :label="item"
                      :value="index"
                    >
                    </el-option> -->
									</el-select>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
				<div class="dispcenter">
					<div class="wificlass" @click="sweepingRobotSaveBtn">确 定</div>
				</div>
			</el-dialog>
			<!-- pad -->
			<div class="tabletS" @click="tabletBtn">
				<img draggable="false" class="tabletSimg" v-if="isPadnum == 0"
					src="../../assets/img/imgHomePage/pad.png" alt="" />
				<!-- <img
          v-if="stateTips[4]"
          src="../../assets/img/imgHomePage/pad1.png"
          alt=""
        /> -->
				<img draggable="false" class="tabletSimg" v-if="stateTips[4]" src="../../assets/img/imgHomePage/ip.gif"
					alt="" />
				<img draggable="false" v-if="stateTips[4]" class="Flatdynamics"
					src="../../assets/img/imgHomePage/radio.gif" alt="" />
				<img draggable="false" class="tabletSimg" v-if="stateTips[4] == false"
					src="../../assets/img/imgHomePage/errpad.png" alt="" />
			</div>
			<el-dialog draggable="true" @dragstart="PadStartsDragging($event)" @dragend="PadEndDragging($event)"
				@close="dialogClose()" :style="`left:${padelLeqltx}px;top:${padelLeqlty}px`" :modal="false" title="pad"
				width="1000px" height="460px" :model-value="tabletborder" :before-close="paddialog"
				:close-on-click-modal="false">
				<div class="PadVariableclassname">
					<progressBar v-show="padProgressBarName > 1" :style="`width:${padProgressBarName}%`"
						:sweepingRobotProgressBarName="padProgressBarName"></progressBar>
				</div>
				<!-- <div class="sweepingRobotProgressBarBorder">
          <div class="ChildElement" v-if="padProgressBarName >1" :style="`width:${padProgressBarName}%`">
            <span> %{{padProgressBarName}}</span>
          </div>
      </div> -->
				<img class="padDescription" @click="tabletbsordertrue" src="../../assets/img/imgHomePage/33.png"
					alt="" />
				<el-dialog title="产品说明" width="47%" :model-value="tabletbsorder" :close-on-click-modal="false"
					:before-close="padProductDescriptionDialog">
					<div class="dispcenter">
						<!-- <div class="flexbtw" style="width: 75%"> -->
						<!-- <div class="wsldsujp">
                <p>wifi Display</p>
                <p>WLAN Direct</p>
                <p>OTG</p>
                <p>USB</p>

                <p>蓝牙</p>
                <p>WLAN 协议</p>
                <p>WLAN 频率</p>
                <p>蓝牙文件传输</p>

                <p>PC数据同步</p>
              </div> -->
						<!-- <div class="wsldsujp">
                <p>支持</p>
                <p>支持</p>
                <p>支持(反向供电时最大输出电流1A5V)</p>
                <p>Type-C支持USB 3.0</p>

                <p>Bluelooth5.1 支持BLE,支持SBC,AAC,支持LDAC高清音频</p>
                <p>WIFI 6，B02，11 abgnacax 2.2MMO</p>
                <p>2.4GHz和5GHz</p>
                <p>支持</p>

                <p>支持(预留Hiauite)</p>
              </div> -->
						<!-- </div> -->
						<div class="wsldsujp">
							<el-row class="wsldsujpsoan">
								<el-col :span="12">wifi Display</el-col>
								<el-col :span="12">支持</el-col>
							</el-row>
							<el-row class="wsldsujpsoan">
								<el-col :span="12"> wifi Direct</el-col>
								<el-col :span="12">支持</el-col>
							</el-row>
							<el-row class="wsldsujpsoan">
								<el-col :span="12">OTG</el-col>
								<el-col :span="12">支持(反向供电时最大输出电流1A5V)</el-col>
							</el-row>
							<el-row class="wsldsujpsoan">
								<el-col :span="12">USB</el-col>
								<el-col :span="12">Type-C支持USB 3.0</el-col>
							</el-row>
							<el-row class="wsldsujpsoan">
								<el-col :span="12">WLAN 协议</el-col>
								<el-col :span="12">WIFI 6，B02，11 abgnacax 2.2MMO</el-col>
							</el-row>
							<el-row class="wsldsujpsoan">
								<el-col :span="12">WLAN 频率</el-col>
								<el-col :span="12">2.4GHz和5GHz</el-col>
							</el-row>
							<el-row class="wsldsujpsoan">
								<el-col :span="12">蓝牙文件传输</el-col>
								<el-col :span="12">支持</el-col>
							</el-row>
							<el-row class="wsldsujpsoan">
								<el-col :span="12">wifi Display</el-col>
								<el-col :span="12">支持(预留Hiauite)</el-col>
							</el-row>
							<el-row class="wsldsujpsoan">
								<el-col :span="12">PC数据同步</el-col>
								<el-col :span="12">支持</el-col>
							</el-row>
							<el-row class="wsldsujpsoan">
								<el-col :span="12">蓝牙</el-col>
								<el-col :span="12">
									<span>Bluelooth5.1 支持BLE,支持SBC,AAC,支持LDAC高清音频</span>
								</el-col>
							</el-row>
						</div>
					</div>
				</el-dialog>

				<div style="height: 36px" class="margin_top margin_left">
					<p>WLAN网络配置</p>
				</div>
				<div class="dispcenter">
					<div class="flexbtw" style="width: 95%">
						<div class="OptionalDataOnTheRight">
							<div v-for="(i, index) in EchoDisplayArr" :key="index">
								<el-radio @click.native="automwatic(i)" v-model="singleChoice" :label="i">{{ i }}
								</el-radio>
							</div>
							<div v-for="(i, index) in miqerryue" :key="index">
								<el-radio @click.native="automwatic(i)" v-model="singleChoice" :label="i">{{ i }}
								</el-radio>
							</div>
							<div class="OtherNetworks" @click="OtherNetworksBtn">
								其它网络
							</div>
						</div>
						<div v-if="ClickToDisplay" style="width: 40%">
							<el-form :model="ruleForm" :rules="rules" ref="ruleForm" :label-width="formLabelWidth"
								class="demo-ruleForm">
								<el-form-item label="SSID" prop="codeType">
									<el-input v-if="!OtherNetworksIsshow" :placeholder="ssidtoggleInputBoxd"
										:disabled="true">
									</el-input>
									<el-input v-if="OtherNetworksIsshow" placeholder="请输入内容"
										v-model="ssidtoggleInputBoxd3">
									</el-input>
									<!-- <el-input
                    class="inputs"
                    oninput="value=value.replace(/[^\d.]/g,'')"
                    v-model="ssidSSID"
                    placeholder="请输入内容"
                  ></el-input> -->
									<!-- <el-select
                  v-if="toggleInput == 0"
                    @change="PlateID"
                    v-model="ssidSSID"
                    placeholder="请选择内容"
                    class="poiuy"

                    filterable
                  >
                    <el-option
                      v-for="(item, index) in miqerryue"
                      :key="index"
                      :label="item"
                      :value="index"
                    >
                    </el-option>
                  </el-select> -->
									<!-- <el-input
                       v-if="toggleInput == 1"
                    class="inputs"

                    v-model="ssidtoggleInputBoxd"
                    placeholder="请输入密码"
                  >
                  </el-input> -->
								</el-form-item>
								<!-- <el-form-item> -->
								<!-- <div style="    margin-left: 6%;" >

                    <el-link type="success" @click="toggleInputBox"  v-if="toggleInput == 0">点击手动输入SSID</el-link>
                    <el-link type="success" @click="toggledropdownbox"  v-if="toggleInput == 1">点击手动选择SSID</el-link>
                  </div> -->
								<!-- </el-form-item> -->
								<el-form-item label="密 码" prop="code">
									<el-input show-password class="inputs" type="password" v-model="ssidPassword"
										placeholder="请输入密码">
									</el-input>
								</el-form-item>
								<el-form-item label="蓝牙开关" prop="code">
									<el-select filterable @change="bluetooth" v-model="blueOff" placeholder="请选择内容"
										class="poiuy">
										<el-option v-for="(item, index) in laqwebelNa" :key="index" :label="item"
											:value="item">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="扫描开关" prop="code">
									<el-select filterable @change="scan_cang_time" v-model="scan_any_time"
										placeholder="请选择内容" class="poiuy">
										<el-option v-for="(item, index) in laqwebelNa" :key="index" :label="item"
											:value="index">
										</el-option>
									</el-select>
								</el-form-item>
							</el-form>
						</div>
						<div style="width: 10px"></div>
						<div v-if="ClickToDisplay" style="width: 40%">
							<el-form :model="ruleForm" :rules="rules" ref="ruleForm" :label-width="formLabelWidth"
								class="demo-ruleForm">
								<el-form-item label="  安全性" prop="code">
									<el-input placeholder="WPA/WPA2-personal" v-model="input" :disabled="true">
									</el-input>
								</el-form-item>
								<el-form-item label="自动连接" prop="code">
									<el-select filterable @change="cang_connection" v-model="automatic_connection"
										placeholder="请选择内容" class="poiuy">
										<el-option v-for="(item, index) in labelNamelist" :key="index" :label="item"
											:value="index">
										</el-option>
									</el-select>
								</el-form-item>

								<el-form-item v-if="blueOff == '打开'" label="可配对" prop="code">
									<el-select filterable @change="PadDisplayDeviceVariables" v-model="blueName"
										placeholder="请选择内容" class="poiuy">
										<el-option v-for="(item, index) in equipmentName" :key="index" :label="item"
											:value="item">
										</el-option>
									</el-select>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
				<div style="height: 40px"></div>
				<div class="dispcenter">
					<div class="wificlass" @click="tabletConnection">确 定</div>
				</div>
			</el-dialog>

			<!-- 电话 -->
			<div class="Landlinetelephone" @click="LandlinetelephoneBtn">
				<img class="Telephonecla" v-if="duijiangname == 0" src="../../assets/img/imgHomePage/dianhua.png"
					alt="" />
				<img class="Telephonecla" v-if="stateTips[1]" src="../../assets/img/imgHomePage/dianhua1.gif" alt="" />
				<img v-if="stateTips[1]" class="Telephonediagram" src="../../assets/img/imgHomePage/radio.gif" alt="" />
				<img class="Telephonecla" v-if="stateTips[1] == false" src="../../assets/img/imgHomePage/errdh.png"
					alt="" />
			</div>
			<el-dialog :before-close="TelephoneDialog" draggable="true" @dragstart="dragstssqart($event)"
				@dragend="draqwdgend($event)" @close="dialogClose2()" :style="`left:${elLeft}px;top:${elTop}px`"
				:modal="false" custom-class="cudstofmClass" title="电话" top="2%" :model-value="isTelephoneS"
				:close-on-click-modal="false">
				<div class="PadVariableclassname">
					<progressBar v-if="phoneProgressBarName > 1" :style="`width:${phoneProgressBarName}%`"
						:sweepingRobotProgressBarName="phoneProgressBarName"></progressBar>
				</div>
				<!-- <div class="sweepingRobotProgressBarBorder">
          <div class="ChildElement" v-if="phoneProgressBarName >1" :style="`width:${phoneProgressBarName}%`">
            <span> %{{phoneProgressBarName}}</span>
          </div>
      </div> -->
				<div class="flexbtw" style="width: 98%">
					<div class="flexbtw mweinem">
						<div class="telephoneSignaling" @click="wangluopwBtn(1)"
							:class="{ telephoneSignalingActive: isActivehande == 1 }">
							网络配置
						</div>
						<div class="telephoneSignaling" @click="wangluopwBtn(2)"
							:class="{ telephoneSignalingActive: isActivehande == 2 }">
							信令流程
						</div>
					</div>
					<div class="wificlass" @click="closeTheInterphonePopUpWindow">
						确 定
					</div>
				</div>
				<div v-show="isActivehande == 1">
					<div class="flexbtw">
						<div class="wiFitoolLibraryClassName">
							<!-- <div class="dispcenter "> -->
							<div class="flexbtw">
								<div class="ToolLibrarylog">
									<img draggable="false" class="uasghjmsd" src="../../assets/img/imgHomePage/16.png"
										alt="" />
								</div>
								<div class="libraryText">
									<span class="fonst">工具库</span>
								</div>
							</div>
							<!-- </div> -->
							<ul class="business signalingProcessClassName">
								<li v-for="(item, index) in businessListAry" :key="index" style="text-align: center">
									<img :id="item.id" class="draggable imagboxsw iwqndsoeew" draggable="true" :src="
                      require(`../../assets/img/imgHomePage/${item.id}.jpg`)
                    " :alt="item" />
									<p class="libraryTextSize">{{ item.name }}</p>
								</li>
							</ul>
						</div>
						<div>
							<div class="mapontheright">
								<!-- <img class="imawe" src="../../assets/img/imgHomePage/地图.jpg" alt=""> -->
								<img class="imawe" src="../../assets/img/imgHomePage/newMap.jpg" alt="" />

								<ul class="business dragList dragList2">
									<li class="droppableEle dC1draganddropname" data-index="10" ref="clasnameDc1"></li>
									<div id="tuozBwox" class="Riqghwt_click">
										<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png"
											alt="" />
									</div>
									<div class="saxu_yiw"></div>
									<li class="droppableEle dCerdraganddropname" ref="claswqnameDc2" data-index="11">
									</li>

									<div class="sawrxu_ywiw"></div>
									<div class="sawrxuiw"></div>
									<li class="droppableEle Endoffice" data-index="12"
										ref="echoOfTheEndOfficeWhereTheBattleCommandIsLocated"></li>

									<div class="sawrxswwsuiw">
										<!-- <img  src="../../assets/img/imgHomePage/xx.png" alt=""> -->
										<img draggable="false" src="../../assets/img/imgHomePage/xx.png" alt="" />
										<!-- <img
                        class="iconclass"
                        src="../../assets/img/imgHomePage/xx.png"
                        alt=""
                      /> -->
									</div>
									<li class="droppableEle Endoffename" data-index="13"
										ref="echoOfTheTerminalOfficeWhereTheRadioAndTelevisionBusinessHallIsLocated">
									</li>

									<div class="eudhsw">
										<img draggable="false" style="width: 100%"
											src="../../assets/img/imgHomePage/businesshall.png" alt="" />
									</div>
									<div class="eudwhsw"></div>
									<div class="euedhsw"></div>
								</ul>
							</div>
						</div>
					</div>

					<div class="dispcenter margin_top">
						<div class="flexbtw" style="width: 35%">
							<div class="flexbtw">
								<img draggable="false" style="width: 40px; margin-top: -12px"
									src="../../assets/img/imgHomePage/xx.png" alt="" />
								<span class="margin_left"> 作战指挥部</span>
							</div>
							<div class="flexbtw">
								<div class="eudhsswsw">
									<img draggable="false" style="width: 100%"
										src="../../assets/img/imgHomePage/businesshall.png" alt="" />
								</div>
								<span class="margin_left">广电营业厅</span>
							</div>
						</div>
					</div>
				</div>
				<div v-show="isActivehande == 2" class="flexbtw signalingProcess">
					<div style="position: relative; width: 30%; margin-top: 2.6%" class="flexbtw">
						<img class="actionSignalingName" src="../../assets/img/imgHomePage/actionSignaling.png"
							alt="" />
						<div class="showactioncontent" @click="isfontActiveBtn(1)"></div>
						<div class="rightactioncontent" @click="isfontActiveBtn(2)"></div>
						<div class="leftboxitem">
							<div v-show="fontactive == 1" class="dispcenter">
								<ul class="businessListAry">
									<li class="actionSer" v-for="(item, index) in busLis" :key="index"
										style="text-align: center">
										<p :id="item.id" class="draggable" draggable="true">
											{{ item.name }}
										</p>
									</li>
								</ul>
							</div>
							<div v-show="fontactive == 2" class="dispcenter">
								<ul class="businessListAry">
									<li  class="actioqnSer" v-for="(item, index) in bussLias" :key="index"
										style="text-align: center">
										<p :id="item.id" class="draggable" draggable="true">
											{{ item.name }}
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div class="mapontheright">
						<div class="bohao" @click="bwtwqn"></div>
						<img class="bohaoimg" src="../../assets/img/imgHomePage/tapDial.png" alt="" />
						<img class="tonghuaimg" src="../../assets/img/imgHomePage/tongh.png" alt="" />
						<!-- <img class="bohaoimg" src="../../assets/img/imgHomePage/bohao.png" alt=""> -->
						<!-- 默认显示 -->
						<img class="imawe" v-if="wnassowisw == 0"
							src="../../assets/img/imgHomePage/signalingDefaultDisplaypPicture.jpg" alt="" />
						<!-- 点击拨号 号码错误的时候显示 -->
						<img class="imawe" v-if="wnasswrowisw == 1"
							src="../../assets/img/imgHomePage/pictureOfDialingError.jpg" alt="" />
						<!-- 点击拨号 号码正确的时候显示 -->
						<!-- <img
              class="imawe"
              v-if="wnasswrowisw == 2"
              src="../../assets/img/imgHomePage/showWhencorrect.jpg"
              alt=""
            /> -->
						<img class="imawe" v-if="wnasswrowisw == 2"
							src="../../assets/img/imgHomePage/pictureOfCorrectDialing.jpg" alt="" />
						<!-- <el-dialog width="28%" :model-value="bohaoishow">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                :label-width="formLabelWidth"
                class="demo-ruleForm"
              >
                <el-form-item label="  电话号" prop="code">
                  <el-input
                    style="width: 240px"
                    v-model="ssiqsakd"
                  onkeypress="javascript:if(event.keyCode == 32 || event.keyCode == 13)event.returnValue = false;"
                    onkeyup="value=value.replace(/[^\x00-\xff]/g, '')"
                    placeholder="请输入电话号"
                  >
                  </el-input>
                </el-form-item>
              </el-form>

              <div class="dispcenter margin_top">
                <div class="wificlass" @click="dianhuah">保 存</div>
              </div>
            </el-dialog> -->
						<el-dialog title="被叫号码" @close="dialogClose2()" :model-value="bohaoishow" :model="ruleForm"
							:before-close="handleClose_phoneBox" width="450px">
							<div style="height: 200px; position: relative; ">
								<el-form :model="ruleForm" :rules="rules" ref="ruleForm" :label-width="formLabelWidth"
									class="demo-ruleForm">
									<el-form-item label="电话号" prop="code">

										<el-input readonly="true" v-model="ssiqsakd"
											style="width: 50px;position: absolute;">
										</el-input>
										<span style="position: absolute;left: 52px;">-</span>
										<el-input type="number" style="width: 190px;left: 60px;" v-model="phoneNumber"
											onkeypress="javascript:if( event.keyCode == 13)event.returnValue = false;"
											onkeyup="value=value.replace(/[^\x00-\xff]/g, '')" placeholder="请输入电话号"
											oninput="if(value.length>5)value=value.slice(0,8)">
										</el-input>

									</el-form-item>
								</el-form>
							</div>
							<div class="dispcenter margin_top">
								<div class="wificlass" @click="dianhuah">保 存</div>
							</div>
						</el-dialog>
						<ul class="business dragList dragList2 signalingBackgroundDiagram">
							<div v-if="wnasswrowisw == 2 && oeilsukqwtim == 0">
								<div class="dialogBox" v-show="onDemandDisplay > 1">
									<span> 您好，这里是作战指挥部，请问是广电营业厅吗？</span>
									<img class="dialogBoximg" draggable="false"
										src="../../assets/img/imgHomePage/righticon.png" alt="" />
									<img @click="correctmessageprompt"
										class="tmessagepromptName lsthistheradioandtelevision"
										src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
								</div>
								<div class="dialowdgBox" v-show="onDemandDisplay > 6">
									<span>我们需要安装一台电视机，并开通卫星电视和有线电视业务。还需要安装wifi</span>
									<img class="dialowdgBoximg" draggable="false"
										src="../../assets/img/imgHomePage/righticon.png" alt="" />
									<img @click="correctmessageprompt"
										class="tmessagepromptName lsthistheradioandtelevision"
										src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
								</div>
								<div class="CalledpopUpprompt" v-show="onDemandDisplay > 3">
									<span> 是的。您有什么需要</span>
									<img class="CalledpopUppromptimg" draggable="false"
										src="../../assets/img/imgHomePage/lefticon.png" alt="" />
									<img @click="correctmessageprompt"
										class="tmessagepromptName lsthistheradioandtelevision"
										src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
								</div>

								<div class="koqalogBox" v-show="onDemandDisplay > 9">
									<span> 好的，我们明天为您上门服务。</span>
									<p>点击右边按钮关闭提示语</p>
									<img class="koqalogBoximg" draggable="false"
										src="../../assets/img/imgHomePage/lefticon.png" alt="" />
									<img @click="correctmessageprompt"
										class="tmessagepromptName lsthistheradioandtelevision"
										src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
								</div>
								<!-- <div class=" wificlass correctmessagepromptName"  v-show="onDemandDisplay > 11" @click="correctmessageprompt">保 存</div> -->
							</div>

							<div v-if="wnasswrowisw == 1 && timessTimeiqsdial == 0">
								<div class="dialogBox" v-show="dialthewrongnumbe > 1">
									<span> 您好，这里是作战指挥部，请问是广电营业厅吗？</span>
									<img class="dialogBoximg" draggable="false"
										src="../../assets/img/imgHomePage/righticon.png" alt="" />
									<img @click="closeerroPopup" class="tmessagepromptName lsthistheradioandtelevision"
										src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
								</div>
								<div class="CalledpopUpprompt" v-show="dialthewrongnumbe > 3">
									<span> 对不起，您打错了</span>
									<p>点击右边按钮关闭提示语</p>
									<img class="CalledpopUppromptimg" draggable="false"
										src="../../assets/img/imgHomePage/lefticon.png" alt="" />
									<img @click="closeerroPopup" class="tmessagepromptName lsthistheradioandtelevision"
										src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
								</div>
							</div>
							<div id="tungwBox" class="Riqghwt_click">
								<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
							</div>
							<li class="droppableEle iamboxs" data-index="14" ref="iamEcho"></li>
							<li class="droppableEle zhenlingbox" data-index="15" ref="ringEcho"></li>
							<li class="droppableEle acmbox" data-index="16" ref="acmEcho"></li>
							<li class="droppableEle huiyinlbox" data-index="17" ref="echoEcho"></li>
							<li class="droppableEle ANCbox" data-index="18" ref="ancEcho"></li>
							<li class="droppableEle zhaojijs" data-index="19" ref="offHookAnswerEcho"></li>
							<li class="tongh" @click="clickToCall"></li>
							<ul v-show="ulShow">
								<li class="droppableEle beijiaogualeft" v-show="wnassowisw == 1" data-index="20"
									ref="callerHangUEcho"></li>
								<li class="droppableEle zhujiaogajright" v-show="wnassowisw == 1" data-index="21"
									ref="sendReminderEcho"></li>

								<li class="droppableEle cbkboxcl" data-index="22" v-show="wnassowisw == 1"
									ref="clfEcho"></li>
								<li class="droppableEle CLFboxcl" data-index="23" v-show="wnassowisw == 1"
									ref="rlgEcho"></li>
								<li class="droppableEle RLGboxcl ss" data-index="24" v-show="wnasswrowisw == 1"
									ref="rLGEcho"></li>
							</ul>
							<div id="zhswyhxBox" class="Riqghwt_click">
								<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
							</div>
						</ul>
					</div>
				</div>
			</el-dialog>

			<!-- 对讲机 -->
			<div class="InterphoneName">
				<img v-if="wisdmsow == 0" @click="InterphoneBtn" class="walkie_talkie" src="../../assets/img/imgHomePage/duijiang.gif"
					alt="" />
				<img v-if="stateTips[0]" @click="InterphoneBtn" class="walkie_talkie" src="../../assets/img/imgHomePage/duijiang1.gif"
					alt="" />
				<img v-if="stateTips[0]" @click="InterphoneBtn" class="Walkietalkiediagram" src="../../assets/img/imgHomePage/radio.gif"
					alt="" />
				<img v-if="stateTips[0] == false" @click="InterphoneBtn" class="walkie_talkie" src="../../assets/img/imgHomePage/errdjj.png"
					alt="" />
			</div>
			<div @click="Interphonetrue" v-if="Interphone == 1" class="Interphonemessageprompt">
				<img class="dialong" src="../../assets/img/imgdevelopment/leftbox.png" alt="" />
				<span> 通信保障部，收到请回答。作战指挥部请求安装一部电话。 </span>
			</div>
			<el-dialog draggable="true" @dragstart="walkieTalkie($event)" @dragend="walkieEndTalkie($event)"
				@close="dialogClose()" :before-close="walkie_talkie" :style="`left:${walkieqx}px;top:${walkieqendx}px`"
				:modal="false" title="对讲机" :model-value="icon_s" :close-on-click-modal="false">
				<div class="PadVariableclassname">
					<progressBar v-if="interphoneProgressBarName > 1" :style="`width:${interphoneProgressBarName}%`"
						:sweepingRobotProgressBarName="interphoneProgressBarName"></progressBar>
				</div>
				<!-- <div class="sweepingRobotProgressBarBorder">
          <div class="ChildElement" v-if="interphoneProgressBarName >1" :style="`width:${interphoneProgressBarName}%`">
            <span> %{{interphoneProgressBarName}}</span>
          </div>
      </div> -->
				<div style="height: 36px"></div>
				<div class="dispcenter">
					<div class="flexbtw" style="width: 100%">
						<div>
							<el-form :model="ruleForm" :rules="rules" ref="ruleForm" :label-width="formLabelWidth"
								class="demo-ruleForm">
								<el-form-item label="电 源 " prop="codeType">
									<el-select @change="power_caneg" v-model="dianwyuan" placeholder="请选择内容"
										class="poiuy">
										<el-option v-for="(item, index) in labelNamelist" :key="index" :label="item"
											:value="index">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="功 率 (瓦) " prop="code">
									<el-select @change="switchcang" v-model="gonglc" placeholder="请选择内容" class="poiuy">
										<el-option v-for="(item, index) in power_switcharr" :key="index" :label="item"
											:value="item">
										</el-option>
									</el-select>
								</el-form-item>

							</el-form>
						</div>
						<div>
							<el-form :model="ruleForm" :rules="rules" ref="ruleForm" :label-width="formLabelWidth"
								class="demo-ruleForm">
								<el-form-item label="频 道" prop="code">
									<el-select @change="powerchange" v-model="pindaouws" placeholder="请选择内容"
										class="poiuy">
										<el-option v-for="(item, index) in channwel" :key="index" :label="item"
											:value="item">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="通 话" prop="code">
									<el-select @change="communicationWitch" v-model="tbtnsw" placeholder="请选择内容"
										class="poiuy">
										<el-option v-for="(item, index) in labelNamelist" :key="index" :label="item"
											:value="index">
										</el-option>
									</el-select>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
				<div style="height: 40px"></div>
				<div class="dispcenter">
					<div class="wificlass" @click="img_btnN">确 定</div>
				</div>
			</el-dialog>

			<!-- 手机 -->
			<div class="mobile_Phone">
				<img class="mobileimg" @click="mobileDragBtn" v-if="guanjisis == 0" src="../../assets/img/imgHomePage/shouji.png" alt="" />
				<img class="mobileimg" @click="mobileDragBtn" v-if="stateTips[6]" src="../../assets/img/imgHomePage/shouji1.gif" alt="" />
				<img v-if="stateTips[6]" @click="mobileDragBtn" class="Mobilediagram" src="../../assets/img/imgHomePage/radio.gif" alt="" />
				<img class="mobileimg" @click="mobileDragBtn" v-if="stateTips[6] == false" src="../../assets/img/imgHomePage/errmobilphone.png"
					alt="" />
			</div>
			<el-dialog draggable="true" @dragstart="drqagstsssqart($event)" @dragend="draqwwwwedgend($event)"
				@close="dialogClose()" :before-close="mobilePhonePaddialog" :style="`left:${elLeft1}px;top:${elTop1}px`"
				:modal="false" title="手机" class="wsqw" top="2%" :model-value="tuozhuaibufen"
				:close-on-click-modal="false">
				<div class="PadVariableclassname">
					<progressBar v-if="mobileProgressBarName > 1" :style="`width:${mobileProgressBarName}%`"
						:sweepingRobotProgressBarName="mobileProgressBarName"></progressBar>
				</div>
				<!-- <div class="sweepingRobotProgressBarBorder">
          <div class="ChildElement" v-if="mobileProgressBarName >1" :style="`width:${mobileProgressBarName}%`">
            <span> %{{mobileProgressBarName}}</span>
          </div>
      </div> -->
				<div class="flexbtw">
					<!--  -->
					<div class="flexbtw mobilephoneconfigurationswitching">
						<div class="wanglpei" @click="mobile_ItemBtn(1)"
							:class="{ tabSwitchTagName: mobile_active == 1 }">
							网络配置
						</div>
						<div class="wanglpei" @click="mobile_ItemBtn(2)"
							:class="{ tabSwitchTagName: mobile_active == 2 }">
							开机流程
						</div>
					</div>
					<div class="flexbtw dispBox">
						<!-- v-show="mobile_active == 2" -->
						<div class="xisisdwass" @click="PhoneSave">确 定</div>
					</div>
				</div>
				<div v-show="mobile_active == 1" class="flexbtw">
					<div class="wiFitoolLibraryClassName">
						<div class="flexbtw">
							<div class="ToolLibrarylog">
								<img draggable="false" class="uasghjmsd" src="../../assets/img/imgHomePage/16.png"
									alt="" />
							</div>
							<div class="libraryText">
								<span class="fonst">工具库</span>
							</div>
						</div>

						<ul class="planWrap">
							<li v-for="(item, index) in busessLis" :key="index" style="text-align: center">
								<img :id="item.id" class="draggable imagboxsw iwqndwsoeew" draggable="true"
									:src="require(`../../assets/img/imgHomePage/${item.id}.jpg`)" :alt="item" />
								<p class="libraryTextSize">{{ item.name }}</p>
							</li>
						</ul>
					</div>

					<div>
						<div class="mapontheright">
							<!-- <img style="width:100%" src="../../assets/img/imgHomePage/地图.jpg" alt=""> -->
							<img class="imawe" src="../../assets/img/imgHomePage/newMap.jpg" alt="" />
							<ul class="business dragList dragList2">
								<li class="droppableEle droppablekey" data-index="51" ref="externalNetworkEcho"></li>

								<li class="lismw"></li>
								<li class="droppableEle droppable16" data-index="52" ref="gatewayEcho"></li>

								<li class="liswmww"></li>
								<li class="droppableEle droppable17" data-index="53" ref="coreNetworkEcho"></li>

								<li class="liqsswmww"></li>
								<li class="droppableEle droppable18" data-index="54" ref="baseStationEcho"></li>

								<li class="liqcssw"></li>
								<li class="droppableEle droppable19" data-index="55" ref="networkManagementSystemEcho">
								</li>

								<li class="liqqcssw"></li>
								<img draggable="false" class="sawrxswwsuiw" src="../../assets/img/imgHomePage/xx.png"
									alt="" />
							</ul>
						</div>
						<div class="dispcenter" style="margin-top: 15px">
							<div class="flexbtw">
								<div class="almsdew">
									<img draggable="false" src="../../assets/img/imgHomePage/xx.png" alt="" />
								</div>
								<div style="margin-top: 13px">作战指挥部</div>
							</div>
						</div>
					</div>
				</div>
				<div v-show="mobile_active == 2">
					<div style="height: 10px"></div>
					<div class="box_Left">
						<div class="coveredBox"></div>
						<!-- <img
              class="wqmsimg"
              src="../../assets/img/imgHomePage/uwh.jpg"
              alt=""
            /> -->
						<img class="wqmsimg" src="../../assets/img/imgHomePage/pigeonBackground.jpg" alt="" />
						<div class="diyigegsssdezipo" @click="owmsncan" ref="tre">
							<img src="../../assets/img/imgHomePage/gezi01.png" alt="" v-if="canksk" />
							<p class="classdiyzipwop" v-if="canksk">PDU会话建立</p>
						</div>
						<div class="diyiipo" @click="rrcjianks" ref="trwe">
							<img src="../../assets/img/imgHomePage/gezi03.png" alt="" v-if="cankesk" />
							<p class="classdiyzipwop" v-if="cankesk">RRC建立过程</p>
						</div>

						<div class="sdezeipo" @click="safeMode_btn" ref="pol">
							<img src="../../assets/img/imgHomePage/gezi01.png" alt="" v-if="canxiw" />
							<p class="classdiyzipwop" v-if="canxiw">安全模式建立</p>
						</div>

						<div class="deziqpo" @click="csjlsxwbtn" ref="poqwstl">
							<img src="../../assets/img/imgHomePage/gezi01.png" alt="" v-if="canxiaow" />
							<p class="classdiyzipwop" v-if="canxiaow">系统消息</p>
						</div>

						<div class="diyes" @click="suijisjianks" ref="suiji">
							<img src="../../assets/img/imgHomePage/gezi02.png" alt="" v-if="xiaHks" />
							<p class="diyzipop" v-if="xiaHks">随机接入</p>
						</div>

						<div class="diyigedezipo" @click="xitcjianks" ref="initialtext">
							<img src="../../assets/img/imgHomePage/gezi02.png" alt="" v-if="xiafenHks" />
							<p class="diyzipop" v-if="xiafenHks">初始UE消息</p>
						</div>

						<div class="diyigezipo" @click="SigningRight_btn" ref="acves">
							<img src="../../assets/img/imgHomePage/gezi02.png" alt="" v-if="xiafns" />
							<p class="diyzipop" v-if="xiafns">鉴权和加密</p>
						</div>

						<div class="performSweepingTaskClassName" @click="performSweepingTaskClassNameBtn">
							<img src="../../assets/img/imgHomePage/gezi02.png" alt=""
								v-if="performSweepingTaskisshow" />
							<p class="diyzipop" v-if="performSweepingTaskisshow" ref="performSweepingTaskacves">
								执行扫地任务
							</p>
						</div>

						<div class="box_Right">
							<div class="suijiyangs" v-for="(item, index) in listgezi" :key="index">
								<span v-if="item !== null && item !== undefined "> {{ item }}</span>
								<span class="siame" @click="deleteBtn(item, index)"
									v-if="item !== null && item !== undefined">
									X
								</span>
							</div>
							<div>
								<div class="suijiyangsw" @click="powerOnBtn" v-if="swna == 0"></div>
							</div>
							<div class="suijiyangsw" v-if="swna == 1"></div>
						</div>
					</div>
				</div>
			</el-dialog>
			<!-- 电视 -->
			<div class="television" @click="tVDragBtn">
				<img draggable="false" class="TVmotionimg" v-show="dianshiishs == 0"
					src="../../assets/img/imgHomePage/dianshi.png" alt="" />
				<img draggable="false" class="TVmotionimg" v-show="stateTips[2]"
					src="../../assets/img/imgHomePage/dianshi.gif" alt="" />
				<!-- <img
         v-show="stateTips[2]"
          class="TVmotionpicture"
          src="../../assets/img/imgHomePage/radio.gif"
          alt=""
        /> -->
				<img draggable="false" class="TVmotionimg" v-show="stateTips[2] == false"
					src="../../assets/img/imgHomePage/errds.png" alt="" />
			</div>
			<el-dialog draggable="true" @dragstart="dstswqssqart($event)" @dragend="draqwwwqedgend($event)"
				@close="dialogClose()" :before-close="televisiondialog" :style="`left:${elLeft2}px;top:${elTop2}px`"
				:modal="false" title="电视" top="2%" :model-value="television_dialog" :close-on-click-modal="false">
				<div class="PadVariableclassname">
					<progressBar v-if="tVProgressBarName > 1" :style="`width:${tVProgressBarName}%`"
						:sweepingRobotProgressBarName="tVProgressBarName"></progressBar>
				</div>
				<!-- <div class="sweepingRobotProgressBarBorder">
          <div class="ChildElement" v-if="tVProgressBarName >1" :style="`width:${tVProgressBarName}%`">
            <span> %{{tVProgressBarName}}</span>
          </div>
      </div> -->
				<div class="flexbtw margin_top">
					<div class="toolLibraryClassName">
						<div class="flexbtw">
							<div class="ToolLibrarylog">
								<img draggable="false" class="uasghjmsd" src="../../assets/img/imgHomePage/16.png"
									alt="" />
							</div>
							<div class="libraryText">
								<span class="fonst">工具库</span>
							</div>
						</div>
						<ul class="business planWrap">
							<li v-for="(item, index) in televisionList" :key="index" style="text-align: center">
								<img :id="item.id" class="draggable imagboxsw wjskhbw" draggable="true"
									:src="require(`../../assets/img/imgHomePage/${item.id}.jpg`)" :alt="item" />
								<p class="libraryTextSize">{{ item.name }}</p>
							</li>
						</ul>
					</div>
					<div>
						<div class="mapontheright">
							<div class="tVSaveName" @click="television_wancheng" id="create">
								确 定
							</div>
							<!-- <img style="width:100%" src="../../assets/img/imgHomePage/地图.jpg" alt=""> -->
							<img class="marginImage imawe margin_top" src="../../assets/img/imgHomePage/newMap.jpg"
								alt="" />

							<ul class="business dragList dragList2">
								<li class="droppableEle guangbwxbox" data-index="0" ref="televisionhtmlImg"></li>
								<div id="zsjyxBox" class="Riqghwt_click">
									<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
								</div>
								<div class="weixgb"></div>
								<div class="wospkm"></div>
								<li class="droppableEle shfskzzbox" data-index="1"
									ref="uplinkTransmissionControlStation"></li>
								<div id="zmeyyxBox" class="Riqghwt_click">
									<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
								</div>
								<!-- <div class="weixqlefgb"></div> -->
								<li class="droppableEle wxdsjszBox" data-index="2" ref="satelliteTVReceivingStation">
								</li>
								<div id="zqxBox" class="Riqghwt_click">
									<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
								</div>
								<!-- <div class="lqefgb"></div> -->
								<div class="zhihuibs"></div>
								<li class="droppableEle commingleClass" data-index="3" ref="mixer"></li>
								<li class="droppableEle guSDHBox" data-index="4" ref="national"></li>
								<div id="zqxwqBox" class="Riqghwt_click">
									<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
								</div>
								<div class="pkjwis"></div>
								<li class="woisd"></li>
								<div class="lqerigtfgb"></div>
								<img class="sqssimg" draggable="false" src="../../assets/img/imgHomePage/fenpeiqi.png"
									alt="" />
								<li class="droppableEle shenggSDHBox" data-index="5" ref="provincialLevel"></li>
								<div id="zqxwqyanhBox" class="Riqghwt_click">
									<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
								</div>
								<div class="lqeriwgtfgb"></div>
								<li class="droppableEle zibianjmSDHBox" data-index="6" ref="selfMadeProgram"></li>
								<div id="zqxshBox" class="Riqghwt_click">
									<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
								</div>
								<li class="droppableEle fangdajinmSDHBox" data-index="7" ref="amplisfier"></li>
								<div id="zqxshwqBox" class="Riqghwt_click">
									<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
								</div>
								<div class="zhywbot"></div>
								<li class="droppableEle fenzhiqSDHBox" data-index="8" ref="branchingDevice"></li>
								<div id="zqxshwwqBox" class="Riqghwt_click">
									<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png" alt="" />
								</div>
								<div class="iwskow"></div>
								<img draggable="false" class="sawrxswwsuiw" src="../../assets/img/imgHomePage/xx.png"
									alt="" />
							</ul>
						</div>
					</div>
				</div>
				<div class="dispcenter margin_top">
					<div class="flexbtw" style="width: 32%">
						<div class="flexbtw">
							<img draggable="false" style="width: 40px; margin-top: -12px"
								src="../../assets/img/imgHomePage/xx.png" alt="" />
							作战指挥部
						</div>
						<div class="flexbtw">
							<div class="eudhwsw">
								<img draggable="false" src="../../assets/img/imgHomePage/fenpeiqi.png" alt="" />
							</div>
							<div>分配器</div>
						</div>
					</div>
				</div>
			</el-dialog>

			<!-- wifi -->
			<div class="routerIntroductionS" @click="routerIntroductionBtn">
				<img class="wifiionpicimg" v-show="fissienws == 0" src="../../assets/img/imgHomePage/luyouqi.png"
					alt="" />
				<img class="wifiionpicimg" v-show="stateTips[3]" src="../../assets/img/imgHomePage/luyouqi1.gif"
					alt="" />
				<img v-show="stateTips[3]" class="wifiionpicture" src="../../assets/img/imgHomePage/radio.gif" alt="" />
				<img class="wifiionpicimg" v-show="stateTips[3] == false" src="../../assets/img/imgHomePage/errwifi.png"
					alt="" />
			</div>
			<el-dialog :before-close="wifidialog" draggable="true" @dragstart="dstsart($event)"
				@dragend="dradgend($event)" @close="dialogClose()" :style="`left:${elLeft3}px;top:${elTop3}px`"
				:modal="false" title="wifi" top="2%" :model-value="routerIntroductionBtnsync"
				:close-on-click-modal="false">
				<!-- <div class="sweepingRobotProgressBarBorder">
          <div class="ChildElement" v-if="routerProgressBarName >1" :style="`width:${routerProgressBarName}%`">
            <span> %{{routerProgressBarName}}</span>
          </div>
      </div> -->
				<div class="PadVariableclassname">
					<progressBar v-if="routerProgressBarName > 1" :style="`width:${routerProgressBarName}%`"
						:sweepingRobotProgressBarName="routerProgressBarName"></progressBar>
				</div>
				<div class="flexbtw" style="width: 98%">
					<div class="flexbtw mweinem">
						<div class="wanglpei" @click="wi_fiacitve(1)" :class="{ tabSwitchTagName: wi_activ == 1 }">
							网络配置
						</div>
						<div class="wanglpei" @click="wi_fiacitve(2)" :class="{ tabSwitchTagName: wi_activ == 2 }">
							参数配置
						</div>
					</div>
					<div class="wificlass" @click="padNextss">确 定</div>
				</div>
				<div v-show="wi_activ == 1">
					<div class="flexbtw">
						<div class="wiFitoolLibraryClassName">
							<div class="flexbtw">
								<div class="ToolLibrarylog">
									<img draggable="false" class="uasghjmsd" src="../../assets/img/imgHomePage/16.png"
										alt="" />
								</div>
								<div class="libraryText">
									<span class="fonst">工具库</span>
								</div>
							</div>
							<div class="planWrap">
								<ul class="business planWrap">
									<li v-for="(item, index) in wifiList" :key="index" style="text-align: center">
										<img :id="item.id" class="draggable imagboxsw wjskhbw" draggable="true" :src="
                        require(`../../assets/img/imgHomePage/${item.id}.jpg`)
                      " :alt="item" />
										<p class="libraryTextSize">{{ item.name }}</p>
									</li>
								</ul>
							</div>
						</div>
						<div>
							<div class="mapontheright">
								<img class="imawe" src="../../assets/img/imgHomePage/newMap.jpg" alt="" />
								<ul class="business dragList dragList2">
									<li class="droppableEle luyouxicas" data-index="60"
										ref="echoOfWirelessNetworkRouter"></li>
									<div id="zsjyhkmxBox" class="Riqghwt_click">
										<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png"
											alt="" />
									</div>
									<div class="toborxs"></div>
									<li class="droppableEle luyouxier" data-index="61" ref="eirewallEcho"></li>
									<div id="zsjqnixBox" class="Riqghwt_click">
										<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png"
											alt="" />
									</div>
									<div class="tobotoprxs"></div>
									<li class="droppableEle luyouxisan" data-index="62" ref="echoOfCentralSwitch"></li>
									<div id="zsjqox" class="Riqghwt_click">
										<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png"
											alt="" />
									</div>
									<div class="leftaszkde"></div>
									<li class="droppableEle luyouxisileft" data-index="63" ref="serverEcho"></li>
									<div id="zsjwLqox" class="Riqghwt_click">
										<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png"
											alt="" />
									</div>
									<div class="leftade"></div>
									<li class="droppableEle luyouxiwuright" data-index="64"
										ref="networkManagementControllerEcho"></li>
									<div id="zsjwLlowqox" class="Riqghwt_click">
										<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png"
											alt="" />
									</div>
									<div class="leftbottmsade"></div>
									<li class="droppableEle luyouxiliubottom" data-index="65" ref="echoOfPoeSwitch">
									</li>
									<div id="zsjwLlwowqox" class="Riqghwt_click">
										<img class="iconclass" src="../../assets/img/imgHomePage/deleteicon1.png"
											alt="" />
									</div>
									<div class="leftsade"></div>
									<div class="sawrxswwsuiw">
										<img draggable="false" src="../../assets/img/imgHomePage/xx.png" alt="" />
									</div>
								</ul>
							</div>
							<div class="dispcenter" style="margin-top: 15px">
								<div class="flexbtw">
									<div class="almsdew">
										<img draggable="false" src="../../assets/img/imgHomePage/xx.png" alt="" />
									</div>
									<div style="margin-top: 13px">作战指挥部</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-show="wi_activ == 2">
					<div style="height: 36px"></div>
					<div class="dispcenter">
						<div class="flexbtw" style="width: 90%">
							<div>
								<el-form :model="ruleForm" :rules="rules" ref="ruleForm" :label-width="formLabelWidth"
									class="demo-ruleForm">
									<el-form-item label="SSID" prop="codeType">
										<el-input class="inputs" v-model="SSIDwif" placeholder="请输入内容">
										</el-input>
										<!-- <el-input
                      class="inputs"
                      v-model="echoList.wireless_fidelity.base_param.SSID"
                      placeholder="请输入内容"
                    >
                    </el-input> -->

										<!-- <el-select
                        filterable
                        @change="SSIDUWwif"
                        v-model="SSIDwif"
                        placeholder="请输入内容"
                        class="poiuy"
                      >
                        <el-option
                          v-for="(item, index) in miqwqwryue"
                          :key="index"
                          :label="item"
                          :value="index"
                        >
                        </el-option>
                      </el-select> -->
									</el-form-item>
									<el-form-item label="是否隐藏 " prop="code">
										<el-radio v-model="whether_hide_SSID" label="是">是</el-radio>
										<el-radio  v-model="whether_hide_SSID" label="否">否</el-radio>
									</el-form-item>
									<el-form-item label="PSK密码" prop="code">
										<el-input show-password type="password" class="inputs" v-model="PSK_password"
											placeholder="请输入内容">
										</el-input>
									</el-form-item>
									<el-form-item label="  密钥周期" prop="code">
										<el-select filterable @change="secretKey" v-model="miyuew" placeholder="请选择内容"
											class="poiuy">
											<el-option v-for="(item, index) in miyue" :key="index" :label="item"
												:value="index">
											</el-option>
										</el-select>
									</el-form-item>
								</el-form>
							</div>
							<div>
								<el-form :model="ruleForm" :rules="rules" ref="ruleForm" :label-width="formLabelWidth"
									class="demo-ruleForm">
									<el-form-item label="安全性" prop="code">
										<el-select filterable @change="security" v-model="aqxnw" placeholder="请选择内容"
											class="poiuy">
											<el-option v-for="(item, index) in aqxlist" :key="index" :label="item"
												:value="index">
											</el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="AP频段" prop="code">
										<el-select filterable @change="FrequencyBan" v-model="pind" placeholder="请选择内容"
											class="poiuy">
											<el-option v-for="(item, index) in pindlist" :key="index" :label="item"
												:value="index">
											</el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="加密算法" prop="code">
										<el-select filterable @change="algorithm" v-model="jmsf" placeholder="请选择内容"
											class="poiuy">
											<el-option v-for="(item, index) in jmsfarrlist" :key="index" :label="item"
												:value="index">
											</el-option>
										</el-select>
									</el-form-item>
								</el-form>
							</div>
						</div>
					</div>
					<div style="height: 36px"></div>
					<!-- <div style="height: 40px"></div>
            <div class="dispcenter">
              <div class="wificlass" @click="padNextss">保 存</div>
            </div> -->
				</div>
			</el-dialog>
			<div class="wndsjwowm" v-if="scroqllbar" style="padding-bottom: 10px;">
				<img class="edjeeeews" @click="scroqllbar = false" src="../../assets/img/imgHomePage/deleteicon1.png"
					alt="" />
				<img class="wndsjwowmimg" src="../../assets/img/imgHomePage/give.png" alt="" />
				<el-scrollbar height="100%">
					<p v-for="item in state" :key="item" class="scrollbar-demo-item"
						:class="{ colorRed: item.indexOf('错误') > -1 }">
						{{ mydatetime }}:{{ item }}
					</p>
				</el-scrollbar>

			</div>
		</div>
	</div>
</template>
<script>
	// import VueDraggableResizable from 'vue-draggable-resizable'
	import {
		nullLiteral
	} from "@babel/types";
	import $ from "jquery";
	import {
		getTopics,
		getTopicscreaded,
		communicationdatasave,
		communicationdataread,
	} from "../../api/topics";
	import progressBar from "../../components/common/progressBar.vue";
	export default {
		name: "Intelligent",
		data() {
			return {
				ulShow: false,
				displaydevice: null,
				bluetoothNeviceName: '',
				pigeonecho: [],
				equipmentMod: "",
				equipmentName: ["N8-1", "N8-2", "N8-3", "N8-4"],
				BluetoothequipmentName: ["N8-1", "N8-2", "N8-3", "N8-4"],
				bluetoothDisplayDeviceVariables: null,
				padDeviceDisplay: null,
				woieuifrucdlsd: 0,
				echoVariableOfSweepingRobot: 0,
				judgeMobileNumber: 0,
				broadcastvariable: 0,
				currentId: window.sessionStorage.getItem("currentId"),
				echoList: {},
				padProgressBarName: 0,
				tabletTimer: null,
				interphoneProgressBarName: 0,
				interphoneTimer: null,
				phoneProgressBarName: 0,
				wifiTimer: null,
				routerProgressBarName: 0,
				passwordType: "",
				OtherNetworksIsshow: false,
				ssidDisplay: "",
				ClickToDisplay: false,
				singleChoice: "",
				ssidtoggleInputBoxd: "",
				ssidtoggleInputBoxd3: "",
				// toggleInput:0,
				oeilsukqwtim: 0,
				splwqaey: 0,
				scroqllbar: false,
				bluelanyatooth: "",
				blueOff: "",
				blueName: "",
				state: [],
				mydatetime: "",
				stateTips: [],
				walkieqx: "",
				walkieqendx: "",
				walkieEndY: "",
				padelLeqltx: "",
				padelLeqlty: "",
				startntY: "",
				PadstartntX: "",
				PadstartntY: "",
				startntX: "",
				elLeqltx: 0,
				elLeqlty: 0,
				startntY2: "",
				startntX2: "",
				startntY1: "",
				startntX1: "",
				wnasswrowisw: 0,
				dianwyuan: "",
				startcli2entX: "",
				startcl2ientY: "",
				elLeft1: "",
				elTop1: "",
				sngtime: "",
				delgclick: 0,
				clickNum1: 0,
				xiafns: false,
				xiafnsClick: false,
				xiafenHks: false,
				xiafenHksClick: true,
				swna: 0,
				canxiaow: false,
				canxiaowClick: true,
				canClick: true,
				canksk: false,
				cankesk: false,
				canCslick: true,
				canxiw: false,
				canxiwClick: true,
				timer: null,
				tabletbsorder: false,
				wnassowisw: 0,
				newShow: 0,
				targetID: "",
				recoveryIndex: 0,
				safetyArr: ["WPA/WPA2-personal"],
				power_switcharr: ["1", "2", "3", "4", "5"],
				channwel: ["1", "2", "3", "4", "5", "6", "7", "8"],
				businessListAry: [{
						name: "DC1",
						id: "DC1_switched_network"
					},
					{
						name: "DC2",
						id: "DC2_switched_network"
					},
					{
						name: "作战指挥部所在端局",
						id: "operations_command_end_office"
					},
					{
						name: "广电营业厅所在端局",
						id: "rt_service_hall_end_office"
					},
				],
				busessLis: [{
						name: "外部网络",
						id: "outer_net"
					},
					{
						name: "网关",
						id: "gateway"
					},
					{
						name: "核心网",
						id: "core_network"
					},
					{
						name: "基站",
						id: "base_station"
					},
					{
						name: "网管系统",
						id: "NMS"
					},
				],
				busLis: [{
						name: "振铃",
						id: "ring",
					},
					{
						name: "回铃音",
						id: "back_ring",
					},
					{
						name: "摘机接听",
						id: "pick_answer",
					},
					{
						name: "送催挂音",
						id: "reminder_hang",
					},
					{
						name: "被叫挂机",
						id: "called_hang_up",
					},
					{
						name: "主叫挂机",
						id: "calling_hang_up",
					},
				],
				bussLias: [{
						name: "CBK",
						id: "CBK"
					},
					{
						name: "IAM",
						id: "IAM"
					},
					{
						name: "ACM",
						id: "ACM"
					},
					{
						name: "ANC",
						id: "ANC"
					},
					{
						name: "CLF",
						id: "CLF"
					},
					{
						name: "RLG",
						id: "RLG"
					},
				],

				televisionList: [{
						name: "广播卫星",
						id: "broadcast_satellite"
					},
					{
						name: "上行发射控制站",
						id: "up_link_station"
					},
					{
						name: "卫星电视接收站",
						id: "satellite_television_accept_station"
					},
					{
						name: "国干SDH",
						id: "country_SDH"
					},
					{
						name: "省级SDH",
						id: "province_SDH"
					},
					{
						name: "自编节目",
						id: "own_program"
					},
					{
						name: "放大器",
						id: "amplifier"
					},
					{
						name: "分支器",
						id: "splitter"
					},
					{
						name: "混合器",
						id: "commingle"
					},
				],

				wifiList: [{
						name: "路由器",
						id: "router"
					},
					{
						name: "防火墙",
						id: "firewall"
					},
					{
						name: "中心交换机",
						id: "central_exchange"
					},
					{
						name: "服务器",
						id: "server"
					},
					{
						name: "网管控制器",
						id: "network_management_controller"
					},
					{
						name: "POE交换机",
						id: "POE_exchange"
					},
				],
				bluetoothSwitchOfSweepingRobot: false,
				lqswabqe: "",
				widuis: true,
				listyi: [{
						name: "PDU会话建立",
						imag: require("../../assets/img/imgHomePage/gezi01.png"),
					},
					{
						name: "RRC建立过程",
						imag: require("../../assets/img/imgHomePage/gezi03.png"),
					},
					{
						name: "安全模式建立",
						imag: require("../../assets/img/imgHomePage/gezi01.png"),
					},
					{
						name: "系统消息",
						imag: require("../../assets/img/imgHomePage/gezi01.png"),
					},
				],
				listTwo: [{
						name: "随机接入",
						imag: require("../../assets/img/imgHomePage/gezi02.png"),
					},
					{
						name: "初始UE消息",
						imag: require("../../assets/img/imgHomePage/gezi02.png"),
					},
					{
						name: "签权和加密",
						imag: require("../../assets/img/imgHomePage/gezi02.png"),
					},
				],
				form: {
					ssid: "",
				},

				tiuytyi: null,
				yikuaisd: "",
				yikqgezier: "",
				qidwisd: "",
				gewezier: "",
				ywendaisd: "",
				kqwemcczier: "",
				kuawisd: "",
				listgezi: [],
				// src: require('../../assets/img/imgHomePage/uwh.jpg'),
				bohaoishow: false,
				ssiqsakd: "010",
				phoneNumber: "",
				phoneNumberCopy: "",
				crnmua: true,
				labelNamelist: ["打开", "关闭"],
				lqsabqe: "",
				labelNa: ["打开", "关闭"],
				lsabe: "",
				laqwebelNa: ["打开", "关闭"],
				miyue: ["1200", "2400", "3600"],
				miyuew: "",
				aqxlist: ["WPA2-PSK", "WPA3无线加密", "WPA-PSK"],
				pindlist: ["2.4GHz", "5GHz"],
				pind: "",

				aqxnw: "",
				jmsfarrlist: ["AES", "TKIP", "TKIP$AES"],
				jmsf: "",
				lsedabe: "",
				SSIDwif: "",
				pindaouws: "",
				tbtnsw: "",
				gonglc: "",
				radio: "",
				editContentbox: false,
				editCoNanber: 0,
				isPadnum: 0,
				yushu: false,
				tabletborder: false,
				ssid: "",
				ssidpawored: "",
				PSK_password: "",
				isXttsy: false,
				tuozhuaibufen: false,
				routerIntroductionBtnsync: false,
				radios: "2",
				Imsds: false,
				isTelephoneS: false,
				fontactive: 1,
				qwsdkds: false,
				pnswqsndwq: false,
				shaos_qw: false,
				pnswq_sndwq: false,
				icon_s: false,
				shkuais_wqw: false,
				television_dialog: false,
				startclientX: 0, // 元素拖拽前距离浏览器的X轴位置
				startclientY: 0,
				elLeft: 0, // 元素的左偏移量
				elTop: 0,
				hghjdsew: true,
				robotBluetoothSwitch: "关闭",
				dragstartpclientX: 0,
				dragstartpclientY: 0,
				dragstartp_client_x: 0,
				dragstartp_client_y: 0,
				sdjhd: true,
				iswn_dw: 0,
				step: 0,
				waibuwlbl: 0,
				Swtep_swaan: 0,
				stwdesi_Swi: 0,
				wUkwses_iaaska: 0,

				Step_san: true,
				di_stuo_x: 0,
				di_stuo_y: 0,
				disbl_x: 0,
				disbl_y: 0,

				stesi_Si: true,
				si_stuo_x: 0,
				si_stuo_y: 0,
				diSi_sty_x: 0,
				diSi_sty_y: 0,

				wUkes_iska: true,
				diwu_tuo_x: 0,
				diwu_tuo_y: 0,
				diwu_sty_x: 0,
				diwu_sty_y: 0,

				isshow_zhenl: true,
				bl_zhenL_x: 0,
				bl_zhenL_y: 0,
				zhenL_po_x: 0,
				zhenL_po_y: 0,
				num_zhenl: 0,

				isshow_huiyL: true,
				bl_huiyL_x: 0,
				bl_huiyL_y: 0,
				huiyL_po_x: 0,
				huiyL_po_y: 0,
				num_huiyinl: 0,

				isduijjer: true,
				bianktuijX: 0,
				bianktuijY: 0,
				tuijr_X: 0,
				tuijr_Y: 0,
				duwijj_po: 0,

				isduijjSan: true,
				bianlduijjs_x: 0,
				bianlduijjs_y: 0,
				blDjjS_x: 0,
				blDjjS_y: 0,
				duiJjS_Po: 0,

				isduijjsi: true,
				blduijjsi_x: 0,
				blduijjsi_y: 0,
				bldjj_po_x: 0,
				bldjj_po_y: 0,
				duijjsi_po: 0,

				isActivehande: 1,
				duijiangname: 0,
				wisdmsow: 0,
				mobile_active: 1,
				guanjisis: 0,
				dianshiishs: 0,
				wi_activ: 1,
				fissienws: 0,
				isluyouq: true,
				luyouqkai_x: 0,
				luyouqkai_y: 0,
				luyouq_po_x: 0,
				luyouq_po_y: 0,
				blluyou: 0,
				isfanghuoq: true, //防火墙
				fanghuoq_x: 0,
				fanghuoq_y: 0,
				fanghuoq_po_x: 0,
				fanghuoq_po_y: 0,
				blfanghq: 0,
				isjiaohuaj: true, //交换机
				jiaohj_x: 0,
				jiaohj_y: 0,
				jiaohj_po_x: 0,
				jiaohj_po_y: 0,
				jhj_jiaohuanjpo: 0,
				isTheServer: true, //服务器
				theserx: 0,
				thesery: 0,
				theser_po_x: 0,
				theser_po_y: 0,
				theservpo: 0,
				iskongzhiq: true, //控制器
				kongzx: 0,
				kongzy: 0,
				kongzhiq_po_x: 0,
				kongzhiq_po_y: 0,
				kongzibl: 0,
				ispoejhq: true, //交换器
				pojhsqx: 0,
				pojhsqy: 0,
				pocjhq_po_x: 0,
				pocjhq_po_y: 0,
				pockzjbl: 0,
				isgbwxww: true, //广播卫星
				guangboweixwqx: 0,
				guangboweixwqy: 0,
				guangbo_po_x: 0,
				guangbo_po_y: 0,
				guangboweixwns: 0,
				isfashez: true, //发射控制站
				fashekzsx: 0,
				fashekzsy: 0,
				fashekza_po_x: 0,
				fashekza_po_y: 0,
				fashekongzs: 0,
				isweixingjieshz: true, //卫星接收站
				weixingjieshx: 0,
				weixingjieshy: 0,
				weixingjs_po_x: 0,
				weixingjs_po_y: 0,
				weixingjisz: 0,
				isguganqx: true, //骨干
				guganx: 0,
				gugany: 0,
				gugans_po_x: 0,
				gugans_po_y: 0,
				gugansdhs: 0,
				isshenggans: true, //省干sdh
				shenggx: 0,
				shenggy: 0,
				shengga_po_x: 0,
				shengga_po_y: 0,
				sewwdshengg: 0,
				iszibianjm: true, //自编节目
				zibianx: 0,
				zibiany: 0,
				zibianjm_po_x: 0,
				zibianjm_po_y: 0,
				zibianw: 0,
				isfangdaj: true, //放大镜
				fangdjx: 0,
				fangdjy: 0,
				fangdaj_po_x: 0,
				fangdaj_po_y: 0,
				ssfangdajx: 0,
				isfenzhiq: true, //分支器
				fenzhiqx: 0,
				fenzhiqy: 0,
				fenzhiq_po_x: 0,
				fenzhiq_po_y: 0,
				fenzhiqhsa: 0,
				ishuiyinl_hyl: true, //IAM
				bl_IAM_y: 0,
				bl_IAM_x: 0,
				huiyl_po_x: 0,
				huiyl_po_y: 0,
				iAM_zheswnl: 0,
				zhaiji_hyl: true, //摘机
				bl_zhuaijL_x: 0,
				bl_zhuaijL_y: 0,
				zhuaiji_po_x: 0,
				zhuaiji_po_y: 0,
				seret: "",
				wifisaiingex: "",
				zhuaijnum_zhenl: 0,
				zhujigj_hyl: true, //主机挂机
				bl_zhujguajs_y: 0,
				bl_zhujguajs_x: 0,
				zjguaji_po_x: 0,
				zjguaji_po_y: 0,
				zjguajinum_zhenl: 0,
				beijiaogj_hyl: true, //被叫挂机
				beijiaogj_x: 0,
				beijiaogj_y: 0,
				beijgsz_po_x: 0,
				beijgsz_po_y: 0,
				qwuwenwqs_zhenl: 0,
				cbkabk_hyl: true, //cbk
				cbkbianl_x: 0,
				cbkbianl_y: 0,
				cbkabkle_po_x: 0,
				cbkabkle_po_y: 0,
				cbksq_zhenl: 0,
				iAM_hyl: true, //iam
				iambl_x: 0,
				iambl_y: 0,
				iamsuw_po_x: 0,
				iamsuw_po_y: 0,
				iamitem_zhenl: 0,
				acmit_hyl: true,
				acmitq_x: 0,
				acmitq_y: 0,
				acmqitq_po_x: 0,
				acmqitq_po_y: 0,
				iamacm_zhenl: 0,
				ancam_hyl: true, //anc
				acnitq_x: 0,
				acnitq_y: 0,
				acninw_po_x: 0,
				acninw_po_y: 0,
				anczywc_zhenl: 0,
				clfitme_hyl: true, //CLF
				aclf_x: 0,
				aclf_y: 0,
				aclf_po_x: 0,
				aclf_po_y: 0,
				acqlsf_zhenl: 0,
				csBlg_hyl: true, //BLG
				aclBlgf_x: 0,
				aclBlgf_y: 0,
				acqlwwsf_zhwenl: 0,
				geziyilef: 0, //动画鸽子
				timer: null,
				geziyiri: 0,
				dingshiq: 0,
				wiwtime: null,

				geziwtssyiri: 10, //rrc建立过程
				gezsiywilef: 0,
				rrcjianlgc: 0,
				rrctimer: null, //rrc
				rrcwiwtime: null,
				gezqiyisslef: 0,

				xiongxleft: 0,
				xiongxtright: 10,
				xitongxftimer: null,
				xitongxwiwtime: null,
				xitongnlgc: 0,

				ongnqlgc: 0,
				suiji_left: 0,
				suiji_top: 0,
				suijstimer: null,
				suijsretimer: null,

				csjlsxw: 0,
				chushi_left: 0,
				chushi_top: 0,
				chushitimer: null,
				chushiertime: null,

				SigningRight: 0,
				Signing_left: 0,
				Signing_top: 0,
				Signingtime: null,
				gtsime: null,
				mobileProgressBarName: 0,
				safeMode: 0,
				safeMode_left: 0,
				safeMode_top: 0,
				safesModets: null,
				satime: null,
				arrwodi: 0,
				machinevariable: 0,
				pduwois: 0,
				wiuehuti: null,
				wiwtwqwime: null,
				wiwwduehuti: null,
				wiwtwqwsadeime: null,
				timer_ouyti: null,
				timer_ouyty: null,
				timphuyty: null,
				timphuytyi: null,
				xiaHks: false,
				xiaHksClick: true,
				ssidSSID: "",
				ssidPassword: "",
				safety: "",
				automatic_connection: "",
				scan_any_time: "",
				blue_tooth: "",
				safetyindex: 0,
				Connection: "",
				scancangtime: "",
				blue_index_tooth: "",
				sweepingRobotVariables: 0,
				powercaneg: "",
				switchcangq: "",
				securityIndex: "",
				Frequency_ban_index: "",
				algorithmIndex: "",
				powerchangep: "",
				communicationWitchq: "",
				whether_hide_SSID: "",
				obj: {},
				onDemandDisplay: 0,
				timessiqsakd: null,
				dialthewrongnumbe: 0,
				timessiqsdial: null,
				timessTimeiqsdial: 0,
				mobileTimer: null,
				sweepingrobotTimer: null,
				tVProgressBarName: 0,
				tvTimer: null,
				miqwqwryue: [
					"oppo reno5 5g",
					"Galaxy S20",
					"digrct-8d-hp",
					"dt",
					"huawei mate",
				],
				miqerryue: [
					"oppo reno5 5g",
					"Galaxy S20",
					"digrct-8d-hp",
					"dt",
					"huawei mate",
				],
				miqerArrryue: [], //
				iwinishow: 0,
				Interphone: 0,
				ssiqswewfeakd: "",
				sweepingRobotProgressBa: 0,
				sweepingRobotProgressBarName: 0,
				mobileechoVariable: 0,
				routerEchoVariable: 0,
				EchoDisplayArr: [],
				defaultDisplayPrompt: 0,
				performSweepingTaskisshow: false,
				performSweepingTaskisshowClick: true,
				executeSweepingTaskCommand: 0,
				correctBackgroundImage: 0,
				wrongMessagePrompt: 0,
				showDeviceName: null,
				floorRobot: null, //扫地机器人
				container: ["", "", "", "", "", ""], //信令流程拖拽容器
				selector: "", //选择的WLAN
				clickLY: 0,
				conversionG: "", //title换算
				isInit:0,
				phoneNumberInit:0,
				mobileInit:0,
				televInit:0
			};
		},

		created() {
			this.businessListAry = this.businessListAry.sort(() => 0.5 - Math.random());
			this.busLis = this.busLis.sort(() => 0.5 - Math.random());
			this.bussLias = this.bussLias.sort(() => 0.5 - Math.random());
			this.busessLis = this.busessLis.sort(() => 0.5 - Math.random());
			this.televisionList = this.televisionList.sort(() => 0.5 - Math.random());
			this.wifiList = this.wifiList.sort(() => 0.5 - Math.random());
			// this.getTopicscreadedpost()
			// this.communicationdatareadpost()
			this.EchoDisplayArr = this.$store.state.EchoArrLiat;

		},
		mounted() {
			this.communicationdatareadpost();
			// this.routerEcho()
			

		},
		updated() {
			if (this.woieuifrucdlsd == 1 && this.televInit == 0) {
				// 电视回显
				this.televisionEcho();
				this.televInit = 1;
			}
			if (this.echoVariableOfSweepingRobot == 1 && this.phoneNumberInit == 0) {
				// 电话网络配置
				this.telephoneNetworkConfiguration();
				// 电话信令流程回显
				this.telephoneSignalingProcessEcho();
        		this.phoneNumberInit = 1;
			}
			if (this.mobileechoVariable == 1 && this.mobileInit == 0) {
				// 手机网络配置
				this.cellularConfigurationEcho();
				// 手机开机流程
				this.echoOfMobilePhoneStartupprocess();
				this.mobileInit = 1;
			}
			if (this.routerEchoVariable == 1 && this.isInit == 0) {
				// wifi回显
				this.routerEcho();
        		this.isInit = 1;
			}
		},
		components: {
			progressBar,
		},

		methods: {
			dialogClose() {
				this.preservationBtnName();
			},
			dialogClose2() {
        this.bohaoishow = false;
      },
			// 蓝牙开关-5
			monitorBluetooth(callback) {
				this.displaydevice = callback;
			},
			// bluetoothNevicebtn(da){

			// },
			PadDisplayDeviceVariables(t) {
				this.bluetoothDisplayDeviceVariables = this.floorRobot
				this.padDeviceDisplay = this.equipmentName[this.equipmentMod]
			},
			// 回显
			communicationdatareadpost() {
				let dataobj = new FormData();
				dataobj.append("user_id", this.$store.state.user_id);
				dataobj.append("data_sign", "intelligence_communication");
				dataobj.append("task_id", this.$store.state.taskId);
				dataobj.append("value_sign", "all");
				dataobj.append("template_id", this.$store.state.template_id);
				communicationdataread(dataobj).then((res) => {
					if (res.data.code == 200 && res.data.data !== "") {
						this.echoList = JSON.parse(res.data.data);
						if (this.echoList.tablet_computer.base_param.usable_blue_tooth_name == null) {
							let facilityArray = ["N8-1", "N8-2", "N8-3", "N8-4"]
							let random = Math.floor(Math.random() * (facilityArray.length - 0));
							this.floorRobot = facilityArray[random]
							this.echoList.tablet_computer.base_param.usable_blue_tooth_name = this.floorRobot
						} else {
							this.floorRobot = this.echoList.tablet_computer.base_param.usable_blue_tooth_name

						}
						setTimeout(() => {
							this.obj = this.echoList;
						}, 1000);

						// pad回显
						this.tabletEcho();
						// 扫地机器人回显
						if (
							this.echoList.floor_mopping_robot.base_param.blue_tooth == "打开"
						) {
							this.displaydevice = true
							this.bluetoothSwitchOfSweepingRobot = true;
							this.robotBluetoothSwitch = "打开";
						} else {
							this.displaydevice = false
							this.robotBluetoothSwitch = "关闭";
							this.bluetoothSwitchOfSweepingRobot = false;
						}
						// 对讲机回显
						this.interphoneEcho();
					}
				});
			},
			// 初始化格式
			getTopicscreadedpost() {
				getTopicscreaded().then((res) => {
					this.obj = JSON.parse(res.data.data);
				});
			},
			// wifi回显
			routerEcho() {
				this.SSIDwif = this.echoList.wireless_fidelity.base_param.SSID;
				this.whether_hide_SSID =
					this.echoList.wireless_fidelity.base_param.whether_hide_SSID;
				this.PSK_password =
					this.echoList.wireless_fidelity.base_param.PSK_password;
				this.miyuew = this.echoList.wireless_fidelity.base_param.secret_key_time;
				this.aqxnw = this.echoList.wireless_fidelity.base_param.safety;
				this.pind = this.echoList.wireless_fidelity.base_param.AP_band;
				this.jmsf = this.echoList.wireless_fidelity.base_param.encryption;

				if (this.echoList.wireless_fidelity.network_config.base_param.router) {
					let echoOfWirelessNetworkRouterimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.wireless_fidelity.network_config.base_param.router +
						".jpg");
					this.$refs.echoOfWirelessNetworkRouter.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						echoOfWirelessNetworkRouterimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (this.echoList.wireless_fidelity.network_config.base_param.firewall) {
					let eirewallEchoimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.wireless_fidelity.network_config.base_param.firewall +
						".jpg");
					this.$refs.eirewallEcho.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						eirewallEchoimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (
					this.echoList.wireless_fidelity.network_config.base_param
					.central_exchange
				) {
					let echoOfCentralSwitchimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.wireless_fidelity.network_config.base_param
						.central_exchange +
						".jpg");
					this.$refs.echoOfCentralSwitch.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						echoOfCentralSwitchimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (this.echoList.wireless_fidelity.network_config.base_param.server) {
					let serverEchoimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.wireless_fidelity.network_config.base_param.server +
						".jpg");
					this.$refs.serverEcho.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						serverEchoimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (
					this.echoList.wireless_fidelity.network_config.base_param
					.network_management_controller
				) {
					let networkManagementControllerEchoimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.wireless_fidelity.network_config.base_param
						.network_management_controller +
						".jpg");
					this.$refs.networkManagementControllerEcho.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						networkManagementControllerEchoimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (
					this.echoList.wireless_fidelity.network_config.base_param.POE_exchange
				) {
					let echoOfPoeSwitchimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.wireless_fidelity.network_config.base_param
						.POE_exchange +
						".jpg");
					this.$refs.echoOfPoeSwitch.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						echoOfPoeSwitchimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}

				let that = this;
				that.qwoidmo3idoy(that);
			},
			// pad回显
			tabletEcho() {
				this.blueName = this.echoList.floor_mopping_robot.base_param.blue_tooth_name;
				this.blueOff = this.echoList.floor_mopping_robot.base_param.blue_tooth;

				this.ssidPassword = this.echoList.tablet_computer.base_param.password;

				// this.echoList.tablet_computer.base_param.scan_any_time;
				// this.automatic_connection =
				this.echoList.tablet_computer.base_param.automatic_connection;
				this.bluelanyatooth = this.echoList.tablet_computer.base_param.blue_tooth;

				if (this.echoList.tablet_computer.base_param.blue_tooth == "打开") {
					this.showDeviceName = 0;
				} else {
					this.showDeviceName = 1;
				}
			},
			//  电视回显
			televisionEcho() {
				if (
					this.echoList.television.network_config.base_param.broadcast_satellite
				) {
					let imgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.television.network_config.base_param
						.broadcast_satellite +
						".jpg");
					this.$refs.televisionhtmlImg.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						imgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (this.echoList.television.network_config.base_param.up_link_station) {
					let uplinkTransmissionControlStationimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.television.network_config.base_param.up_link_station +
						".jpg");
					this.$refs.uplinkTransmissionControlStation.innerHTML =
						'<span class="delectPic  uplinkTransmissionName" ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						uplinkTransmissionControlStationimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (
					this.echoList.television.network_config.base_param
					.satellite_television_accept_station
				) {
					let satelliteTVReceivingStationImgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.television.network_config.base_param
						.satellite_television_accept_station +
						".jpg");
					this.$refs.satelliteTVReceivingStation.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						satelliteTVReceivingStationImgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}

				if (this.echoList.television.network_config.base_param.commingle) {
					let mixerImgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.television.network_config.base_param.commingle +
						".jpg");
					this.$refs.mixer.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						mixerImgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (this.echoList.television.network_config.base_param.country_SDH) {
					let nationalImgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.television.network_config.base_param.country_SDH +
						".jpg");
					this.$refs.national.innerHTML =
						'<span class="delectPic"></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						nationalImgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (this.echoList.television.network_config.base_param.province_SDH) {
					let provincialLevelImgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.television.network_config.base_param.province_SDH +
						".jpg");
					this.$refs.provincialLevel.innerHTML =
						'<span class="delectPic"></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						provincialLevelImgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}

				if (this.echoList.television.network_config.base_param.own_program) {
					let selfMadeProgramImgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.television.network_config.base_param.own_program +
						".jpg");
					this.$refs.selfMadeProgram.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						selfMadeProgramImgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}

				if (this.echoList.television.network_config.base_param.amplifier) {
					let amplisfierImgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.television.network_config.base_param.amplifier +
						".jpg");
					this.$refs.amplisfier.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						amplisfierImgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (this.echoList.television.network_config.base_param.splitter) {
					let branchingDeviceImgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.television.network_config.base_param.splitter +
						".jpg");
					this.$refs.branchingDevice.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						branchingDeviceImgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				let that = this;
				that.qwoidmo3idoy(that);

				if (this.obj.tablet_computer.base_param.blue_tooth == '打开') {
					this.showDeviceName = 0
				} else {
					this.showDeviceName = 1
				}

				if (this.obj.tablet_computer.base_param.automatic_connection == '打开') {
					this.automatic_connection = 0
				} else {
					this.automatic_connection = 1
				}

				if (this.obj.tablet_computer.base_param.scan_any_time == '打开') {
					this.scan_any_time = 0
				} else {
					this.scan_any_time = 1
				}



			},
			// 电话网络配置回显
			telephoneNetworkConfiguration() {
				if (
					this.echoList.wire_telephone.network_config.base_param
					.DC1_switched_network
				) {
					let clasnameDc1imgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.wire_telephone.network_config.base_param
						.DC1_switched_network +
						".jpg");
					this.$refs.clasnameDc1.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						clasnameDc1imgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (
					this.echoList.wire_telephone.network_config.base_param
					.DC2_switched_network
				) {
					let claswqnameDc2imgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.wire_telephone.network_config.base_param
						.DC2_switched_network +
						".jpg");
					this.$refs.claswqnameDc2.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						claswqnameDc2imgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (
					this.echoList.wire_telephone.network_config.base_param
					.operations_command_end_office
				) {
					let echoOfTheEndOfficeWhereTheBattleCommandIsLocatedimg = require("@/assets/img/imgHomePage/" +
						this.echoList.wire_telephone.network_config.base_param
						.operations_command_end_office +
						".jpg");
					this.$refs.echoOfTheEndOfficeWhereTheBattleCommandIsLocated.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						echoOfTheEndOfficeWhereTheBattleCommandIsLocatedimg +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (
					this.echoList.wire_telephone.network_config.base_param
					.rt_service_hall_end_office
				) {
					let echoOfTheTerminalOfficeWhereTheRadioAndTelevisionBusinessHallIsLocatedimg = require(
						"@/assets/img/imgHomePage/" +
						this.echoList.wire_telephone.network_config.base_param
						.rt_service_hall_end_office +
						".jpg");
					this.$refs.echoOfTheTerminalOfficeWhereTheRadioAndTelevisionBusinessHallIsLocated.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						echoOfTheTerminalOfficeWhereTheRadioAndTelevisionBusinessHallIsLocatedimg +
						'" alt="' +
						this.targetID +
						'">';
				}
				let that = this;
				that.qwoidmo3idoy(that);
			},
			// 电话信令流程回显
			telephoneSignalingProcessEcho() {
				// let skorage = localStorage.getItem("calback")
				// if (skorage != null) {
				// 	this.container = JSON.parse(skorage)
				// }
				if(this.echoList.container){
					this.container = this.echoList.container;
				}

				if (this.echoList.wire_telephone.signaling_procedure.base_param.IAM) {
					let iamEchoimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.wire_telephone.signaling_procedure.base_param.IAM +
						".jpg");
					this.$refs.iamEcho.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						iamEchoimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (this.echoList.wire_telephone.signaling_procedure.base_param.ACM) {
					let acmEchoimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.wire_telephone.signaling_procedure.base_param.ACM +
						".jpg");
					this.$refs.acmEcho.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						acmEchoimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (this.echoList.wire_telephone.signaling_procedure.base_param.ring) {
					let ringEchoimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.wire_telephone.signaling_procedure.base_param.ring +
						".jpg");
					this.$refs.ringEcho.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						ringEchoimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (
					this.echoList.wire_telephone.signaling_procedure.base_param.back_ring
				) {
					let echoEchoimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.wire_telephone.signaling_procedure.base_param
						.back_ring +
						".jpg");
					this.$refs.echoEcho.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						echoEchoimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (this.echoList.wire_telephone.signaling_procedure.base_param.ANC) {
					let ancEchoimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.wire_telephone.signaling_procedure.base_param.ANC +
						".jpg");
					this.$refs.ancEcho.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						ancEchoimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (
					this.echoList.wire_telephone.signaling_procedure.base_param.pick_answer
				) {
					let offHookAnswerEchoimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.wire_telephone.signaling_procedure.base_param
						.pick_answer +
						".jpg");
					this.$refs.offHookAnswerEcho.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						offHookAnswerEchoimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}

				if (
					this.echoList.wire_telephone.signaling_procedure.base_param.phone_num
				) {

					if (this.echoList.wire_telephone.signaling_procedure.base_param.phone_num == "010-8888 6666") {
						this.phoneNumber = '88886666'
						this.phoneNumberCopy = this.phoneNumber
					} else {
						this.phoneNumber =
							this.echoList.wire_telephone.signaling_procedure.base_param.phone_num.slice(4);
						this.phoneNumberCopy = this.phoneNumber
					}

				}

				if (
					this.echoList.wire_telephone.signaling_procedure.base_param.phone_num ==
					"010-8888 6666"
				) {
					this.judgeMobileNumber = 1;
				} else {
					this.judgeMobileNumber = 2;
				}
				if (this.judgeMobileNumber == 1) {
					// 1是对的
					if (
						this.echoList.wire_telephone.signaling_procedure.base_param
						.calling_hang_up
					) {
						let callerHangUEchoimgUrl = require("@/assets/img/imgHomePage/" +
							this.echoList.wire_telephone.signaling_procedure.base_param
							.calling_hang_up +
							".jpg");
						this.$refs.callerHangUEcho.innerHTML =
							'<span class="delectPic " ></span><img draggable="false" id="recovery' +
							this.recoveryIndex +
							'"src="' +
							callerHangUEchoimgUrl +
							'" alt="' +
							this.targetID +
							'">';
					}
					if (
						this.echoList.wire_telephone.signaling_procedure.base_param
						.reminder_hang
					) {
						let sendReminderEchoimgUrl = require("@/assets/img/imgHomePage/" +
							this.echoList.wire_telephone.signaling_procedure.base_param
							.reminder_hang +
							".jpg");
						this.$refs.sendReminderEcho.innerHTML =
							'<span class="delectPic " ></span><img draggable="false" id="recovery' +
							this.recoveryIndex +
							'"src="' +
							sendReminderEchoimgUrl +
							'" alt="' +
							this.targetID +
							'">';
					}
					if (this.echoList.wire_telephone.signaling_procedure.base_param.CLF) {
						let clfEchoimgUrl = require("@/assets/img/imgHomePage/" +
							this.echoList.wire_telephone.signaling_procedure.base_param.CLF +
							".jpg");
						this.$refs.clfEcho.innerHTML =
							'<span class="delectPic " ></span><img draggable="false" id="recovery' +
							this.recoveryIndex +
							'"src="' +
							clfEchoimgUrl +
							'" alt="' +
							this.targetID +
							'">';
					}
					if (this.echoList.wire_telephone.signaling_procedure.base_param.RLG) {
						let rlgEchoimgUrl = require("@/assets/img/imgHomePage/" +
							this.echoList.wire_telephone.signaling_procedure.base_param.RLG +
							".jpg");
						this.$refs.rlgEcho.innerHTML =
							'<span class="delectPic " ></span><img draggable="false" id="recovery' +
							this.recoveryIndex +
							'"src="' +
							rlgEchoimgUrl +
							'" alt="' +
							this.targetID +
							'">';
					}
				} else if (this.judgeMobileNumber == 2) {
					if (
						this.echoList.wire_telephone.signaling_procedure.base_param
						.reminder_hang
					) {
						let callerHangUEchoimgUrl = require("@/assets/img/imgHomePage/" +
							this.echoList.wire_telephone.signaling_procedure.base_param
							.reminder_hang +
							".jpg");
						this.$refs.callerHangUEcho.innerHTML =
							'<span class="delectPic " ></span><img draggable="false" id="recovery' +
							this.recoveryIndex +
							'"src="' +
							callerHangUEchoimgUrl +
							'" alt="' +
							this.targetID +
							'">';
					}
					if (
						this.echoList.wire_telephone.signaling_procedure.base_param
						.called_hang_up
					) {
						let sendReminderEchoimgUrl = require("@/assets/img/imgHomePage/" +
							this.echoList.wire_telephone.signaling_procedure.base_param
							.called_hang_up +
							".jpg");
						this.$refs.sendReminderEcho.innerHTML =
							'<span class="delectPic " ></span><img draggable="false" id="recovery' +
							this.recoveryIndex +
							'"src="' +
							sendReminderEchoimgUrl +
							'" alt="' +
							this.targetID +
							'">';
					}
					if (this.echoList.wire_telephone.signaling_procedure.base_param.CBK) {
						let clfEchoimgUrl = require("@/assets/img/imgHomePage/" +
							this.echoList.wire_telephone.signaling_procedure.base_param.CBK +
							".jpg");
						this.$refs.clfEcho.innerHTML =
							'<span class="delectPic " ></span><img draggable="false" id="recovery' +
							this.recoveryIndex +
							'"src="' +
							clfEchoimgUrl +
							'" alt="' +
							this.targetID +
							'">';
					}
					if (this.echoList.wire_telephone.signaling_procedure.base_param.CLF) {
						let rlgEchoimgUrl = require("@/assets/img/imgHomePage/" +
							this.echoList.wire_telephone.signaling_procedure.base_param.CLF +
							".jpg");
						this.$refs.rlgEcho.innerHTML =
							'<span class="delectPic " ></span><img draggable="false" id="recovery' +
							this.recoveryIndex +
							'"src="' +
							rlgEchoimgUrl +
							'" alt="' +
							this.targetID +
							'">';
					}
					if (this.echoList.wire_telephone.signaling_procedure.base_param.RLG) {
						let rLGEchoimgUrl = require("@/assets/img/imgHomePage/" +
							this.echoList.wire_telephone.signaling_procedure.base_param.RLG +
							".jpg");
						this.$refs.rLGEcho.innerHTML =
							'<span class="delectPic " ></span><img draggable="false" id="recovery' +
							this.recoveryIndex +
							'"src="' +
							rLGEchoimgUrl +
							'" alt="' +
							this.targetID +
							'">';
					}
				}
				this.isShow();
				let that = this;
				that.qwoidmo3idoy(that);

			},
			// 手机网络配置
			cellularConfigurationEcho() {
				if (
					this.echoList.mobile_communication.network_design.base_param.outer_net
				) {
					let externalNetworkEchoimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.mobile_communication.network_design.base_param
						.outer_net +
						".jpg");
					this.$refs.externalNetworkEcho.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						externalNetworkEchoimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (
					this.echoList.mobile_communication.network_design.base_param.gateway
				) {
					let gatewayEchoimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.mobile_communication.network_design.base_param.gateway +
						".jpg");
					this.$refs.gatewayEcho.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						gatewayEchoimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (
					this.echoList.mobile_communication.network_design.base_param
					.core_network
				) {
					let coreNetworkEchoimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.mobile_communication.network_design.base_param
						.core_network +
						".jpg");
					this.$refs.coreNetworkEcho.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						coreNetworkEchoimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (
					this.echoList.mobile_communication.network_design.base_param
					.base_station
				) {
					let baseStationEchoimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.mobile_communication.network_design.base_param
						.base_station +
						".jpg");
					this.$refs.baseStationEcho.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						baseStationEchoimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}
				if (this.echoList.mobile_communication.network_design.base_param.NMS) {
					let networkManagementSystemEchoimgUrl = require("@/assets/img/imgHomePage/" +
						this.echoList.mobile_communication.network_design.base_param.NMS +
						".jpg");
					this.$refs.networkManagementSystemEcho.innerHTML =
						'<span class="delectPic " ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						networkManagementSystemEchoimgUrl +
						'" alt="' +
						this.targetID +
						'">';
				}

				let that = this;
				that.qwoidmo3idoy(that);
			},
			// 手机开机流程
			echoOfMobilePhoneStartupprocess() {
				if (
					this.echoList.mobile_communication.boot_process.base_param.sys_msg !==
					null &&
					this.echoList.mobile_communication.boot_process.base_param.sys_msg !==
					undefined
				) {
					this.listgezi[0] =
						this.echoList.mobile_communication.boot_process.base_param.sys_msg;
				}
				if (
					this.echoList.mobile_communication.boot_process.base_param
					.random_join !== null &&
					this.echoList.mobile_communication.boot_process.base_param
					.random_join !== undefined
				) {
					this.listgezi[1] =
						this.echoList.mobile_communication.boot_process.base_param.random_join;
				}
				if (
					this.echoList.mobile_communication.boot_process.base_param.RRC_setup !==
					null &&
					this.echoList.mobile_communication.boot_process.base_param.RRC_setup !==
					undefined
				) {
					this.listgezi[2] =
						this.echoList.mobile_communication.boot_process.base_param.RRC_setup;
				}
				if (
					this.echoList.mobile_communication.boot_process.base_param
					.initial_context !== null &&
					this.echoList.mobile_communication.boot_process.base_param
					.initial_context !== undefined
				) {
					this.listgezi[3] =
						this.echoList.mobile_communication.boot_process.base_param.initial_context;
				}

				if (
					this.echoList.mobile_communication.boot_process.base_param
					.authentication_encryption !== null &&
					this.echoList.mobile_communication.boot_process.base_param
					.authentication_encryption !== undefined
				) {
					this.listgezi[4] =
						this.echoList.mobile_communication.boot_process.base_param.authentication_encryption;
				}
				if (
					this.echoList.mobile_communication.boot_process.base_param
					.safe_mode_setup !== null &&
					this.echoList.mobile_communication.boot_process.base_param
					.safe_mode_setup !== undefined
				) {
					this.listgezi[5] =
						this.echoList.mobile_communication.boot_process.base_param.safe_mode_setup;
				}
				if (
					this.echoList.mobile_communication.boot_process.base_param
					.PDU_session_setup !== null &&
					this.echoList.mobile_communication.boot_process.base_param
					.PDU_session_setup !== undefined
				) {
					this.listgezi[6] =
						this.echoList.mobile_communication.boot_process.base_param.PDU_session_setup;
				}
				if (
					this.echoList.mobile_communication.boot_process.base_param
					.sweep_floor_task !== null &&
					this.echoList.mobile_communication.boot_process.base_param
					.sweep_floor_task !== undefined
				) {
					this.listgezi[7] =
						this.echoList.mobile_communication.boot_process.base_param.sweep_floor_task;
				}

			},
			// 对讲机回显
			interphoneEcho() {
				this.dianwyuan = this.echoList.inter_phone.base_param.power_switch;
				this.gonglc = this.echoList.inter_phone.base_param.power;
				this.pindaouws = this.echoList.inter_phone.base_param.channel;
				this.tbtnsw = this.echoList.inter_phone.base_param.communication_switch;
			},
			Interphonetrue() {
				this.Interphone = 0;
			},
			automwatic(k) {
				this.clickLY = 1
				this.selector = this.echoList.tablet_computer.base_param.SSID;
				sessionStorage.setItem("selector", this.echoList.tablet_computer.base_param.SSID);
				this.ssidtoggleInputBoxd = k;
				this.ClickToDisplay = true;
				this.OtherNetworksIsshow = false;
			},
			OtherNetworksBtn() {
				this.clickLY = 2
				sessionStorage.setItem("selector", '其他网络');
				// this.conversionG  = this.ssidtoggleInputBoxd3
				if (this.echoList.tablet_computer.base_param.SSID != "oppo reno5 5g" &&
					this.echoList.tablet_computer.base_param.SSID != "Galaxy S20" &&
					this.echoList.tablet_computer.base_param.SSID != "digrct-8d-hp" &&
					this.echoList.tablet_computer.base_param.SSID != "dt" &&
					this.echoList.tablet_computer.base_param.SSID != "huawei mate" &&
					this.echoList.tablet_computer.base_param.SSID != this.SSIDwif
				) {
					this.ssidtoggleInputBoxd3 = this.echoList.tablet_computer.base_param.SSID
				}
				this.singleChoice = "";
				this.ClickToDisplay = true;
				this.OtherNetworksIsshow = true;
				// this.iwinishow = 1 xx
			},
			changsafety(data) {
				this.safetyindex = data;
			},
			cang_connection(data) {
				this.Connection = data;
			},
			scan_cang_time(data) {
				this.scancangtime = data;
			},
			PlateID(d) {
				this.sngtime = d;
			},
			bluetooth(data) {
				this.blue_index_tooth = data;
				this.showDeviceName = data;
			},
			power_caneg(d) {
				this.powercaneg = d;
			},
			switchcang(t) {
				this.switchcangq = t-1;
			},
			powerchange(i) {
				this.powerchangep = i-1;
			},
			communicationWitch(d) {
				this.communicationWitchq = d;
			},
			returnHome() {
				this.preservationBtnName()
				this.$router.push("/");
			},
			//点击通话
			clickToCall() {
				if (this.phoneNumber == "") {
					this.$message({
						message: "请先输入电话号",
						type: "warning",
					});
					return;
				}
				// 默认显示的背景图
				// this.wnassowisw = 1;
				// document.querySelector(".beijiaogualeft").innerHTML = "";
				// document.querySelector(".zhujiaogajright").innerHTML = "";
				// document.querySelector(".cbkboxcl").innerHTML = "";
				// document.querySelector(".CLFboxcl").innerHTML = "";
				// document.querySelector(".RLGboxcl").innerHTML = "";
				// debugger


				if (this.phoneNumber == "88886666" &&
					this.container[0] == "back_ring" &&
					this.container[1] == "IAM" &&
					this.container[2] == "ACM" &&
					this.container[3] == "ANC" &&
					this.container[4] == "ring" &&
					this.container[5] == "pick_answer") {
					this.ulShow = true; //选对答案显示下面的内容
					// 默认显示的背景图
					this.wnassowisw = 1;
					// 切换成对的背景图
					this.wnasswrowisw = 2;					
					// 消息对话框
					if (this.correctBackgroundImage === 0) {
						this.correctBackgroundImage = 1;
						this.timessiqsakd = setInterval(() => {
							this.oeilsukqwtim = 0;
							this.onDemandDisplay = this.onDemandDisplay += 1;
						}, 500);
						// this.container=["","","","","",""]
					}
					// // 根须需求，对错之间要不要清空拖放元素框
					// $(".beijiaogualeft").html("");
					// $(".zhujiaogajright").html("");
					// $(".cbkboxcl").html("");
					// $(".CLFboxcl").html("");
					// $(".RLGboxcl").html("");
				} else if (this.phoneNumber != "88886666" &&
					this.container[0] == "back_ring" &&
					this.container[1] == "IAM" &&
					this.container[2] == "ACM" &&
					this.container[3] == "ANC" &&
					this.container[4] == "ring" &&
					this.container[5] == "pick_answer") {
					this.ulShow = true;
					// 默认显示的背景图
					this.wnassowisw = 1;
					// 切换成错的背景图
					this.wnasswrowisw = 1;
					// 消息对话框
					if (this.wrongMessagePrompt === 0) {
						this.wrongMessagePrompt = 1;
						this.timessiqsdial = setInterval(() => {
							this.timessTimeiqsdial = 0;
							this.dialthewrongnumbe = this.dialthewrongnumbe += 1;
						}, 500);
						// this.container=["","","","","",""]
					}
					// 根须需求，对错之间要不要清空拖放元素框
					// $(".beijiaogualeft").html("");
					// $(".zhujiaogajright").html("");
					// $(".cbkboxcl").html("");
					// $(".CLFboxcl").html("");
					// $(".RLGboxcl").html("");
				}else{
					this.wnassowisw = 0;
					this.wnasswrowisw = 0;
					this.ulShow = false; //点击通话下内容隐藏
				}
			},
			correctmessageprompt() {
				this.oeilsukqwtim = 1;
				clearInterval(this.timessiqsakd);
				this.onDemandDisplay = 0;
				this.correctBackgroundImage = 0;

			},
			closeerroPopup() {
				this.timessTimeiqsdial = 1;
				clearInterval(this.timessiqsdial);
				this.dialthewrongnumbe = 0;
				this.wrongMessagePrompt = 0;
			},
			tabletbsordertrue() {
				this.tabletbsorder = !this.tabletbsorder;
			},
			bwtwqn() {
				this.bohaoishow = !this.bohaoishow;
			},
			wi_fiacitve(i) {
				this.wi_activ = i;
			},
			// wifi拖拽
			routerIntroductionBtn() {
				this.routerEchoVariable = 1;
				this.routerIntroductionBtnsync = true;
				setTimeout(() => {
					this.initiateGame();
				}, 300);
			},
			wifidialog() {
				this.routerIntroductionBtnsync = false;
			},
			// 电视拖拽
			tVDragBtn() {
				this.television_dialog = true;
				this.woieuifrucdlsd = 1;
				setTimeout(() => {
					this.initiateGame();
				}, 300);
			},
			televisiondialog() {
				this.television_dialog = false;
			},
			// 手机拖拽
			mobileDragBtn() {
				this.mobileechoVariable = 1;
				this.tuozhuaibufen = !this.tuozhuaibufen;
				setTimeout(() => {
					this.initiateGame();
					
				}, 300);
			},
			mobilePhonePaddialog() {
				this.tuozhuaibufen = false;
			},
			// 电话拖拽
			LandlinetelephoneBtn() {
				this.echoVariableOfSweepingRobot = 1;
				this.isTelephoneS = true;
				//  this.businessListAry = this.businessListAry.sort(() => 0.5 - Math.random())
				// this.bussLias = this.bussLias.sort(() => 0.5 - Math.random())
				setTimeout(() => {
					this.initiateGame();
				}, 300);
			},
			TelephoneDialog() {
				this.isTelephoneS = false;
			},
			initiateGame() {
				this.draggableElements = document.querySelectorAll(".draggable");
				this.droppableElements = document.querySelectorAll(".droppableEle");
				this.draggableElements.forEach((elem) => {
					elem.addEventListener("dragstart", this.dragStart);
				});
				this.droppableElements.forEach((elem) => {
					elem.addEventListener("dragenter", this.dragEnter);
					elem.addEventListener("dragover", this.dragOver);
					elem.addEventListener("dragleave", this.dragLeave);
					elem.addEventListener("drop", this.drop);
				});
			},
			dragStart(event) {
				// 拖网源

				// event.dataTransfer.setData("text", event.target.id);
				// this.targetID = event.srcElement.__vnode.props.id.id
				this.targetID = event.target.id;
			},
			dragEnter(event) {
				if (
					event.target.classList &&
					event.target.classList.contains("droppableEle") &&
					!event.target.classList.contains("dropped")
				) {
					event.target.classList.add("droppable-hover");
				}
			},
			dragOver(event) {
				if (
					event.target.classList &&
					event.target.classList.contains("droppableEle") &&
					!event.target.classList.contains("dropped")
				) {
					event.preventDefault();
				}
			},
			dragLeave(event) {
				if (
					event.target.classList &&
					event.target.classList.contains("droppableEle") &&
					!event.target.classList.contains("dropped")
				) {
					event.target.classList.remove("droppable-hover");
				}
			},
			// 拖拽结束
			drop(event) {
				// this.evoverallsituation =event
				this.wertdh(event);
				this.Signaling_process(event);
				this.mobilePhone(event);
				this.WiFiDrag(event);
				this.televisionapi(event);
				event.preventDefault();
				event.stopPropagation(); //阻止冒泡
				var draggableElement = document.getElementById(this.targetID);
				if (!event.target.classList.contains("dropped")) {
					const imgUrl = require("@/assets/img/imgHomePage/" +
						this.targetID +
						".jpg");

					event.target.innerHTML =
						'<span class="delectPic" ></span><img draggable="false" id="recovery' +
						this.recoveryIndex +
						'"src="' +
						imgUrl +
						'" alt="' +
						this.targetID +
						'">';
					this.recoveryIndex++;

					let that = this;
					// let iscdjo = this.targetID;
					that.qwoidmo3idoy(that);

					if (this.targetID == 'back_ring') {
						this.container[0] = 'back_ring'
					}
					if (this.targetID == 'IAM') {
						this.container[1] = 'IAM'
					}
					if (this.targetID == 'ACM') {
						this.container[2] = 'ACM'
					}
					if (this.targetID == 'ANC') {
						this.container[3] = 'ANC'
					}
					if (this.targetID == 'ring') {
						this.container[4] = 'ring'
					}
					if (this.targetID == 'pick_answer') {
						this.container[5] = 'pick_answer'
					}
				}
			},
			del(){
				// if (this.phoneNumberCopy !== this.phoneNumber) {
				// 	this.phoneNumberCopy = this.phoneNumber;
				// 	document.querySelectorAll(".dragList .droppableEle").forEach((elem) => {
				// 		elem.innerHTML = "";
				// 	});
				// this.container = [];
				this.wnassowisw = 0;
				this.wnasswrowisw = 0;
				this.ulShow = false; //点击通话下内容隐藏
				// this.echoList.wire_telephone.signaling_procedure.base_param.ACM = "";
				// this.echoList.wire_telephone.signaling_procedure.base_param.ANC = "";
				// this.echoList.wire_telephone.signaling_procedure.base_param.CBK = "";
				// this.echoList.wire_telephone.signaling_procedure.base_param.CLF = "";
				// this.echoList.wire_telephone.signaling_procedure.base_param.IAM = "";
				// this.echoList.wire_telephone.signaling_procedure.base_param.back_ring = "";
				// this.echoList.wire_telephone.signaling_procedure.base_param.called_hang_up = "";
				// this.echoList.wire_telephone.signaling_procedure.base_param.calling_hang_up = "";
				// this.echoList.wire_telephone.signaling_procedure.base_param.pick_answer = "";
				// this.echoList.wire_telephone.signaling_procedure.base_param.reminder_hang = "";
				// this.echoList.wire_telephone.signaling_procedure.base_param.ring = "";
				// //提交内容
				// th.obj.wire_telephone.signaling_procedure.base_param.back_ring = "";
				// th.obj.wire_telephone.signaling_procedure.base_param.called_hang_up = "";
				// th.obj.wire_telephone.signaling_procedure.base_param.calling_hang_up = "";
				// th.obj.wire_telephone.signaling_procedure.base_param.pick_answer = "";
				// th.obj.wire_telephone.signaling_procedure.base_param.reminder_hang = "";
				// th.obj.wire_telephone.signaling_procedure.base_param.ring = "";
				document.querySelector(".beijiaogualeft").innerHTML = "";
				document.querySelector(".zhujiaogajright").innerHTML = "";
				document.querySelector(".cbkboxcl").innerHTML = "";
				document.querySelector(".CLFboxcl").innerHTML = "";
				document.querySelector(".RLGboxcl").innerHTML = "";
			},
			//删除信令逻辑
			qwoidmo3idoy(that) {
				$(".delectPic").click(function() {
					let clawom = $(this).parent(".droppableEle").attr("data-index");
					// 电话网络配置
					if (clawom == 10) {
						that.del();
						that.obj.wire_telephone.network_config.base_param.DC1_switched_network =
							"";
					}
					if (clawom == 11) {
						that.del();
						that.obj.wire_telephone.network_config.base_param.DC2_switched_network =
							"";
					}
					if (clawom == 12) {
						that.del();
						that.obj.wire_telephone.network_config.base_param.operations_command_end_office =
							"";
					}
					if (clawom == 13) {
						that.del();
						that.obj.wire_telephone.network_config.base_param.rt_service_hall_end_office =
							"";
					}

					if (clawom == 14) {
						that.del();
						that.obj.wire_telephone.signaling_procedure.base_param.IAM = "";
						if (that.container[1]) {
							that.container[1] = ""
						}
					}
					if (clawom == 15) {
						that.del();
						that.obj.wire_telephone.signaling_procedure.base_param.ring = "";
						if (that.container[4]) {
							that.container[4] = ""
						}
					}
					if (clawom == 16) {
						that.del();
						that.obj.wire_telephone.signaling_procedure.base_param.ACM = "";
						if (that.container[2]) {
							that.container[2] = ""
						}
					}
					if (clawom == 18) {
						that.del();
						that.obj.wire_telephone.signaling_procedure.base_param.ANC = "";
						if (that.container[3]) {
							that.container[3] = ""
						}
					}
					if (clawom == 17) {
						that.del();
						that.obj.wire_telephone.signaling_procedure.base_param.back_ring = "";
						if (that.container[0]) {
							that.container[0] = ""
						}
					}
					if (clawom == 19) {
						that.del();
						that.obj.wire_telephone.signaling_procedure.base_param.pick_answer = "";
						if (that.container[5]) {
							that.container[5] = ""
						}
					}
					if (that.wnasswrowisw == 2) {
						if (clawom == 20) {
							that.obj.wire_telephone.signaling_procedure.base_param.calling_hang_up =
								"";
						}
						if (clawom == 21) {
							that.obj.wire_telephone.signaling_procedure.base_param.reminder_hang =
								"";
						}
						if (clawom == 22) {
							that.obj.wire_telephone.signaling_procedure.base_param.CLF = "";
						}
						if (clawom == 23) {
							that.obj.wire_telephone.signaling_procedure.base_param.RLG = "";
						}
					} else if (that.wnasswrowisw == 1) {
						if (clawom == 20) {
							that.obj.wire_telephone.signaling_procedure.base_param.reminder_hang =
								"";
						}
						if (clawom == 21) {
							that.obj.wire_telephone.signaling_procedure.base_param.called_hang_up = 
								"";
						}

						if (clawom == 22) {
							that.obj.wire_telephone.signaling_procedure.base_param.CBK = "";
						}
						if (clawom == 23) {
							that.obj.wire_telephone.signaling_procedure.base_param.CLF = "";
						}
						if (clawom == 24) {
							that.obj.wire_telephone.signaling_procedure.base_param.RLG = "";
						}
					}
					// // 手机
					if (clawom == 51) {
						that.obj.mobile_communication.network_design.base_param.outer_net =
							"";
					}
					if (clawom == 52) {
						that.obj.mobile_communication.network_design.base_param.gateway = "";
					}
					if (clawom == 53) {
						that.obj.mobile_communication.network_design.base_param.core_network =
							"";
					}
					if (clawom == 54) {
						that.obj.mobile_communication.network_design.base_param.base_station =
							"";
					}
					if (clawom == 55) {
						that.obj.mobile_communication.network_design.base_param.NMS = "";
					}

					// 电视
					if (clawom == 0) {
						that.obj.television.network_config.base_param.broadcast_satellite =
							"";
					}
					if (clawom == 1) {
						that.obj.television.network_config.base_param.up_link_station = "";
					}
					if (clawom == 2) {
						that.obj.television.network_config.base_param.satellite_television_accept_station =
							"";
					}
					if (clawom == 4) {
						that.obj.television.network_config.base_param.country_SDH = "";
					}
					if (clawom == 5) {
						that.obj.television.network_config.base_param.province_SDH = "";
					}
					if (clawom == 6) {
						that.obj.television.network_config.base_param.own_program = "";
					}
					if (clawom == 7) {
						that.obj.television.network_config.base_param.amplifier = "";
					}
					if (clawom == 8) {
						that.obj.television.network_config.base_param.splitter = "";
					}
					if (clawom == 3) {
						that.obj.television.network_config.base_param.commingle = "";
					}

					// wifi
					if (clawom == 60) {
						that.obj.wireless_fidelity.network_config.base_param.router = "";
					}

					if (clawom == 61) {
						that.obj.wireless_fidelity.network_config.base_param.firewall = "";
					}
					if (clawom == 62) {
						that.obj.wireless_fidelity.network_config.base_param.central_exchange =
							"";
					}
					if (clawom == 63) {
						that.obj.wireless_fidelity.network_config.base_param.server = "";
					}
					if (clawom == 64) {
						that.obj.wireless_fidelity.network_config.base_param.network_management_controller =
							"";
					}
					if (clawom == 65) {
						that.obj.wireless_fidelity.network_config.base_param.POE_exchange =
							"";
					}

					$(this).parent(".droppableEle").html("");
				});
			},
			// 信令流程电话号保存判断逻辑 - 保存电话
			dianhuah() {
				// this.container = [];
				// this.wnassowisw = 0;
				// this.wnasswrowisw = 0;
				// this.ulShow = false; //点击通话下内容隐藏
				if (this.phoneNumber == "88886666") {
					this.ssiqswewfeakd = "010-8888 6666";
				} else {
					this.ssiqswewfeakd = this.ssiqsakd + '-' + this.phoneNumber;
				}

				if (this.phoneNumberCopy !== this.phoneNumber) {
					this.phoneNumberCopy = this.phoneNumber;
					this.wnassowisw = 0;
					this.wnasswrowisw = 0;
					this.ulShow = false;
					// document.querySelectorAll(".dragList .droppableEle").forEach((elem) => {
					// 	elem.innerHTML = "";
					// });
					// this.echoList.wire_telephone.signaling_procedure.base_param.ACM = "";  上面
					// this.echoList.wire_telephone.signaling_procedure.base_param.ANC = "";  上面
					// this.echoList.wire_telephone.signaling_procedure.base_param.CBK = "";
					// this.echoList.wire_telephone.signaling_procedure.base_param.CLF = "";
					// this.echoList.wire_telephone.signaling_procedure.base_param.IAM = "";  上面
					// this.echoList.wire_telephone.signaling_procedure.base_param.back_ring = "";
					// this.echoList.wire_telephone.signaling_procedure.base_param.called_hang_up = "";
					// this.echoList.wire_telephone.signaling_procedure.base_param.calling_hang_up = "";
					// this.echoList.wire_telephone.signaling_procedure.base_param.pick_answer = "";
					// this.echoList.wire_telephone.signaling_procedure.base_param.reminder_hang = "";
					// this.echoList.wire_telephone.signaling_procedure.base_param.ring = "";
					document.querySelector(".beijiaogualeft").innerHTML = "";
					document.querySelector(".zhujiaogajright").innerHTML = "";
					document.querySelector(".cbkboxcl").innerHTML = "";
					document.querySelector(".CLFboxcl").innerHTML = "";
					document.querySelector(".RLGboxcl").innerHTML = "";
				}else{
					// this.wnassowisw = 1;
					// this.wnasswrowisw = 2;
					// this.ulShow = true;
					
				}
				this.obj.wire_telephone.signaling_procedure.base_param.phone_num =
					this.ssiqswewfeakd;
				// if (this.ssiqsakd !== "010-8888 6666" || this.ssiqsakd.length > 13 || this.ssiqsakd.length < 13) {
				//   this.$message.error("错了哦，请注意电话号格式");
				// } else {
				//   this.bohaoishow = false;
				// }

				if (this.phoneNumber.length < 8 && this.phoneNumber != "") {
					this.$message.error("错了哦，电话号不能小于8位数");
				} else if (this.phoneNumber == '') {
					this.$message.warning("请输入电话号码！");
				} else {
					this.bohaoishow = false;
				}
			},
			//电话接口提交
			wertdh(event) {
				if (event.target.classList[1] == "dC1draganddropname") {
					this.obj.wire_telephone.network_config.base_param.DC1_switched_network =
						this.targetID;
				}
				if (event.target.classList[1] == "dCerdraganddropname") {
					this.obj.wire_telephone.network_config.base_param.DC2_switched_network =
						this.targetID;
				}
				if (event.target.classList[1] == "Endoffice") {
					this.obj.wire_telephone.network_config.base_param.operations_command_end_office =
						this.targetID;
				}
				if (event.target.classList[1] == "Endoffename") {
					this.obj.wire_telephone.network_config.base_param.rt_service_hall_end_office =
						this.targetID;
				}
			},
			Signaling_process(event) {
				if (event.target.classList[1] == "iamboxs") {
					this.obj.wire_telephone.signaling_procedure.base_param.IAM =
						this.targetID;
				}
				if (event.target.classList[1] == "zhenlingbox") {
					this.obj.wire_telephone.signaling_procedure.base_param.ring =
						this.targetID;
				}
				if (event.target.classList[1] == "acmbox") {
					this.obj.wire_telephone.signaling_procedure.base_param.ACM =
						this.targetID;
				}
				if (event.target.classList[1] == "huiyinlbox") {
					this.obj.wire_telephone.signaling_procedure.base_param.back_ring =
						this.targetID;
				}
				if (event.target.classList[1] == "zhaojijs") {
					this.obj.wire_telephone.signaling_procedure.base_param.pick_answer =
						this.targetID;
				}
				if (event.target.classList[1] == "ANCbox") {
					this.obj.wire_telephone.signaling_procedure.base_param.ANC =
						this.targetID;
				}

				if (this.wnasswrowisw == 2) {
					if (event.target.classList[1] == "beijiaogualeft") {
						this.obj.wire_telephone.signaling_procedure.base_param.calling_hang_up =
							this.targetID;
					}
					if (event.target.classList[1] == "zhujiaogajright") {
						this.obj.wire_telephone.signaling_procedure.base_param.reminder_hang =
							this.targetID;
					}
					if (event.target.classList[1] == "cbkboxcl") {
						this.obj.wire_telephone.signaling_procedure.base_param.CLF =
							this.targetID;
					}

					if (event.target.classList[1] == "CLFboxcl") {
						this.obj.wire_telephone.signaling_procedure.base_param.RLG =
							this.targetID;
					}
					if (event.target.classList[1] == "RLGboxcl") {
						this.obj.wire_telephone.signaling_procedure.base_param.CBK =
							this.targetID;
					}
				} else if (this.wnasswrowisw == 1) {
					if (event.target.classList[1] == "beijiaogualeft") {
            this.obj.wire_telephone.signaling_procedure.base_param.reminder_hang =
            this.targetID;
					}
					if (event.target.classList[1] == "zhujiaogajright") {
            this.obj.wire_telephone.signaling_procedure.base_param.called_hang_up =
							this.targetID;
					}
					if (event.target.classList[1] == "cbkboxcl") {
						this.obj.wire_telephone.signaling_procedure.base_param.CBK =
							this.targetID;
					}
					if (event.target.classList[1] == "CLFboxcl") {
						this.obj.wire_telephone.signaling_procedure.base_param.CLF =
							this.targetID;
					}
					if (event.target.classList[1] == "RLGboxcl") {
						this.obj.wire_telephone.signaling_procedure.base_param.RLG =
							this.targetID;
					}
				}

				// if (this.wnasswrowisw == 1) {
				//   if (event.target.classList[1] == "beijiaogualeft") {
				//     this.obj.wire_telephone.signaling_procedure.base_param.reminder_hang =
				//       this.targetID;
				//   }
				// }
				// if (this.wnasswrowisw == 2) {
				//   if (event.target.classList[1] == " zhujiaogajright") {
				//     this.obj.wire_telephone.signaling_procedure.base_param.reminder_hang =
				//       this.targetID;
				//   }
				// }
			},
			// 手机
			mobilePhone(event) {
				if (event.target.classList[1] == "droppablekey") {
					this.obj.mobile_communication.network_design.base_param.outer_net =
						this.targetID;
				}
				if (event.target.classList[1] == "droppable16") {
					this.obj.mobile_communication.network_design.base_param.gateway =
						this.targetID;
				}
				if (event.target.classList[1] == "droppable17") {
					this.obj.mobile_communication.network_design.base_param.core_network =
						this.targetID;
				}
				if (event.target.classList[1] == "droppable18") {
					this.obj.mobile_communication.network_design.base_param.base_station =
						this.targetID;
				}
				if (event.target.classList[1] == "droppable19") {
					this.obj.mobile_communication.network_design.base_param.NMS =
						this.targetID;
				}
			},
			// 电视
			televisionapi(event) {
				let clawom = $(this).parent(".droppableEle").attr("data-index");
				if (event.target.classList[1] == "guangbwxbox") {
					this.obj.television.network_config.base_param.broadcast_satellite =
						this.targetID;
				}
				if (event.target.classList[1] == "shfskzzbox") {
					this.obj.television.network_config.base_param.up_link_station =
						this.targetID;
				}
				if (event.target.classList[1] == "wxdsjszBox") {
					this.obj.television.network_config.base_param.satellite_television_accept_station =
						this.targetID;
				}
				if (event.target.classList[1] == "guSDHBox") {
					this.obj.television.network_config.base_param.country_SDH =
						this.targetID;
				}
				if (event.target.classList[1] == "shenggSDHBox") {
					this.obj.television.network_config.base_param.province_SDH =
						this.targetID;
				}
				if (event.target.classList[1] == "zibianjmSDHBox") {
					this.obj.television.network_config.base_param.own_program =
						this.targetID;
				}
				if (event.target.classList[1] == "fangdajinmSDHBox") {
					this.obj.television.network_config.base_param.amplifier = this.targetID;
				}
				if (event.target.classList[1] == "fenzhiqSDHBox") {
					this.obj.television.network_config.base_param.splitter = this.targetID;
				}

				if (event.target.classList[1] == "commingleClass") {
					this.obj.television.network_config.base_param.commingle = this.targetID;
				}
			},
			// wifi
			WiFiDrag(e) {
				if (e.target.classList[1] == "luyouxicas") {
					this.obj.wireless_fidelity.network_config.base_param.router =
						this.targetID;
				}
				if (e.target.classList[1] == "luyouxier") {
					this.obj.wireless_fidelity.network_config.base_param.firewall =
						this.targetID;
				}
				if (e.target.classList[1] == "luyouxisan") {
					this.obj.wireless_fidelity.network_config.base_param.central_exchange =
						this.targetID;
				}

				if (e.target.classList[1] == "luyouxisileft") {
					this.obj.wireless_fidelity.network_config.base_param.server =
						this.targetID;
				}
				if (e.target.classList[1] == "luyouxiwuright") {
					this.obj.wireless_fidelity.network_config.base_param.network_management_controller =
						this.targetID;
				}
				if (e.target.classList[1] == "luyouxiliubottom") {
					this.obj.wireless_fidelity.network_config.base_param.POE_exchange =
						this.targetID;
				}
			},

			crnmedua() {
				this.widuis = !this.widuis;
			},
			// 手机 动画
			powerOnBtn() {
				this.swna = 1;
				this.listyi = this.listyi.reverse();
				this.listTwo = this.listTwo.reverse();
				//  this.jqswjdp();    // 系统消息
				//  this.jqswausajdp();// 随机接入
				//  this.gezish();     // PDU会话建立
				//  this.giankezish(); // RRC建立过程
				//  this.jesjdp();     // 安全模式建立
				//  this.fensagam();   // 初始UE消息
				//  this.fensam();     // 鉴权和加密
				if (!this.listgezi.includes("系统消息")) {
					this.jqswjdp(); //系统消息
				}
				if (!this.listgezi.includes("随机接入")) {
					this.jqswausajdp(); // 随机接入
				}
				if (!this.listgezi.includes("RRC建立过程")) {
					this.giankezish(); // RRC建立过程
				}
				if (!this.listgezi.includes("初始UE消息")) {
					this.fensagam(); // 初始UE消息
				}
				if (!this.listgezi.includes("安全模式建立")) {
					this.jesjdp(); // 安全模式建立
				}
				if (!this.listgezi.includes("PDU会话建立")) {
					this.gezish(); // PDU会话建立
				}
				if (!this.listgezi.includes("鉴权和加密")) {
					this.fensam(); // 鉴权和加密
				}
				if (!this.listgezi.includes("执行扫地任务")) {
					this.performSweepingTaskFun(); // 鉴权和加密
				}
			},
			// 鸽子
			gezish() {
				this.canksk = true;
				this.canClick = true;
				var that = this;
				if (that.canClick) {
					$(".diyigegsssdezipo").animate({
						left: "43%"
					}, {
						duration: 5000,
						queue: false,
						complete: function() {
							// that.canksk = false;
							// setInterval(function () {
							$(".diyigegsssdezipo").css({
								left: "0%"
							});
							// }, 1000);

							if (that.canClick) {
								that.gezish();
							}
						},
					});
				}
			},
			owmsncan() {
				this.listgezi.push(this.$refs.tre.innerText);
				this.canksk = false;
				this.canClick = false;
			},

			giankezish() {
				this.cankesk = true;
				this.canCslick = true;
				var that = this;
				if (that.canCslick) {
					$(".diyiipo").animate({
						left: "43%"
					}, {
						duration: 4300,
						queue: false,
						complete: function() {
							$(".diyiipo").css({
								left: "0%"
							});
							if (that.canCslick) {
								that.giankezish();
							}
						},
					});
				}
			},
			rrcjianks() {
				this.listgezi.push(this.$refs.trwe.innerText);
				this.cankesk = false;
				this.canCslick = false;
			},

			jesjdp() {
				this.canxiw = true;
				this.canxiwClick = true;
				var that = this;
				if (that.canxiwClick) {
					$(".sdezeipo").animate({
						left: "43%"
					}, {
						duration: 4500,
						queue: false,
						complete: function() {
							$(".sdezeipo").css({
								left: "0%"
							});
							if (that.canxiwClick) {
								that.jesjdp();
							}
						},
					});
				}
			},
			safeMode_btn() {
				this.canxiw = false;
				this.canxiwClick = false;
				this.listgezi.push(this.$refs.pol.innerText);
			},
			jqswjdp() {
				this.canxiaow = true;
				this.canxiaowClick = true;
				var that = this;
				if (that.canxiaowClick) {
					$(".deziqpo").animate({
						left: "43%"
					}, {
						duration: 4700,
						queue: false,
						complete: function() {
							$(".deziqpo").css({
								left: "0%"
							});
							if (that.canxiaowClick) {
								that.jqswjdp();
							}
						},
					});
				}
			},
			csjlsxwbtn() {
				this.canxiaow = false;
				this.canxiaowClick = false;
				this.listgezi.push(this.$refs.poqwstl.innerText);
			},
			jqswausajdp() {
				this.xiaHks = true;
				this.xiaHksClick = true;
				var that = this;
				if (that.xiaHksClick) {
					$(".diyes").animate({
						left: "43%"
					}, {
						duration: 5000,
						queue: false,
						complete: function() {
							$(".diyes").css({
								left: "0%"
							});
							if (that.xiaHksClick) {
								that.jqswausajdp();
							}
						},
					});
				}
			},
			suijisjianks() {
				this.xiaHks = false;
				this.xiaHksClick = false;
				this.listgezi.push(this.$refs.suiji.innerText);
			},
			fensagam() {
				this.xiafenHks = true;
				this.xiafenHksClick = true;
				var that = this;
				if (that.xiafenHksClick) {
					$(".diyigedezipo").animate({
						left: "43%"
					}, {
						duration: 4900,
						queue: false,
						complete: function() {
							$(".diyigedezipo").css({
								left: "0%"
							});
							if (that.xiafenHksClick) {
								that.fensagam();
							}
						},
					});
				}
			},
			xitcjianks() {
				this.listgezi.push(this.$refs.initialtext.innerText);
				this.xiafenHks = false;
				this.xiafenHksClick = false;
			},
			fensam() {
				this.xiafns = true;
				this.xiafnsClick = true;
				var that = this;
				if (that.xiafnsClick) {
					$(".diyigezipo").animate({
						left: "43%"
					}, {
						duration: 3900,
						queue: false,
						complete: function() {
							$(".diyigezipo").css({
								left: "0%"
							});
							if (that.xiafnsClick) {
								that.fensam();
							}
						},
					});
				}
			},
			performSweepingTaskFun() {
				this.performSweepingTaskisshow = true;
				this.performSweepingTaskisshowClick = true;
				var that = this;
				if (that.performSweepingTaskisshowClick) {
					$(".performSweepingTaskClassName").animate({
						left: "43%"
					}, {
						duration: 4900,
						queue: false,
						complete: function() {
							$(".performSweepingTaskClassName").css({
								left: "0%"
							});
							if (that.performSweepingTaskisshowClick) {
								that.performSweepingTaskFun();
							}
						},
					});
				}
			},
			performSweepingTaskClassNameBtn() {

				this.listgezi.push(this.$refs.performSweepingTaskacves.innerText)
				this.performSweepingTaskisshow = false;
				this.performSweepingTaskisshowClick = false;
				//  this.listgezi = ['RRC建立过程','系统消息','随机接入','RRC建立过程','初始UE消息','安全模式建立','PDU会话建立','鉴权和加密']
			},
			SigningRight_btn() {
				this.listgezi.push(this.$refs.acves.innerText);
				this.xiafns = false;
				this.xiafnsClick = false;
			},
			// 删除鸽子信息
			deleteBtn(i, index) {
				// 删除 listgezi
				this.listgezi.splice(index, 1);
				// push listyi
				//  this.listyi.push(i)
				if (i == "系统消息") {
					this.jqswjdp();
				}
				if (i == "PDU会话建立") {
					this.gezish();
				}
				if (i == "RRC建立过程") {
					this.giankezish();
				}
				if (i == "安全模式建立") {
					this.jesjdp();
				}

				if (i == "随机接入") {
					this.jqswausajdp();
				}
				if (i == "初始UE消息") {
					this.fensagam();
				}
				if (i == "鉴权和加密") {
					this.fensam();
				}
				if (i == "执行扫地任务") {

					this.performSweepingTaskFun();
				}
			},
			// 校验
			check() {
				let skorage = localStorage.getItem("calback")
				if (skorage != null) {
					this.container = JSON.parse(skorage)
				}

				// this.obj.floor_mopping_robot.base_param.blue_tooth = this.robotBluetoothSwitch;

				var date = new Date();
				this.year = date.getFullYear();
				this.month = date.getMonth() + 1;
				this.date = date.getDate();
				this.day = new Array(
					"星期日",
					"星期一",
					"星期二",
					"星期三",
					"星期四",
					"星期五",
					"星期六"
				)[date.getDay()];
				this.hour =
					date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
				this.minute =
					date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
				this.second =
					date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
				var currentTime =
					"现在是:" +
					this.year +
					"年" +
					this.month +
					"月" +
					this.date +
					"日 " +
					this.hour +
					":" +
					this.minute +
					":" +
					this.second +
					" " +
					this.day;
				this.mydatetime =
					this.year +
					"-" +
					this.month +
					"-" +
					this.date +
					" " +
					this.hour +
					":" +
					this.minute +
					":" +
					this.second;

				let dataobj = new FormData();
				dataobj.append("data", JSON.stringify(this.obj));
				getTopics(dataobj).then((res) => {
					let datamodel = JSON.parse(res.data.data);


					this.state = datamodel.msg;
					this.stateTips = datamodel.sign;
					this.wisdmsow = 1;
					this.duijiangname = 1;
					this.dianshiishs = 1;
					this.fissienws = 1;
					this.isPadnum = 1;
					this.editCoNanber = 1;
					this.guanjisis = 1;
					this.scroqllbar = true;
					//  this.stateTips[0] = true
					// this.stateTips[1]  = true
					// this.stateTips[2]  = true
					// this.stateTips[3] = true
					// this.stateTips[4]  = true
					// this.stateTips[5]  = true
					// this.stateTips[6] = true

					if (this.stateTips[5]) {
						this.machinevariable = 1;
					}
					if (
						this.stateTips[0] &&
						this.stateTips[1] &&
						this.stateTips[2] &&
						this.stateTips[3] &&
						this.stateTips[4] &&
						this.stateTips[5] &&
						this.stateTips[6]
					) {
						this.machinevariable = 0;
					}
				});
				var that = this;
				// for (let i = 0; i < that.listgezi.length; i++) {
				//   if (that.listgezi[i].indexOf("执行扫地任务") !== -1) {
				//     that.executeSweepingTaskCommand = 1;
				//   } else {
				//     that.executeSweepingTaskCommand = 0;
				//   }
				// }

				setTimeout(() => {
					if (
						this.stateTips[0] &&
						this.stateTips[1] &&
						this.stateTips[2] &&
						this.stateTips[3] &&
						this.stateTips[4] &&
						this.stateTips[5] &&
						this.stateTips[6]

					) {
						setTimeout(function() {
							$(".sapdij").animate({
								top: "10%"
							}, 100, "linear", function() {
								//  $(".sapdij").animate( {left: "40%"},{duration: 500,queue: false,});
								$(".sapdij").animate({
										left: "80%"
									},
									2000,
									"linear",
									function() {
										$(".sapdij").animate({
												top: "-20%"
											},
											500,
											"linear",
											function() {
												$(".sapdij").animate({
														left: "7%"
													},
													2000,
													"linear",
													function() {}
												);
												$(".sapdij").animate({
														top: "10px"
													},
													1000,
													"linear",
													function() {}
												);
											}
										);
									}
								);
							});
						}, 100);
						setTimeout(function() {
							$(".sweepingRobotSignalWave").animate({
									top: "-13%"
								},
								100,
								"linear",
								function() {
									//  $(".sapdij").animate( {left: "40%"},{duration: 500,queue: false,});
									$(".sweepingRobotSignalWave").animate({
											left: "82%"
										},
										2000,
										"linear",
										function() {
											$(".sweepingRobotSignalWave").animate({
													top: "-40%"
												},
												500,
												"linear",
												function() {
													$(".sweepingRobotSignalWave").animate({
															left: "9%"
														},
														2000,
														"linear",
														function() {}
													);
													$(".sweepingRobotSignalWave").animate({
															top: "-15px"
														},
														1000,
														"linear",
														function() {}
													);
												}
											);
										}
									);
								}
							);
						}, 100);
					}
				}, 500);
			},
			// 手机信令流程保存
			PhoneSave() {
				// this.tuozhuaibufen = false;
				this.obj.mobile_communication.boot_process.base_param.sys_msg =
					this.listgezi[0];
				this.obj.mobile_communication.boot_process.base_param.random_join =
					this.listgezi[1];
				this.obj.mobile_communication.boot_process.base_param.RRC_setup =
					this.listgezi[2];
				this.obj.mobile_communication.boot_process.base_param.initial_context =
					this.listgezi[3];

				this.obj.mobile_communication.boot_process.base_param.authentication_encryption =
					this.listgezi[4];
				this.obj.mobile_communication.boot_process.base_param.safe_mode_setup =
					this.listgezi[5];
				this.obj.mobile_communication.boot_process.base_param.PDU_session_setup =
					this.listgezi[6];
				this.obj.mobile_communication.boot_process.base_param.sweep_floor_task =
					this.listgezi[7];
				if (
					this.mobileProgressBarName === 0 &&
					this.mobileProgressBarName <= 110
				) {
					this.mobileTimer = setInterval(() => {
						this.mobileProgressBarName = this.mobileProgressBarName += 5;
					}, 100);
				}

				setInterval(() => {
					if (this.mobileProgressBarName >= 105) {
						this.mobileProgressBarName = 0;
						clearInterval(this.mobileTimer);
					}
				}, 100);
				this.preservationBtnName();
			},
			// 电话保存
			closeTheInterphonePopUpWindow() {
				localStorage.setItem("calback", JSON.stringify(this.container))
				// this.isTelephoneS = false;
				// this.duijiangname = 1;
				if (this.phoneProgressBarName === 0 && this.phoneProgressBarName <= 110) {
					this.telephoneTimer = setInterval(() => {
						this.phoneProgressBarName = this.phoneProgressBarName += 5;
					}, 100);
				}
				setInterval(() => {
					if (this.phoneProgressBarName >= 105) {
						this.phoneProgressBarName = 0;
						clearInterval(this.telephoneTimer);
					}
				}, 100);
				this.preservationBtnName();
			},
			rightBtnItem() {
				this.editContentbox = true;
			},
			handleCldose_two() {
				this.editContentbox = false;
			},
			wangluopwBtn(i) {
				this.isActivehande = i;
			},
			// 扫地机器人保存信息
			sweepingRobotSaveBtn() {

				// this.obj.floor_mopping_robot.base_param.blue_tooth_name = this.floorRobot
				// this.$emit('change', this.sweepingRobotProgressBarName)
				// if (this.bluetoothSwitchOfSweepingRobot) {
				//   this.robotBluetoothSwitch = "打开";
				// } else {
				//   this.robotBluetoothSwitch = "关闭";
				// }


				if (this.bluetoothSwitchOfSweepingRobot == true) {
					this.obj.tablet_computer.base_param.blue_tooth = "打开";
					this.robotBluetoothSwitch = "打开";
				} else if (this.bluetoothSwitchOfSweepingRobot == false) {
					this.obj.tablet_computer.base_param.blue_tooth = "关闭";
					this.robotBluetoothSwitch = "关闭";
				}



				// if ( this.padDeviceDisplay == this.bluetoothDisplayDeviceVariables) {
				//     this.robotBluetoothSwitch = "打开";
				// }else{
				//    this.robotBluetoothSwitch = "关闭";
				// }

				//  // this.obj.floor_mopping_robot.base_param.blue_tooth = this.robotBluetoothSwitch;

				if (this.sweepingRobotProgressBarName == 0 && this.sweepingRobotProgressBarName <= 110) {
					this.sweepingrobotTimer = setInterval(() => {
						this.sweepingRobotProgressBarName += 5;
					}, 100);
				}
				setInterval(() => {
					if (this.sweepingRobotProgressBarName >= 105) {
						clearInterval(this.sweepingrobotTimer);
						this.sweepingRobotProgressBarName = 0;
					}
				}, 100);

				//    this.tabletConnection();
				this.preservationBtnName();
			},

			tabletBtn() {

				sessionStorage.setItem("selector", this.echoList.tablet_computer.base_param.SSID);
				this.tabletborder = true;
				if (sessionStorage.getItem("selector") == null) {
					this.ClickToDisplay = false;
				} else {
					this.singleChoice = sessionStorage.getItem("selector")
					this.equipmentMod = sessionStorage.getItem("key_select");
					this.bluelanyatooth = this.echoList.tablet_computer.base_param.blue_tooth
					this.automwatic(sessionStorage.getItem("selector"))
				}

				if (this.singleChoice != "oppo reno5 5g" &&
					this.singleChoice != "Galaxy S20" &&
					this.singleChoice != "digrct-8d-hp" &&
					this.singleChoice != "dt" &&
					this.singleChoice != "huawei mate" &&
					this.singleChoice != this.SSIDwif
				) {
					this.OtherNetworksBtn()
				}


				if (this.echoList.tablet_computer.base_param.blue_tooth == '打开') {
					this.showDeviceName = 0
				} else {
					this.showDeviceName = 1
				}

				if (this.echoList.tablet_computer.base_param.automatic_connection == '打开') {
					this.automatic_connection = 0
				} else {
					this.automatic_connection = 1
				}

				if (this.echoList.tablet_computer.base_param.scan_any_time == '打开') {
					this.scan_any_time = 0
				} else {
					this.scan_any_time = 1
				}

			},
			paddialog() {
				this.tabletborder = false;
			},
			padProductDescriptionDialog() {
				this.tabletbsorder = false;
			},
			// pad保存信息
			tabletConnection() {

				// this.$store.state.EchoArrLiat[0] = this.ssidtoggleInputBoxd3
				// blue_tooth_name
				// "blue_tooth": "关闭",
				// "blue_tooth_name": null

				this.obj.floor_mopping_robot.base_param.blue_tooth = this.blueOff;
				this.obj.floor_mopping_robot.base_param.blue_tooth_name = this.blueName;

				sessionStorage.setItem("cipherG", this.ssidtoggleInputBoxd3);


				// this.tabletborder = false;

				if (this.labelNamelist[this.Connection]) {
					this.obj.tablet_computer.base_param.automatic_connection =
						this.labelNamelist[this.Connection];
				}
				// 扫地机器人的蓝牙
				//  if ( this.bluetoothDisplayDeviceVariables == this.padDeviceDisplay) {
				//      this.obj.tablet_computer.base_param.blue_tooth  = "打开";
				// }else{
				//      this.obj.tablet_computer.base_param.blue_tooth  = "关闭";
				// }

				// if (this.laqwebelNa[this.blue_index_tooth]) {
				//   this.obj.tablet_computer.base_param.blue_tooth =
				//     this.laqwebelNa[this.blue_index_tooth];
				// }
				if (this.laqwebelNa[this.scancangtime]) {
					this.obj.tablet_computer.base_param.scan_any_time =
						this.laqwebelNa[this.scancangtime];
				}

				// if (this.sngtime == "" ) {
				//     this.obj.tablet_computer.base_param.SSID  = "";
				// } else {
				//     this.obj.tablet_computer.base_param.SSID =
				//     this.miqerryue[this.sngtime];
				// }
				// if (this.sngtime !== "" && this.ssidtoggleInputBoxd !== '') {
				//     this.obj.tablet_computer.base_param.SSID =  this.ssidtoggleInputBoxd
				// }

				// this.obj.tablet_computer.base_param.SSID = this.ssidSSID;
				//   this.obj.tablet_computer.base_param.SSID = this.miqerryue[this.sngtime];
				if (this.ssidtoggleInputBoxd3 == "") {
					this.obj.tablet_computer.base_param.SSID = ""
					// this.$store.state.EchoArrLiat[0];
				}
				if (this.ssidtoggleInputBoxd3 != "") {
					this.obj.tablet_computer.base_param.SSID = this.ssidtoggleInputBoxd3;
					if (this.ssidtoggleInputBoxd3 != "" && this.ssidtoggleInputBoxd != "") {
						if (this.clickLY == 1) {
							this.obj.tablet_computer.base_param.SSID = this.ssidtoggleInputBoxd
						} else if (this.clickLY == 2) {
							this.obj.tablet_computer.base_param.SSID = this.ssidtoggleInputBoxd3

						}

					}

				}

				this.obj.tablet_computer.base_param.password = this.ssidPassword;
				this.obj.tablet_computer.base_param.safety =
					this.safetyArr[this.safetyindex];

				if (this.padProgressBarName === 0 && this.padProgressBarName <= 110) {
					this.tabletTimer = setInterval(() => {
						this.padProgressBarName = this.padProgressBarName += 5;
					}, 100);
				}

				setInterval(() => {
					if (this.padProgressBarName >= 105) {
						clearInterval(this.tabletTimer);
						this.padProgressBarName = 0;
					}
				}, 100);
				if (this.padDeviceDisplay == this.bluetoothDisplayDeviceVariables) {
					this.robotBluetoothSwitch = "打开";
				} else {
					this.robotBluetoothSwitch = "关闭";
				}
				// this.obj.floor_mopping_robot.base_param.blue_tooth =this.robotBluetoothSwitch;
				// if(this.bluelanyatooth == 0 || this.bluelanyatooth == "打开"){
				//   this.obj.tablet_computer.base_param.blue_tooth= '打开'
				// }else if (this.bluelanyatooth != 0 || this.bluelanyatooth != "打开"){
				//   this.obj.tablet_computer.base_param.blue_tooth='关闭'
				// }
				this.preservationBtnName();
			},
			mobile_ItemBtn(i) {
				this.mobile_active = i;
				if (this.mobile_active == 2) {
					// 手机开机流程
					// this.echoOfMobilePhoneStartupprocess();
				}
			},
			secretKey(i) {
				this.seret = i;
			},
			SSIDUWwif(t) {
				this.wifisaiingex = t;
			},
			security(i) {
				this.securityIndex = i;
			},
			FrequencyBan(i) {
				this.Frequency_ban_index = i;
			},
			algorithm(i) {
				this.algorithmIndex = i;
			},
			// wifi保存信息
			padNextss() {
				// if (this.SSIDwif == 'oppo reno5 5g'&&'Galaxy S20'&&'digrct-8d-hp'&&'dt'&&'huawei mate') {
				//   this.obj.wireless_fidelity.base_param.SSID = '';
				// }else{
				//   this.obj.wireless_fidelity.base_param.SSID = this.SSIDwif;
				// }
				if (this.ssidtoggleInputBoxd == this.SSIDwif) {
					if (this.whether_hide_SSID == "是") {
						this.ssidtoggleInputBoxd = "";
					}
				}
				this.obj.wireless_fidelity.base_param.SSID = this.SSIDwif;
				this.obj.wireless_fidelity.base_param.whether_hide_SSID =
					this.whether_hide_SSID;
				this.obj.wireless_fidelity.base_param.PSK_password = this.PSK_password;
				// AP_band
				if (this.pindlist[this.Frequency_ban_index]) {
					this.obj.wireless_fidelity.base_param.AP_band =
						this.pindlist[this.Frequency_ban_index];
				}
				// 加密算法
				if (this.jmsfarrlist[this.algorithmIndex]) {
					this.obj.wireless_fidelity.base_param.encryption =
						this.jmsfarrlist[this.algorithmIndex];
				}
				// 安全性
				if (this.aqxlist[this.securityIndex]) {
					this.obj.wireless_fidelity.base_param.safety =
						this.aqxlist[this.securityIndex];
				}
				// 密钥周期
				if (this.miyue[this.seret]) {
					this.obj.wireless_fidelity.base_param.secret_key_time =
						this.miyue[this.seret];
				}

				if (this.whether_hide_SSID == "否") {
					this.miqerArrryue.unshift(this.SSIDwif);
				} else if (this.whether_hide_SSID == "是") {
					this.miqerArrryue = [];
				}
				if (this.miqerArrryue.length > 1) {
					this.miqerArrryue.pop();
				}
				this.$store.state.EchoArrLiat = this.miqerArrryue;

				this.EchoDisplayArr = this.$store.state.EchoArrLiat;
				if (
					this.routerProgressBarName === 0 &&
					this.routerProgressBarName <= 110
				) {
					this.wifiTimer = setInterval(() => {
						this.routerProgressBarName = this.routerProgressBarName += 5;
					}, 100);
				}

				setInterval(() => {
					if (this.routerProgressBarName >= 105) {
						this.routerProgressBarName = 0;
						clearInterval(this.wifiTimer);
					}
				}, 100);
				this.preservationBtnName();
				// this.routerIntroductionBtnsync = false;
			},

			isfontActiveBtn(j) {
				this.fontactive = j;
			},
			InterphoneBtn() {
				this.icon_s = true;
			},
			walkie_talkie() {
				this.icon_s = false;
			},

			// 对讲机保存
			img_btnN() {
				let flag = this.gonglc == this.obj.base_param.distance?true:false;
				let dianwyuanFlag = false;
				let tbtnswFlag = false;
				if(this.dianwyuan == '0' || this.dianwyuan == '打开' ){
					dianwyuanFlag = true;
				} 
				if(this.tbtnsw == '0' || this.tbtnsw == '打开' ){
					tbtnswFlag = true;
				} 
				if(flag && dianwyuanFlag && tbtnswFlag && this.pindaouws == this.obj.base_param.channel){
					this.Interphone = 1;
				}else{
					this.Interphone = 0;
				}
				// this.icon_s = false;
				// this.wisdmsow = 1;
				// this.obj.inter_phone.base_param.power_switch = this.powercaneg == "" ? "" : '1'
				if (this.labelNamelist[this.powercaneg]) {
					this.obj.inter_phone.base_param.power_switch =
						this.labelNamelist[this.powercaneg];
				}
				if (this.power_switcharr[this.switchcangq]) {
					this.obj.inter_phone.base_param.power =
						this.power_switcharr[this.switchcangq];
				}

				if (this.labelNamelist[this.communicationWitchq]) {
					this.obj.inter_phone.base_param.communication_switch =
						this.labelNamelist[this.communicationWitchq];
				}

				if (this.channwel[this.powerchangep]) {
					this.obj.inter_phone.base_param.channel =
						this.channwel[this.powerchangep];
				}

				if (
					this.interphoneProgressBarName === 0 &&
					this.interphoneProgressBarName <= 110
				) {
					this.interphoneTimer = setInterval(() => {
						this.interphoneProgressBarName = this.interphoneProgressBarName += 5;
					}, 100);
				}

				setInterval(() => {
					if (this.interphoneProgressBarName >= 105) {
						this.interphoneProgressBarName = 0;
						clearInterval(this.interphoneTimer);
					}
				}, 100);
			},
			// 电视保存
			television_wancheng() {
				if (this.tVProgressBarName === 0 && this.tVProgressBarName <= 110) {
					this.tvTimer = setInterval(() => {
						this.tVProgressBarName = this.tVProgressBarName += 5;
					}, 100);
				}

				setInterval(() => {
					if (this.tVProgressBarName >= 105) {
						this.tVProgressBarName = 0;
						clearInterval(this.tvTimer);
					}
				}, 100);
				this.preservationBtnName();
			},
			storedVariables() {
				// let currentId =  window.sessionStorage.getItem("currentId");
				return (currentId = window.sessionStorage.getItem("currentId"));
			},
			getPreser() {
				sessionStorage.setItem("key_select", this.equipmentMod);

				let dataobj = new FormData();
				dataobj.append("answer_data", sessionStorage.getItem("answer_data3"));
				dataobj.append("user_id", this.$store.state.user_id);
				dataobj.append("data_sign", "intelligence_communication");
				dataobj.append("task_id", this.$store.state.taskId);
				dataobj.append("template_id", this.$store.state.template_id);

				communicationdatasave(dataobj).then((res) => {

				});
			},

			// 保 存
			preservationBtnName(i) {
				if (this.bluetoothSwitchOfSweepingRobot == true) {
					this.obj.tablet_computer.base_param.blue_tooth = "打开"
				} else if (this.bluetoothSwitchOfSweepingRobot == false) {
					this.obj.tablet_computer.base_param.blue_tooth = "关闭"
				}
				this.obj.floor_mopping_robot.base_param.blue_tooth = this.blueOff;
				this.obj.floor_mopping_robot.base_param.blue_tooth_name = this.blueName;
				this.obj.container = this.container;
				sessionStorage.setItem("key_select", this.equipmentMod);
				sessionStorage.setItem("answer_data3", JSON.stringify(this.obj))

				let dataobj = new FormData();
				dataobj.append("answer_data", sessionStorage.getItem("answer_data3"));
				dataobj.append("user_id", this.$store.state.user_id);
				dataobj.append("data_sign", "intelligence_communication");
				dataobj.append("task_id", this.$store.state.taskId);
				dataobj.append("template_id", this.$store.state.template_id);

				communicationdatasave(dataobj).then((res) => {
					if (res.data.code == 200 && i == 1) {
						this.$message({
							message: "保存成功",
							type: "success",
						});
					}
				});
			},

			//判断信息流程进下面是否显示
			isShow(){
				let flag = false;
				let xlInfo = this.echoList.wire_telephone.signaling_procedure.base_param;
				if(xlInfo.phone_num &&xlInfo.phone_num == "010-8888 6666"){
					if(xlInfo.calling_hang_up ||xlInfo.reminder_hang  ||xlInfo.CLF || xlInfo.RLG ){
						this.wnassowisw = 1;
						this.wnasswrowisw = 2;
						flag = true;
					}else{
						flag = false;
					}
				}else {
					if(xlInfo.called_hang_up||xlInfo.reminder_hang ||xlInfo.CLF || xlInfo.RLG || xlInfo.CBK){
						this.wnassowisw = 1;
						this.wnasswrowisw = 1;
						flag = true;
					}else {
						flag = false;
					}
				}
				if(flag){
					this.ulShow = true;
				}
			}

		},
		destroyed() {
			location.reload();
			// this.$router.go(0);
		},
	};
</script>
<style scoped lang="scss">
	/deep/ .el-dialog {
		// --el-dialog-width: 1000px;
		width: 60% !important;
	}

	@import "../../assets/css/Intelligent/Intelligent.css";
	@import "../../assets/css/dialogForm.scss";
</style>
	