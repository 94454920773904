import Axios from '../utils/request';

// token
let user_token = sessionStorage.getItem("user_token"); //获取数据
let currentId = sessionStorage.getItem("currentId"); //获取数据
/* sessionStorage.removeItem("user_token");//删除数据
sessionStorage.clear();//删除保存的所有数据 */
// 测试接口
export const testAjax = (params) => Axios({
  method: 'post',
  url: '/token_test',
  data: params,
  headers: {
    "z-token": user_token
  }
})

// 电信业务分类与计费 回显 保存
export const telecommunicationServiceEcho = (data) => Axios.post("/communication_data_read", data)
export const telecommunicationServiceSave = (data) => Axios.post("/communication_data_save", data)

//请求列表的函数
export const getTopicscreaded = (params) => Axios.post("/get_intelligence_communication_data", {
  params
})
export const communicationdatasave = (data) => Axios.post("/communication_data_save", data) //保存
export const communicationdataread = (data) => Axios.post("/communication_data_read", data) //回显
export const getTopics = (data) => Axios.post("/intelligence_communication_verify", data)

//根据id获取详情
export const getTopic = (id, params) => Axios.get(`/topic/${id}`, {
  params
})


// 远古通信请求接口
export const getAncient = (params) => Axios.post("/get_ancient_communication_data", {
  params
})

// 古代通信的请求接口
export const getBefore = (params) => Axios.post("/get_old_communication_data", {
  params
})

// 近代通信的请求接口
export const getModern = (params) => Axios.post("/get_early_communication_data", {
  params
})
// 近代通信的请求接口传值
export const getModerntimes = (data) => Axios.post("/early_communication_verify", data)

//原始通信请求接口
export const getOriginal = (data) => Axios.post("/get_original_communication_data", data)

// 原始通信的请求接口传值
export const getOriginaltimes = (data) => Axios.post("/original_communication_verify", data)

//人类发展简史保存接口
export const getCommunication = (data) => Axios.post("/communication_data_save", data)

//人类发展简史读取保存接口
export const readCommunication = (data) => Axios.post("/communication_data_read", data)

//通信网络认知提交接口
export const Communicational = (data) => Axios.post("/sumbit_grade", data)

//模块获取参数
// {
//   params: {
//   id: 123,
//   name: 'Henry',
//   sex: 1,
//   phone: 13333333
//   }
// export const PageDispense = (data) => Axios.get( "/pageDispense",{params:data})

export const PageDispense = (data) => Axios.get("/pageDispense?taskId=" + data.taskId + "&templeteName=" + data.taskInfoName)


export const Engineering = (data) => Axios.post("/engineering_practice_verify", data)

// 查看分数 -- 测试使用
export const communicationScore = (data) => Axios.post("/communication_score", data)

// 获取用户姓名
export const get_user_data = (data) => Axios.post("/get_user_data", data)

// 全息影像控制
export const holographic = (data) => Axios.post("/holographic/control", data)
