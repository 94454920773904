<template>
	<div class="engineering_view">
		<img alt="Vue logo" src="../assets/img/engineering/engineering_pic.jpg" />
		<div class="businessList">
			<ul class="busItemList">
				<li v-for="(item, index) in engineeringItemList" :key="index" @click="changeTab(index)"
					class="businessItem" :class="{ active: currentIndex === index }">
					{{ item }}
				</li>
			</ul>
<!--
			<span @click="signal_tower(),sunClick() " class="checkClassName"  >校验</span>

			<span @click="basestation_room(),sunClick()" class="checkClassName"  >校验</span> -->

			<span @click="backStep" class="checkClassName">返 回</span>

			<span @click="checkAnswerData()" class="backStep">校 验</span>
			<span @click="saveData('aa',1)" class="backStep saveData">保 存</span>


		</div>
		<!-- 小唐 提示 -->
		<img class="cup2" @click="showAlert" src="../assets/img/businessProcess/cup2.png" alt="" />
		<div class="cup3" v-show="showRobot">
			<div class="cup3_row"></div>
			<p class="Robot_tips">红军不怕远征难,万水千山只等闲。祝贺大家一路披荆斩棘，闯过重重难关。我国移动通信的发展如同长征一般，历经1G模拟电路时代，国内技术空白；2G数字时代，国际厂家抢占中国市场；
				3G 时代打破垄断，3G国际标准之一TD-SCDMA由大唐提出并产业化；4G 时代TD-LTE规模商用，同世界一起齐头并进，实现移动互联；5G
				时代自研自建，世界领先。一代代通信人深耕不辍的精神，是长征精神在新时代的写照。接下来进入实践仿真，体会通信的魅力！
			</p>
		</div>
		<!-- 小唐人提示 -->
		<img class="robot_textTips" @click="robotTextTipsFn()" src="../assets/img/businessProcess/robot_textTips.png"
			alt="">
		<div class="textTips" v-show="robotTextTips_show">
			<div class="row"></div>
			<p class="text" style="white-space: pre-wrap;">{{robotTipsText}}</p>
		</div>
		<div class="businessPart">
			<div class="busPart busPart0" v-show="currentIndex == 0">
				<img class="baseStationLayout_pic" alt="baseStationLayout_pic"
					src="../assets/img/engineering/baseStationLayout_pic.jpg" @click="kkys" />
				<div class="baseStationLayout">
					<div v-for="(item, index) in baseStationTowerName" :key="index"
						:class="`baseStationTower_box baseStationTower_box${index + 1}`" @mouseenter="mouseover(index)"
						@mouseleave="mouseoverG(index)">
						<img :class="`baseStationTower baseStationTower${index + 1}`" alt="基站塔" :src="
                require(`../assets/img/engineering/baseStationTower/baseStationTower_${
                  index + 1
                }.png`)
              " @click="parameterConfig(index, item)" />
						<div class="baseStationTower_select" v-if="baseStationTower_data[index].show">
							<el-select v-model="baseStationTower_data[index].saveAnswer" placeholder="请选择"
								:popper-append-to-body="false" :append-to-body="false">
								<el-option v-for="item in baseStationTowerName" :key="item" :label="item" :value="item">
								</el-option>
							</el-select>
						</div>
					</div>
				</div>
			</div>

			<div class="busPart busPart1" v-show="currentIndex == 1">
				<img class="equipment_pic" alt="equipment_pic"
					src="../assets/img/engineering/equipmentLayout_pic.jpg" />
				<div class="equipmentLayout">
					<div v-for="(item, index) in machineRoomData" :key="index"
						:class="`equipment_box equipment_box${item.num}`" @mouseenter="equipmentMouseover(index)"
						@click="equipmentParameterConfig(index, item.name, item.pageID)">
						<img v-show="item.show" :class="`equipmentImg equipmentImg${item.num}`" alt="设备" :src="
                require(`../assets/img/engineering/baseStationMachineRoom/equipment_${item.num}.png`)
              " />
					</div>
				</div>
			</div>

			<div class="busPart busPart2" v-show="currentIndex == 2">
				<img class="core_pic" alt="core_pic" src="../assets/img/engineering/core_map.jpg" />
				<div class="coreLayout">
					<div v-for="(item, index) in coreMachineRoomData" :key="index"
					:class="`core_box core_box${index}`"
					@mouseenter="coreEquipmentMouseover(index)"
					@click=" coreEquipmentParameterConfig( index, item.machineRoom_Name + item.machineRoom_Type, item.machineRoom_ID )
					">
						<img v-show="item.show" :class="`coreImg coreImg${index}`" alt="核心机房设备" :src="
                require(`../assets/img/engineering/coreMachineRoom/${item.machineRoom_ID}_1.png`)
              " />
					</div>
					<!-- 连线1 -->
					<!-- <ul class="lineBox">
						<li v-for="n in 10" :key="n" :class="`linrItem linrItem_${n}`" style="color:red">{{n}}</li>
					</ul> -->
          <img v-show="lineone"  style="position: absolute;width: 30%;left: 40%;top: 12%;" src="../assets/img/engineering/lineone.png" alt="">
          <img v-show="linetwo"   style="position: absolute;width: 30%;left: 40%;top: 12%;" src="../assets/img/engineering/linetwo.png" alt="">
				</div>
				<!-- <ul class="connectingBigBox">
          <li class="firstLineFirstParagraph"></li>
          <li class="theSecondSectionOfTheFirstLine"></li>
          <li class="secondSectionOfConnection"></li>
          <li class="theThirdSectionOfConnection"></li>
          <li class="theFourthSegmentOfConnection"></li>
          <li class="theFifthSegmentOfConnection"></li>
        </ul> -->
			</div>
		</div>

		<!-- 信号塔 参数弹框 -->
		<el-dialog :title="dialogFormTitle" v-show="dialogFormVisible" v-model="dialogFormVisible" :before-close="dialogFormVisibleHandleClose" 
			width="850px">
			<div class="PadVariableclassname">
				<progressBar v-if="sweepingRobotProgressBarName > 1" :style="`width:${sweepingRobotProgressBarName}%`"
					:sweepingRobotProgressBarName="sweepingRobotProgressBarName"></progressBar>
			</div>
			<div style="height: 460px; position: relative">
				<div class="architecture signalTowerBox">
					<div class="treeBox">
						<el-tree :data="treeData" default-expand-all :props="defaultProps" @node-click="handleNodeClick"
							:highlight-current="true">
						</el-tree>
					</div>
					<div class="treeContent">
						<!-- <span class="itemTitle" >标题描述：待...{{item.title}}</span> -->
						<div class="contentTreeRight" v-for="(answerItem,answerIndex) in stationTowerAnswerList"
							:key="answerIndex" v-show="answerIndex == oneLevelTowerIndex">
							<div class="treeContentItem" v-for="(item, index) in baseStationTowerData" :key="index"
								v-show="treeID == index">
								<p>{{stemDescriptionText}}</p>
								<template v-if="item.type == 'radio'">
									<ul :class="`treeListBox  treeListBox_${item.name}`">
										<li v-for="(listName,picIndex) in item.selectList" :key="listName">
											<div :class="`radioImg ${listName}`">
												<!-- antenna天线  hover展示信息 -->
												<template v-if="item.msgList">
													<el-popover placement="right" :width="300" trigger="hover"
														show-arrow title="详情" popper-class="tooltip-wrap">
														<div class="popover-content">
															<ul>
																<li v-for="(textItem,textIndex) in item.msgList[picIndex]"
																	:key="textIndex">{{textItem}}</li>
															</ul>
														</div>
														<template #reference>
															<img alt="器件"
																:src="require(`../assets/img/engineering/baseStationTower/${item.name}/${listName}.png`)" />
														</template>
													</el-popover>
												</template>
												<template v-else>
													<img alt="器件"
														:src="require(`../assets/img/engineering/baseStationTower/${item.name}/${listName}.png`)" />
												</template>
											</div>
											<el-radio class="radioItem" v-model="answerItem[index]"
												:label="item.titleList[picIndex]">{{ item.titleList[picIndex]}}
											</el-radio>
										</li>
									</ul>
								</template>
								<template v-if="item.type == 'checkbox'">
									<el-checkbox-group v-model="answerItem[index]" style="width:100%;height: 100%;">
										<ul :class="`treeListBox  treeListBox_${item.name}`">
											<li v-for="(listName,picIndex) in item.selectList" :key="listName">
												<div :class="`radioImg ${listName}`">
													<template v-if="item.msgList">
														<el-popover placement="right" :width="300" trigger="hover"
															show-arrow title="详情" popper-class="tooltip-wrap">
															<div class="popover-content">
																<ul>
																	<li v-for="(textItem,textIndex) in item.msgList[picIndex]"
																		:key="textIndex">{{textItem}}</li>
																</ul>
															</div>
															<template #reference>
																<img alt="器件"
																	:src="require(`../assets/img/engineering/baseStationTower/${item.name}/${listName}.png`)" />
															</template>
														</el-popover>
													</template>
													<template v-else>
														<img alt="器件"
															:src="require(`../assets/img/engineering/baseStationTower/${item.name}/${listName}.png`)" />
													</template>
												</div>
												<el-checkbox class="radioItem" :label="item.titleList[picIndex]">
												</el-checkbox>
											</li>
										</ul>
									</el-checkbox-group>
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="dialog-footer">
				<el-button @click="dialogFormVisible = false">关 闭</el-button>
				<el-button type="primary" @click="getProgressBar">保 存</el-button>
			</div>
		</el-dialog>

		<!-- 基站机房 参数弹框 -->
		<el-dialog :title="machineRoom_dialogFormTitle" v-model="machineRoom_dialogFormVisible" :close-on-click-modal="false"
			:before-close="machineRoom_dialogFormVisibleHandleClose" width="850px">
			<div class="PadVariableclassname">
				<progressBar v-if="sweepingRobotProgressBarName > 1" :style="`width:${sweepingRobotProgressBarName}%`"
					:sweepingRobotProgressBarName="sweepingRobotProgressBarName"></progressBar>
			</div>
			<div style="height: 460px; position: relative">
				<!-- 1.是否有树结构 2.是否有左侧图解 3.两种情况都不是-->
				<template v-for="(machineRoomItem, index) in machineRoomData" :key="index">
					<img class="itemTitleIcon" @click="itemTitleContent(index)" :src="
              require(`../assets/img/engineering/baseStationMachineRoom/itemTitleIcon.png`)
            " alt="题干描述" />
					<div class="itemTitleBox" v-show="itemTitleContentShow" v-if="itemTitleContentShowIndex == index">
						<div class="itemText" v-if="machineRoomItem.describe.text.dec.length > 0">
							<p class="title">{{ machineRoomItem.describe.text.title }}</p>
							<ul class="textContent">
								<li v-for="(textItem, index) in machineRoomItem.describe.text.dec" :key="index">
									<span v-if="textItem[0].length > 0">{{ textItem[0] }}</span>
									<p>{{ textItem[1] }}</p>
								</li>
								<!-- <li style="text-align: center">
                  <img src="../assets/img/engineering/baseStationMachineRoom/DCPD_textImg.jpg" alt="">
                </li> -->
							</ul>
						</div>
						<div class="itemImg" v-if="machineRoomItem.describe.textImgList.textImg.length > 0">
							<p class="title">
								{{ machineRoomItem.describe.textImgList.title }}
							</p>
							<img class="childrenImg" v-for="(imgItem, index) in machineRoomItem.describe.textImgList
                  .textImg" :key="index" alt="描述配图" :src="
                  require(`../assets/img/engineering/baseStationMachineRoom/${imgItem}.png`)
                " />
						</div>
					</div>

					<div v-if="machineRoomItem.treeStructure == true" v-show="currentPageID == machineRoomItem.pageID"
						class="architecture">
						<div class="treeBox">
							<el-tree :data="machineRoomItem.machineRoomTreeData" default-expand-all
								:props="defaultProps" @node-click="machineRoomHandleNodeClick"
								:highlight-current="true">
							</el-tree>
						</div>
						<div class="treeContent">
							<p class="itemTitle">{{ machineRoomItem.title }}</p>
							<div class="contentTreeRight">
								<div class="treeContentItem" v-for="(item, twoIndex) in machineRoomItem.fromList"
									:key="twoIndex" v-show="treeContentItemIndex == twoIndex">
									<el-form v-for="(minItem, threeIndex) in item" :key="threeIndex"
										:label-width="formLabelWidth" class="demo-ruleForm cableHeightStyle"
										style="width: 100%;height:100%;">
										<el-form-item v-if="minItem.type == 'select'" :label="minItem.name">
											<el-select v-model="minItem.answer" placeholder="请选择">
												<el-option v-for="(lastItem, lastIndex) in minItem.dataList"
													:key="lastIndex" :label="lastItem" :value="lastItem"></el-option>
											</el-select>
										</el-form-item>
										<el-form-item v-if="minItem.type == 'input'" :label="minItem.name">
										</el-form-item>
										<ul v-if="minItem.type == 'radio'" class="treeListBox"
											:class="{'treeListBox_connector':minItem.dataList.length == 8}">
											<li v-for="(lastItem, lastIndex) in minItem.dataList" :key="lastIndex">
												<div :class="`radioImg radioImg${lastIndex}`" >
													<img alt="器件图" style="margin:20px 0 "  :src="
                            require(`../assets/img/engineering/baseStationTower/cable/${minItem.imgSrcList[lastIndex]}.png`)
                          " />
												</div>
												<el-radio class="radioItem" v-model="minItem.answer" :label="lastItem">
													{{ lastItem }}</el-radio>
											</li>
										</ul>
									</el-form>
								</div>
							</div>
						</div>
					</div>
					<div v-else-if="machineRoomItem.imgStructure == true"
						v-show="currentPageID == machineRoomItem.pageID" class="architecture">
						<div class="exampleDiagram">
							<img alt="设备示例图" :class="`exampleDiagram${index}`" :src="
                  require(`../assets/img/engineering/baseStationMachineRoom/${machineRoomItem.imgPicName}`)
                " />
						</div>
						<div class="exampleDiagramRight">
							<p class="itemTitle">{{ machineRoomItem.title }}</p>
							<template v-for="(item, twoIndex) in machineRoomItem.fromList" :key="twoIndex">
								<el-form :label-width="formLabelWidth" class="demo-ruleForm">
									<template v-for="(minItem, threeIndex) in item" :key="threeIndex">
										<el-form-item v-if="minItem.type == 'select'" :label="minItem.name">
											<el-select v-model="minItem.answer" placeholder="请选择">
												<el-option v-for="(lastItem, lastIndex) in minItem.dataList"
													:key="lastIndex" :label="lastItem" :value="lastItem"></el-option>
											</el-select>
										</el-form-item>
									</template>
								</el-form>
							</template>
						</div>
					</div>
					<div v-else class="architecture" v-show="currentPageID == machineRoomItem.pageID">
						<p class="itemTitle">{{ machineRoomItem.title }}</p>
						<template v-for="(item, twoIndex) in machineRoomItem.fromList" :key="twoIndex">
							<el-form :label-width="formLabelWidth" class="demo-ruleForm">
								<template v-for="(minItem, threeIndex) in item" :key="threeIndex">
									<el-form-item v-if="minItem.type == 'select'" :label="minItem.name">
										<el-select v-model="minItem.answer" placeholder="请选择">
											<el-option v-for="(lastItem, lastIndex) in minItem.dataList"
												:key="lastIndex" :label="lastItem" :value="lastItem"></el-option>
										</el-select>
									</el-form-item>
								</template>
							</el-form>
						</template>
					</div>
				</template>
			</div>
			<div class="dialog-footer">
				<el-button @click="machineRoom_dialogFormVisible = false">关 闭</el-button>
				<el-button type="primary" @click="getProgressBar">保 存</el-button>
			</div>
		</el-dialog>

		<!-- 核心机房 一级弹框展示机柜 -->
		<el-dialog :title="core_machineRoom_dialogFormTitle" v-model="core_machineRoom_dialogFormVisible" :close-on-click-modal="false"
			:before-close="core_machineRoom_dialogFormVisibleHandleClose" width="850px">
			<div class="PadVariableclassname">
				<progressBar v-if="sweepingRobotProgressBarName > 1" :style="`width:${sweepingRobotProgressBarName}%`"
					:sweepingRobotProgressBarName="sweepingRobotProgressBarName"></progressBar>
			</div>
			<div style="height:42px;line-height: 42px;">
				提示：{{ coreMachineRoomData[core_itemTitleContentShowIndex].title }}
			</div>
			<div style="height: 460px; position: relative">
				<!-- <span class="itemTitle" >标题描述：待...{{item.title}}</span> -->
				<template v-for="(coreItem, index) in coreMachineRoomData" :key="index">
					<div class="architecture" v-show="core_currentPageID == coreItem.machineRoom_ID">
						<!-- 底图切换 核心机房，汇聚机房，接入机房 -->
						<img v-if="coreItem.machineRoom_Type == '核心机房'" class="coreItemBg" :src="
                require(`../assets/img/engineering/coreMachineRoomImg/coreMachineRoomBg.jpg`)
              " alt="核心机房" />
						<img v-else-if="coreItem.machineRoom_Type == '汇聚机房'" class="coreItemBg" :src="
                require(`../assets/img/engineering/coreMachineRoomImg/convergenceMachineRoomBg.jpg`)
              " alt="汇聚机房" />
						<img v-else-if="coreItem.machineRoom_Type == '接入机房'" class="coreItemBg" :src="
                require(`../assets/img/engineering/coreMachineRoomImg/accessMachineRoom.jpg`)
              " alt="接入机房" />
						<div class="miniArchitecture" :class="{
                'coreArchitectureBox': coreItem.machineRoom_Type == '核心机房',
                'convergenceArchitectureBox':
                  coreItem.machineRoom_Type == '汇聚机房',
                'accessArchitectureBox': coreItem.machineRoom_Type == '接入机房',
              }">
							<!-- <p class="itemTitle">提示：{{ coreItem.title }}</p> -->
							<!-- 子页面分四种类型：传输  ODF机柜传输 特殊子页面 -->
							<div :class="`subpageItem cabinet_${twoIndex}`"
								v-for="(cabinetItem, twoIndex) in coreItem.subpage_cabinet" :key="twoIndex"
								@click="core_subpagePage( index, cabinetItem.name, cabinetItem.type, cabinetItem.id, twoIndex )
								"></div>
							<!-- 合并为一个机柜： ODF传输  -->
							<div :class="`subpageItem ODF_1`"
							@click="core_subpagePage( index, coreItem.subpage_ODF.name, coreItem.subpage_ODF.type, coreItem.subpage_ODF.id,0) "></div>
							<!-- 核心机房 有特别处理的页面'EPC','5GC'； 接入机房 有特别处理的页面'DDF' -->
							<template v-if="
                  coreItem.machineRoom_Type == '核心机房' ||
                  coreItem.machineRoom_Type == '接入机房'
                ">
								<div :class="`subpageItem especially_${especiallyIndex}`" v-for="(
                    especiallyItem, especiallyIndex
                  ) in coreItem.especially_page" :key="especiallyItem" @click="
                    core_subpagePage(
                      index,
                      especiallyItem.name,
                      especiallyItem.type,
                      especiallyItem.id,
                      especiallyIndex
                    )
                  "></div>
							</template>
						</div>
					</div>
				</template>
			</div>
		</el-dialog>

		<!-- 核心机房 二级弹框展示参数 -->
		<el-dialog :title="core_machineRoom_dialogFormTitle_secondLevel" :close-on-click-modal="false"
			v-model="core_machineRoom_dialogFormVisible_secondLevel"
			:before-close="core_machineRoom_dialogFormVisibleHandleClose_secondLevel" width="850px">
			<div class="PadVariableclassname">
				<progressBar v-if="sweepingRobotProgressBarName > 1" :style="`width:${sweepingRobotProgressBarName}%`"
					:sweepingRobotProgressBarName="sweepingRobotProgressBarName"></progressBar>
			</div>
			<div style="height: 460px; position: relative">
				<!-- 子页面分四种类型：传输  ODF传输 特殊子页面 -->
				<template v-for="(coreItem, index) in coreMachineRoomData" :key="index">
					<!-- 传输 -->
					<div v-for="(cabinetItem, twoIndex) in coreItem.subpage_cabinet" :key="twoIndex"
						:class="`secondLevelPage cabinetPage`"
						v-show="core_currentPageID_secondLevel == cabinetItem.id">
						<img class="coreCabinetBg" :src="
                require(`../assets/img/engineering/coreMachineRoomImg/transmissionCabinet.jpg`)
              " alt="核心机房" />
						<div class="transmissionCabinetBox">
							提示：此处只需要选择三个设备的其中一个并配置其线缆即可。

							<template v-if="cabinetItem.equipmentPic">
								<img class="coreEquipmentPic" alt="核心设备"
									:src="require(`../assets/img/engineering/coreMachineRoomImg/transmissionEquipment/${cabinetItem.equipmentPic}.png`)" />
									<div style="opacity: 0;" :class="'pic'+ cabinetItem.id + twoIndex" >{{cabinetItem.equipmentPic}}</div>
							</template>

							<ul class="radioListBox">
								<li v-for="(lastItem, lastIndex) in templateData_cabinet.radioList"
								:key="lastIndex" @click="cabinetItem.equipmentPic = lastItem; checkboxPageIndex = lastIndex; ">
								{{lastItem}}
								</li>
							</ul>

							<ul class="checkboxListUl">
								<li v-for="( miniItem, miniIndex ) in templateData_cabinet.radioList" :key="miniItem" v-show="checkboxPageIndex == miniIndex">
									<el-checkbox-group v-model="cabinetItem.checkboxAnswer[miniIndex].answer">
										<el-checkbox v-for="lastItem in templateData_cabinet.checkboxList"
											:key="lastItem" :label="lastItem"></el-checkbox>
									</el-checkbox-group>
								</li>
							</ul>

						</div>
					</div>
					<!-- ODF机柜 传输 -->
					<div :class="`secondLevelPage ODF_page`"
						v-show="core_currentPageID_secondLevel == coreItem.subpage_ODF.id">
						<img class="ODF_ItemBg" src="../assets/img/engineering/coreMachineRoomImg/ODF.jpg" alt="" />
						<div class="ODF_contentBox">
							<!-- 传输区 SC FC 满配 -->
							<div v-for="(transmissionItem,transmissionIndex) in coreItem.subpage_ODF.transmissionArea"
								:key="transmissionItem"
								:class="`transmissionItem transmissionItem_${transmissionIndex}`">

								<el-popover
								    placement="right"
								    :width="80"
								    trigger="hover"
								    :content="transmissionIndex == 0 ? '传输 1' : '传输 2' ">
									<template #reference>
										<img class="ODFMultiSelectPicturesimg"
											:src="require(`../assets/img/engineering/coreMachineRoomImg/transmission/${transmissionItem}.png`)"
											alt="线缆图片" />
									</template>
								</el-popover>
							</div>
							<!-- 光缆区 -->
							<el-popover placement="right" :width="200" trigger="hover" show-arrow title="光缆成端区:"
								popper-class="tooltip-wrap">
								<div class="popover-content">
									<ul>
										<li v-for="(textItem,textIndex) in coreItem.subpage_ODF.opticalCableArea[1]"
											:key="textIndex">{{textItem}}</li>
									</ul>
								</div>
								<template #reference>
									<div class="opticalCable">
										<img class="ODFMultiSelectPicturesimg"
											:src="require(`../assets/img/engineering/coreMachineRoomImg/transmission/${coreItem.subpage_ODF.opticalCableArea[0]}.png`)"
											alt="线缆图片" />
									</div>
								</template>
							</el-popover>
							<!-- 线缆添加表 -->
							<div class="ODF_tableStyle">
								<div class="addRouteFnBtn">
									<el-button size="medium" type="primary" @click="addRouteFn(index)">添加路线 +
									</el-button>
								</div>
								<el-table border height="290" stripe :data="coreItem.subpage_ODF.answer">
									<el-table-column align="center" prop="transmissionType" label="传输类型">
										<template #default="scope">
											<el-select v-model="scope.row[scope.column.property]" placeholder="请选择"
                      :popper-append-to-body="false" 
												size="mini">
												<el-option
													v-for="(lastItem, lastIndex) in coreItem.subpage_ODF.miniName"
													:key="lastIndex" :label="lastItem" :value="lastItem">
												</el-option>
											</el-select>
										</template>
									</el-table-column>
									<el-table-column align="center" prop="jumpMachineRoom" label="跳转机房">
										<template #default="scope">
											<el-select v-model="scope.row[scope.column.property]" placeholder="请选择"
												size="mini">
												<el-option v-for="(lastItem, lastIndex) in templateData_ODF[0].dataList"
													:key="lastIndex" :label="lastItem" :value="lastItem">
												</el-option>
											</el-select>
										</template>
									</el-table-column>
									<el-table-column align="center" prop="cableSelectItem" label="线缆选择">
										<template #default="scope">
											<el-button link type="primary" size="small"
												@click.prevent="cableSelectBtn(index,scope.$index,scope.row[scope.column.property])">
												  {{scope.row[scope.column.property] == "" ? "点击选择" : scope.row[scope.column.property]}}
											</el-button>

										</template>
									</el-table-column>
									<el-table-column label="操作" align="center">
										<template #default="scope">
											<el-button type="danger" size="small"
												@click.prevent="handleDelete(index,scope.$index)">
												删除
											</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<!-- 线缆选择 展示框 -->
							<div class="ODF_cableBox" v-show="cableSelectBoxState">
								<span class="mountainHorn"></span>
								<li v-for="(itemName, lastIndex) of templateData_ODF[1].dataList" :key="lastIndex">
									<div>
										<img class="ODFMultiSelectPicturesimg"
											:src="require(`../assets/img/engineering/coreMachineRoomImg/cable/${itemName}.png`)"
											alt="线缆图片" />
									</div>
									<el-radio v-model="cableValue" :label="templateData_ODF[1].imgSrcList[lastIndex]">
										<span>{{ templateData_ODF[1].imgSrcList[lastIndex] }}</span>
									</el-radio>
								</li>
								<div class="dialog-footer">
									<el-button size="mini" @click="cableSelectBoxState_Cancel">取 消</el-button>
									<el-button type="primary" size="mini" @click="cableSelectBoxState_Determine">确 定
									</el-button>
								</div>
							</div>
						</div>
					</div>
					<!-- 特殊子页面 -->
					<div v-for="(especiallyItem, twoIndex) in coreItem.especially_page" :key="twoIndex"
						:class="`secondLevelPage especiallyPage`"
						v-show="core_currentPageID_secondLevel == especiallyItem.id">
						<template v-if="especiallyItem.name == 'DDF'">
							<img class="especiallyItemBg DDF_Bg" :src="
                  require(`../assets/img/engineering/coreMachineRoomImg/DDF.jpg`)
                " alt="DDF" />
							<div class="DDF_text">{{ especiallyItem.dec }}</div>
						</template>
						<template v-else-if="especiallyItem.name == 'EPC'">
							<img class="especiallyItemBg EPC_Bg" :src="
                  require(`../assets/img/engineering/coreMachineRoomImg/EPC.jpg`)
                " alt="EPC" />
							<div class="especiallyItemBox Box_EPC">
								<el-form :label-width="formLabelWidth_EPC" class="demo-ruleForm">
									<template v-for="(minItem, threeIndex) in EPC_select_data" :key="threeIndex">
										<el-form-item :label="minItem.name" :class="`item_EPC_${threeIndex}`">
											<el-select v-model="minItem.answer" placeholder="请选择">
												<el-option v-for="(lastItem, lastIndex) in EPC_select_list"
													:key="lastIndex" :label="lastItem" :value="lastItem"></el-option>
											</el-select>
										</el-form-item>
									</template>
								</el-form>
							</div>
						</template>
						<template v-else-if="especiallyItem.name == '5GC'">
							<img class="especiallyItemBg" :src="
                  require(`../assets/img/engineering/coreMachineRoomImg/5GC.jpg`)
                "
                alt="5GC"
              />
              <div class="especiallyItemBox Box_5GC">
				  <!-- 答题正确 和错误展示 -->
				  <div class="incobox" v-show="finish = true">
				    <!-- 正确 -->
				    <div>
				      <img
				        class="inco"
				        src="../assets/img/imgdevelopment/ancientpeople/correct.png"
				        alt=""
				      />
				      <span class="txt"><span class="txtBos">{{ correctOk }}</span>次</span>
				    </div>
				    <div>
				      <img
				        class="inco"
				        src="../assets/img/imgdevelopment/ancientpeople/mistake.png"
				        alt=""
				      />
				      <span class="txt"><span class="txtBosErr">{{ mistakeErr }}</span>次</span>
				    </div>
				  </div>
                  <img  @click="GC_logo" class="Box_5GC_Logo" src="../assets/img/businessProcess/cup2.png" alt="">
                  <div class="cup33" v-show="Box_5GCshow">
                    <div class="cup4_row"></div>
                    <p class="Robot_tips2">
                        请点击开始后根据各应用类型选择最适合的5G场景。
                    </p>
                  </div>
                <div
                  class="cloudBox"
                  :style="getStyles()"
                  v-show="cloudBoxShowState"
                >
                  <img
                    class="cloudImg"
                    :src="
                      require(`../assets/img/engineering/coreMachineRoomImg/miniImg/5GC_cloud.png`)
                    " alt="5GC_cloud" />
									<div class="cloudItem">{{ cloudTextItem }}</div>
								</div>
								<!-- 下落接收按钮动画5GC -->
								<ul class="equipment_5GC_box">
									<li v-for="(sceneItem, sceneIndex) in equipment_5GC_data" :key="sceneIndex"
										@click="sceneEquipmentSelect(sceneItem.name, sceneIndex)"></li>
								</ul>
							</div>
						</template>
					</div>
				</template>
			</div>
			<!-- DDF 不需要底部按钮 <5GC> -->
			<template v-if="core_machineRoom_dialogFormTitle_secondLevel == 'DDF'"></template>
			<template v-else-if="core_machineRoom_dialogFormTitle_secondLevel == '5GC'">
				<div class="dialog-footer">
					<div style="z-index: 999">
						<el-button @click="core_machineRoom_dialogFormVisible_secondLevel = false">关 闭</el-button>
						<el-button :disabled="!this.startEquipmentFallState" type="success" @click="startEquipmentFall">开 始</el-button>
						<el-button type="primary" @click="getProgressBar">保 存
						</el-button>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="dialog-footer">
					<el-button @click="core_machineRoom_dialogFormVisible_secondLevel = false">关 闭</el-button>
					<el-button type="primary" @click="getProgressBar">保 存
					</el-button>
				</div>
			</template>
		</el-dialog>

		<!-- 弹出窗 校验 -->
		<!-- <tips :tipsObj="this.tipsObjs" ref="tips"></tips>
		{{this.tipsObjs}} -->
		<div class="winBox" v-show="this.showOff">
			<div class="box">
				<div class="header" @click="showOffclick"><img src="../assets/img/imgHomePage/deleteicon1.png" alt="" /></div>
			</div>
			<div class="conter" v-if="showdata">
				<el-scrollbar height="100%">
					<p v-for="item in tipsObj" :key="item" class="scrollbar-demo-item"
						:class="{colorRed: item.indexOf('错误') > -1}">
						{{getTime}}:{{item}}
					</p>
					<p v-for="item in tipsObj2" :key="item" class="scrollbar-demo-item"
						:class="{colorRed: item.indexOf('错误') > -1}">
						{{getTime}}:{{item}}
					</p>
					<p v-for="item in tipsObj3" :key="item" class="scrollbar-demo-item"
						:class="{colorRed: item.indexOf('错误') > -1}">
						{{getTime}}:{{item}}
					</p>
				</el-scrollbar>
			</div>

		</div>
		<!-- 5GC答题正确错误提示 -->
		<!-- 正确和错误提示 -->
		<div>
		  <!-- 正确 -->
		  <img
		    class="incoimg"
		    v-show="incoshow1"
		    src="../assets/img/imgdevelopment/communication/correctimg1.png"
		    alt=""
		  />
		  <!-- 错误 -->
		  <img
		    class="incoimg"
		    v-show="incoshow2"
		    src="../assets/img/imgdevelopment/communication/mistakeimg.png"
		    alt=""
		  />
		</div>
	</div>
</template>

<script>
	import $ from "jquery"; //先引入再使用
	import {
		telecommunicationServiceEcho,
		telecommunicationServiceSave,
		Engineering
	} from "../api/topics";
	import tips from "./Tips.vue"
	import {getDayTimer} from "../utils/tools.js"
	import progressBar from "../components/common/progressBar.vue";
	let common_machineRoomTreeData = [{
		label: "BBU配置",
		id: "-1",
		children: [
			{label: "供电线缆",id: "0",},
			{label: "连接供电设备",id: "1",},
			{label: "光纤通信接头",id: "2",},
			{label: "回传光模块",id: "3",},
			{label: "前传光模块",id: "4",},
		],
	}];

	let common_machineRoomTreeData_2G = [{
		label: "BTS配置",
		id: "-1",
		children: [{
				label: "供电线缆",
				id: "0",
			},
			{
				label: "连接供电设备",
				id: "1",
			},
			{
				label: "光纤通信接头",
				id: "2",
			},
		],
	}, ];
	let common_machineRoomTreeData_DCPD = [{
		label: "DCPD配置",
		id: "-1",
		children: [{
				label: "供电线缆",
				id: "0",
			},
			{
				label: "连接供电设备",
				id: "1",
			},
		],
	}, ];

	let describe5G = [
		[
			"",
			"1、设备安装完成后，应保证水平/垂直倾斜角度误差在± 1º以内。机箱安装牢固，四套组合螺钉齐全。BBU 在 19 寸标准机柜安装时必须使用托盘或托架安装。确认板卡槽位正确，并且插接牢固。保证机箱各部件无损坏、变形、掉漆等现象。",
		],
		[
			"",
			"2、电气部件连接及固定是否牢靠，重点检查传输线、 GPS 馈线接头等处。检查光纤光缆接口及与扇区对应关系，确认无误。",
		],
		[
			"",
			"3、测量直流回路的正、 负极间的相间电阻值， 确认没有短路或断路。",
		],
		[
			"",
			"4、确认直流用线颜色规格是否规范， 安全标识是否齐全。",
		],
		[
			"",
			"5、确认直流输出连接点稳固性、 线序、 极性是否正确。",
		],
	];
	let describe4G = [
		[
			"",
			"1、4G时代到来时，基站架构发生了较大的变化。为了降低端到端时延，4G采用了扁平化的网络架构。将原来的3级网络架构“扁平化”为2级：eNodeB-核心网。RNC的功能一部分分割在eNodeB中，一部分移至核心网中。4G核心网只包含PS域。",
		],
	];
	let describe3G = [
		[
			"",
			"1、3G时代主要采用分布式基站架构。分布式基站架构将NodeB分为BBU和RRU两部分。",
		],
		[
			"",
			"2、3G通信系统采用3级网络架构，即NodeB – RNC - 核心网。3G核心网同时包含CS域和PS域。",
		],
	];
	let describe2G = [
		[
			"",
			"1、2G通信系统起初主要采用一体式基站架构。一体式基站架构为基站的天线位于铁塔上，其余部分位于基站旁边的机房内。天线通过馈线与室内机房连接。",
		],
		[
			"",
			"2、2G通信系统采用3级网络架构，即：BTS-BSC-核心网。2G早期只包含CS域。",
		],
	];
	let describePDCP = [
		[
			"",
			"1、动态调压电源基于PLC技术，实现配电侧电压动态调整，AAU侧电压保持在-57V DC，用于直流供电场景主设备的分配供电。",
		],
		[
			"",
			"2、适用场景为直流电源至AAU拉远距离较远场景。",
		],
		[
			"",
			"3、安装方式灵活，支持嵌入式安装、挂墙安装；设备侧电压维持-57V DC恒定，降低线损，提升拉远距离；电源效率高，电源效率97%。",
		],
	];
	let describeDcPower = [
		[
			"",
			"1、电力直流电源系统统称为直流屏（直流电源柜），是维持电路中形成稳压恒流的装置。在系统应用中有正极、负极之分，当正、负极与电路连通后，使电路两端之间维持恒定的电位差，从而形成由正到负的电流。",
		],
		[
			"",
			"2、直流电源是一种能量转换装置系统，它把其它形式的能量转换为电能供给电路，以稳压恒流的形式持续为设备供电。",
		],
		[
			"",
			"3、电源系统由交流配电单元、直流配电单元、整流模块及监控模块组成。交流配电单元位于机柜下部，直流配电单元位于机柜上部。",
		],
	];
	let describeAirConditioning = [
		[
			"主要技术参数",
			"通讯机房中的设备是由大量微电子、精密机械设备等组成，而这些设备使用了大量的易受温度、湿度影响的电子元器件、机械构件及材料。温度对计算机机房设备的电子元件有较大的影响;如对半导体元器件而言，室温在规定范围内每增加10℃，其可靠性就会降低约25%;而对电容器，温度每增加10℃，其使用时间将下降50%;绝缘材料对温度同样敏感，温度过高，印刷电路板的结构强度会变弱，温度过低，绝缘材料会变脆，同样会使结构强度变弱。"
		],
		[
			"主要技术性能",
      "已知机房规格为： 6mx5mx3m，请根据下述方法查询机房内设备信息并进行空调规格选取。空调制冷量估算方法：Qt=Q1+Q2;其中，Qt总制冷量（KW）;Q1室内设备负荷（=设备功率×0.8）;Q2环境热负荷（=0.12～0.18KW/m2×机房面积），南方地区可选0.18，而北方地区通常选择0.12，中国标准1P空调的制冷量为2500W。"
		],
	];
	let describeBattery = [
		[
			"主要技术参数",
			"单体电池额定电压2V；单体电池浮充电电压2.23V～2.27V；单体电池均衡充电电压2.30V～2.40V；单体电池放电终止电压≥1.8；电池容量550AH及以上（10小时放电）。"
		],
		[
			"主要技术性能",
      "蓄电池组是基站机房不可或缺的组成部分，主要作用是在市电停电时给机房重要设备供电。目前48V通信设备的输入允许电压变化范围一般为40-57v ,蓄电池组并联数通常为两组。"
		],
	];
	let describeAcCabinet = [
		[
			"",
			"1.交流配电箱是按电气接线要求将开关设备、测量仪表、保护电器和辅助设备组装在封闭或半封闭金属柜中或屏幅上，构成低压配电装置。正常运行时可借助手动或自动开关接通或分断电路。故障或不正常运行时借助保护电器切断电路或报警。",
		],
		[
			"",
			"2、作为基站机房市电（三相供电）输入的枢纽，是基站机房供电的重要保障。其对于电缆规格的选择要求严格，按照电缆内芯数不同，电缆分为单芯电缆和多芯电缆，多芯电缆包括2芯电缆、3芯电缆、3+1芯电缆、4芯电缆、4+1芯电缆、5芯电缆。",
		],
		[
			"",
			"3、国内通信机房低压配电系统绝大部分采用TN-S系统，保护导体和中性导体各自独立，",
		],
	];
	let robotTipsTextAry = ["请为场景中不同塔形选择正确名称，然后进入配置界面根据要求完成不同室外系统的搭建。", "欢迎来到通信机房，请分别点击发光设备，学习基础知识，并完成相关配置填写。",
  "传输网络是通信接入网络连接核心网的重要媒介，现要求根据机房的实际情况完成如下任务。\n1、点击进入延安机房，依次完成核心网机柜和传输机柜基本配置；\n2、分别点击地图中6个机房，根据不同机房类型为传输柜添加正确传输设备（包含2&5G传输设备）；\n3、依次点击机房查看ODF架中光缆信息区信息展示，整理机房之间光缆布放信息；\n4、根据光缆布放信息添加正确线路完成承载网接入层、汇聚层、核心层机房的连接，要求形成环网结构；（注意：接入机房需要同时完成PTN和SDH两套传输的配置）\n5、根据ODF架不同区域接口的不同，选择正确的线缆进行传输设备跳纤。"	
];

	let DDF_Text =
		"DDF架简称：数字配线架，用于用户中继与传输电路间的跳接。它能使数字通信设备的数字码流的连接成为一个整体，从速率2Mb/s～155Mb/s信号的输入、输出都可终接在DDF架上，这为配线、调线、转接、扩容都带来很大的灵活性和方便性。以往DDF架分正面（高速）单元和背面（低速）单元，高速单元和低速单元相比有塞孔，可用于测试、环路等维护工作，现在为维护方便，背面也采用高速单元。一般来说，正面单元接传输设备提供的支路通道，背面单元接用户中继，两者之间通过跳线连接后即可开放业务。DDF架自身故障和跳线故障一般会引起支路信号丢失、支路误码、告警指示、对告等告警。一般情况下，在DDF架的横条上都标有该端子的所对应的电路名称、所处传输设备的网元以及对应的槽道号，在DDF架架顶都标有DDF号以及正反面，如：1A（1A:DDF1机架的正面，进传输机房的正对面为正面），1B（1B:DDF1机架的背面）。比如现有电路：E05-06A-08-10：解释为：右边第5排第6个DDF架正面第8排（从上往下数）第十个2M，DDF架上的2M顺序一般是从上往下，从左往右。";




	let randomTextIndex = Math.floor(Math.random() * 2 + 0) // 0 || 1

	let opticalCableArea_Xiangjiang = [
		["光缆湘江机房-瑞金机房", "光缆湘江机房-井冈山机房", "光缆湘江机房-遵义机房", ],
		["光缆湘江机房-瑞金机房", "光缆湘江机房-井冈山机房", ]
	][randomTextIndex];

	let opticalCableArea_Jinggangshan = [
		["光缆井冈山机房-瑞金机房", "光缆井冈山机房-湘江机房", ],
		["光缆井冈山机房-瑞金机房", "光缆井冈山机房-湘江机房", "光缆井冈山机房-会宁机房", ]
	][randomTextIndex];

	let opticalCableArea_Ruijin = [
		["光缆瑞金机房-遵义机房", "光缆瑞金机房-井冈山机房", "光缆瑞金机房-湘江机房", ],
		["光缆瑞金机房-会宁机房", "光缆瑞金机房-井冈山机房", "光缆瑞金机房-湘江机房", ]
	][randomTextIndex];

export default {
  name: "EngineeringView",
  components: {progressBar},
  data() {
    return {
		sweepingRobotProgressBarName:0,
		randomTextID:null,
		correctOk:0,
		mistakeErr:0,
		incoshow1:false,
		incoshow2:false,
		showOff:false,
      lineone:false,
      linetwo:false,
      // tipsObj:[],
      showdata:false,
      getTime:new Date(),
      Box_5GCshow:true,
      oneLevelTowerIndex:0,
      transmissionList: ["SC","FC"],//SC满配 FC满配
      robotTipsText:robotTipsTextAry[0],
      stemDescriptionText:"",
      cableSelectBoxState:false,
      jumpMachineRoom: "",
      twoabletbsorder: false,
      widthOfTheFirstLine: 0,
      clickToLoadData: 0,
      /* 第三模块 （核心机房 为一类；）*/
      /* 5GC应用场景 */
      EPC_select_list: [
        "移动性管理",
        "4G核心网",
        "服务网关",
        "PDN网关",
        "策略与计费规则功能",
        "计费网关",
        "软交换网元",
      ],
      EPC_select_data: [
        { name: "CG", correctAnswer: "计费网关", answer: "" },
        { name: "SGW", correctAnswer: "服务网关", answer: "" },
        { name: "PGW", correctAnswer: "PDN网关", answer: "" },
        { name: "MME", correctAnswer: "移动性管理", answer: "" },
        { name: "EPC", correctAnswer: "4G核心网", answer: "" },
        { name: "PCRF", correctAnswer: "策略与计费规则功能", answer: "" },
      ],
      cloudBoxShowState: false,
      startEquipmentFallState: true,
      cloudTextItem: "4K/8K云游戏",
      applicationScenario: [
          "4K/8K直播",
          "4K/8K点播",
          "AR",
          "VR",
          "4K/8K游戏",
          "4K/8K云游戏",
          "智慧银行",
          "工业自动化",
          "移动医疗",
          "自动驾驶",
          "智能家居",
          "智慧城市",
      ],
      equipment_5GC_data: [
        {
          name: "EMBB",
          correctAnswer: [
            "4K/8K直播",
            "4K/8K点播",
            "AR",
            "VR",
            "4K/8K游戏",
            "4K/8K云游戏",
            "智慧银行",
          ],
          answer: [],
        },
        {
          name: "URLLC",
          correctAnswer: [
            "工业自动化",
            "移动医疗",
            "自动驾驶",
          ],
          answer: [],
        },
        { name: "MMTC", correctAnswer: ["智能家居", "智慧城市"], answer: [] },
      ],
      checkboxPageIndex: "0",
      core_currentPageID_secondLevel: "EPC_5GC_YanAn_1",
      templateData_cabinet: {
        //传输模板
        radioList: ["OTN", "PTN", "SDH"],
        imgSrcList: ["LC-FC", "SC-SC", "FC-FC", "2M_line", "FC-SC", "LC-LC"],
        checkboxList: ["LC-FC", "SC-SC", "FC-FC", "2M线", "FC-SC", "LC-LC"],
      },
      cableValue:"",
      //ODF架模板
      templateData_ODF: [
        {
          type: "radio",
          name: "跳转机房",
          dataList: [
            "延安机房",
            "会宁机房",
            "遵义机房",
            "瑞金机房",
            "井冈山机房",
            "湘江机房",
          ],
        },
        {
          type: "radio",
          name: "线缆选择",
          dataList: ["LC-FC", "SC-SC", "FC-FC", "2M_line", "FC-SC", "LC-LC"],
          imgSrcList: ["LC-FC", "SC-SC", "FC-FC", "2M线", "FC-SC", "LC-LC"],
        },
      ],
      listcoreMachineRoomData: [],
      //'延安机房','会宁机房','遵义机房','瑞金机房','井冈山机房','湘江机房'
      //核心机房有特别处理的页面 'EPC','5GC'  接入机房有特别处理的页面 'DDF'
      coreMachineRoomData: [
        {
          show: false,
          title: "请依次完成EPC机柜、5GC机柜、核心层传输柜、ODF机柜相关配置。", //题干描述
          machineRoom_ID: "machineRoom_YanAn", //机房ID
          machineRoom_Name: "延安", //机房名称
          machineRoom_Type: "核心机房", //机房类型:核心机房 汇聚机房 接入机房
          especially_page: [
            // 特殊页面5GC EPC DDF
            { name: "EPC", type: "EPC_5GC", id: "EPC_5GC_YanAn_1", answer: [] },
            { name: "5GC", type: "EPC_5GC", id: "EPC_5GC_YanAn_2", answer: [] },
          ],
          subpage_cabinet: [
            // 传输
            {
              name: "传输1",
              type: "cabinet",
              id: "cabinet_YanAn_1",
              equipmentPic:"",
              checkboxAnswer: [
                { name: "OTN", answer: [] },
                { name: "PTN", answer: [] },
                { name: "SDH", answer: [] },
              ],
            },
            {
              name: "传输2",
              type: "cabinet",
              id: "cabinet_YanAn_2",
              equipmentPic:"",
              checkboxAnswer: [
                { name: "OTN", answer: [] },
                { name: "PTN", answer: [] },
                { name: "SDH", answer: [] },
              ],
            },
          ],
          subpage_ODF: {
            name: "ODF架",
            miniName:["传输1","传输2"],
            transmissionArea:["",""],//随机出来的SC满配 FC满配
            opticalCableArea:["",["光缆延安机房-会宁机房","光缆延安机房-遵义机房"]],//随机出来的SC满配 FC满配  鼠标hover,展示
            type: "ODF",
             id: "ODF_YanAn_1",
             answer: [{
              "transmissionType":"",//传输类型
              "jumpMachineRoom":"",//跳转机房
              "cableSelectItem":""//线缆选择
            }] },
        },
        {
          show: false,
          title: "请根据场景完成汇聚机房相关机柜基础配置。", //题干描述
          machineRoom_ID: "machineRoom_Huining", //机房ID
          machineRoom_Name: "会宁", //机房名称
          machineRoom_Type: "汇聚机房", //机房类型:核心机房 汇聚机房 接入机房
          subpage_cabinet: [
            // 传输
            {
              name: "传输1",
              type: "cabinet",
              id: "cabinet_Huining_1",
              equipmentPic:"",
              checkboxAnswer: [
                { name: "OTN", answer: [] },
                { name: "PTN", answer: [] },
                { name: "SDH", answer: [] },
              ],
            },
          ],
          subpage_ODF: { name: "ODF架",miniName:["传输1"],
          transmissionArea:[""],//随机出来的SC满配 FC满配
          opticalCableArea:["",[
              "光缆会宁机房-遵义机房",
              "光缆会宁机房-延安机房",
              "光缆会宁机房-瑞金机房",
              "光缆会宁机房-井冈山机房"]],//鼠标hover,展示
          type: "ODF",
          id: "ODF_Huining_1",
          answer: [{
              "transmissionType":"",//传输类型
              "jumpMachineRoom":"",//跳转机房
              "cableSelectItem":""//线缆选择
            }] },
        },
        {
          show: false,
          title: "请根据场景完成汇聚机房相关机柜基础配置。", //题干描述
          machineRoom_ID: "machineRoom_Zunyi", //机房ID
          machineRoom_Name: "遵义", //机房名称
          machineRoom_Type: "汇聚机房", //机房类型:核心机房 汇聚机房 接入机房
          subpage_cabinet: [
            // 传输
            {
              name: "传输1",
              type: "cabinet",
              id: "cabinet_Zunyi_1",
              equipmentPic:"",
              checkboxAnswer: [
                { name: "OTN", answer: [] },
                { name: "PTN", answer: [] },
                { name: "SDH", answer: [] },
              ],
            },
          ],
          subpage_ODF:  { name: "ODF架",miniName:["传输1"],
          transmissionArea:[""],//随机出来的SC满配 FC满配
            opticalCableArea:["",[
              "光缆遵义机房-会宁机房",
              "光缆遵义机房-延安机房",
              "光缆遵义机房-瑞金机房",
              "光缆遵义机房-湘江机房",]],//鼠标hover,展示
          type: "ODF", id: "ODF_Zunyi_1", answer: [{
              "transmissionType":"",//传输类型
              "jumpMachineRoom":"",//跳转机房
              "cableSelectItem":""//线缆选择
            }] },
        },
        {
          show: false,
          title: "请根据场景完成接入机房相关机柜基础配置。", //题干描述
          machineRoom_ID: "machineRoom_Ruijin", //机房ID
          machineRoom_Name: "瑞金", //机房名称
          machineRoom_Type: "接入机房", //机房类型:核心机房 汇聚机房 接入机房
          especially_page: [
            {
              name: "DDF",
              type: "DDF",
              id: "DDF_Ruijin_1",
              dec: DDF_Text,
            },
          ],
          subpage_cabinet: [
            // 传输
            {
              name: "传输1",
              type: "cabinet",
              id: "cabinet_Ruijin_1",
              equipmentPic:"",
              checkboxAnswer: [
                { name: "OTN", answer: [] },
                { name: "PTN", answer: [] },
                { name: "SDH", answer: [] },
              ],
            },
            {
              name: "传输2",
              type: "cabinet",
              id: "cabinet_Ruijin_2",
              equipmentPic:"",
              checkboxAnswer: [
                { name: "OTN", answer: [] },
                { name: "PTN", answer: [] },
                { name: "SDH", answer: [] },
              ],
            },
          ],
          subpage_ODF: { name: "ODF架",miniName:["传输1","传输2"],
          transmissionArea:["",""],//随机出来的SC满配 FC满配
          opticalCableArea:["",opticalCableArea_Ruijin],//鼠标hover,展示
          type: "ODF", id: "ODF_Ruijinn_1",       answer: [{
              "transmissionType":"",//传输类型
              "jumpMachineRoom":"",//跳转机房
              "cableSelectItem":""//线缆选择
            }] },
        },
        {
          show: false,
          title: "请根据场景完成接入机房相关机柜基础配置。", //题干描述
          machineRoom_ID: "machineRoom_Jinggangshan", //机房ID
          machineRoom_Name: "井冈山", //机房名称
          machineRoom_Type: "接入机房", //机房类型:核心机房 汇聚机房 接入机房
          especially_page: [
            {
              name: "DDF",
              type: "DDF",
              id: "DDF_Jinggangshan_1",
              dec: DDF_Text,
            },
          ],
          subpage_cabinet: [
            // 传输
            {
              name: "传输1",
              type: "cabinet",
              id: "cabinet_Jinggangshan_1",
              equipmentPic:"",
              checkboxAnswer: [
                { name: "OTN", answer: [] },
                { name: "PTN", answer: [] },
                { name: "SDH", answer: [] },
              ],
            },
            {
              name: "传输2",
              type: "cabinet",
              id: "cabinet_Jinggangshan_2",
              equipmentPic:"",
              checkboxAnswer: [
                { name: "OTN", answer: [] },
                { name: "PTN", answer: [] },
                { name: "SDH", answer: [] },
              ],
            },
          ],
          subpage_ODF: { name: "ODF架",miniName:["传输1","传输2"],
          transmissionArea:["",""],//随机出来的SC满配 FC满配
          opticalCableArea:["",opticalCableArea_Jinggangshan],//鼠标hover,展示
          type: "ODF", id: "ODF_Jinggangshan_1", answer: [ {
              "transmissionType":"",//传输类型
              "jumpMachineRoom":"",//跳转机房
              "cableSelectItem":""//线缆选择
            }] },
        },
        {
          show: false,
          title: "请根据场景完成接入机房相关机柜基础配置。", //题干描述
          machineRoom_ID: "machineRoom_Xiangjiang", //机房ID
          machineRoom_Name: "湘江", //机房名称
          machineRoom_Type: "接入机房", //机房类型:核心机房 汇聚机房 接入机房
          especially_page: [
            {
              name: "DDF",
              type: "DDF",
              id: "DDF_Xiangjiang_1",
              dec: DDF_Text,
            },
          ],
          subpage_cabinet: [
            // 传输
            {
              name: "传输1",
              type: "cabinet",
              id: "cabinet_Xiangjiang_1",
              equipmentPic:"",
              checkboxAnswer: [
                { name: "OTN", answer: [] },
                { name: "PTN", answer: [] },
                { name: "SDH", answer: [] },
              ],
            },
            {
              name:"传输2",
              type: "cabinet",
              id: "cabinet_Xiangjiang_2",
              equipmentPic:"",
              checkboxAnswer: [
                { name: "OTN", answer: [] },
                { name: "PTN", answer: [] },
                { name: "SDH", answer: [] },
              ],
            },
          ],
          subpage_ODF:{ name: "ODF架",miniName:["传输1","传输2"],
          transmissionArea:["",""],//随机出来的SC满配 FC满配
          opticalCableArea: ["",opticalCableArea_Xiangjiang],//鼠标hover,展示
          type: "ODF", id: "ODF_Xiangjiang_1", answer: [ {
              "transmissionType":"",//传输类型
              "jumpMachineRoom":"",//跳转机房
              "cableSelectItem":""//线缆选择
            }] },
        },
      ],
      /* 第二模块 */
      /* 5GBBU配置界面/4GBBU配置界面/3GBBU配置界面/2GBBU配置界面 这四个弹框公用一套(2G 没有光模块) */
      /* DCPD配置界面 有tree结构，但参数不统一 */
      /* 空调配置/直流电源柜配置界面/蓄电池配置界面/交流柜配置界面 样式单一from表单*/
      formLabelWidth: "120px",
      formLabelWidth_EPC: "55px",
      currentPageID: "airConditioningConfiguration",
      core_currentPageID: "machineRoom_YanAn",
      machineRoom_dialogFormTitle: "基站机房",
      core_machineRoom_dialogFormTitle: "核心机房",
      core_machineRoom_dialogFormTitle_secondLevel: "传输1",
      machineRoom_dialogFormVisible: false,
      core_machineRoom_dialogFormVisible: false,
      core_machineRoom_dialogFormVisible_secondLevel: false,
      itemTitleContentShow: false,
      core_itemTitleContentShow: false,
      itemTitleContentShowIndex: "0",
      core_itemTitleContentShowIndex: 0,
      machineRoomData: [
        {
          num: 6,
          show: false,
          equipmentName: "equipment_6",
          name: "空调配置界面", //弹框标题
          pageID: "airConditioningConfiguration",
          title: "请根据背景信息的方法计算并选择。", //题干描述
          describe: {
            text: {
              title: "相关资料:",
              dec: describeAirConditioning,
            },
            textImgList: {
              title: "产品相关信息:",
              textImg: [],
            },
          }, //右上角按钮
          treeStructure: false, //是否有树结构
          imgStructure: true, //是否有左侧图解
          /* 有 tree结构 */
          /* "machineRoomTreeID":"0",
          "machineRoomTreeData": common_machineRoomTreeData, */
          imgPicName: "airConditioner.png",
          fromList: [
            [
              {
                type: "select",
                name: "制冷量",
                dataList: ["1P", "2P", "3P"],
                answer: "",
              },
              // {
              //   type: "select",
              //   name: "数量",
              //   dataList: ["1", "2", "3"],
              //   answer: "",
              // },
            ],
          ],
        },
        {
          num: 8,
          show: false,
          equipmentName: "equipment_8",
          name: "直流电源柜配置界面", //弹框标题
          pageID: "dcPowerCabinetConfiguration",
          title: "请根据场景选择合适的直流电源柜配置。",
          describe: {
            text: {
              title: "相关资料:",
              dec: describeDcPower,
            },
            textImgList: {
              title: "产品相关信息:",
              textImg: ["dcPower_textImg"],
            },
          }, //右上角按钮
          treeStructure: false, //是否有树结构
          imgStructure: true, //是否有左侧图解
          imgPicName: "cabinet.png",
          fromList: [
            [
              {
                type: "select",
                name: "电路1",
                dataList: ["负载电路", "交转直模块","交流输入"],
                answer: "",
              },
              {
                type: "select",
                name: "电路2",
                dataList: ["负载电路", "交转直模块","交流输入"],
                answer: "",
              },
              {
                type: "select",
                name: "电路3",
                dataList: ["负载电路", "交转直模块","交流输入"],
                answer: "",
              },
            ],
          ],
        },
        {
          num: 5,
          show: false,
          equipmentName: "equipment_5",
          name: "蓄电池配置界面", //弹框标题
          pageID: "batteryConfiguration",
          title: "基站机房配置蓄电池组以提供供电保护，请完成下述内容填写。", //题干描述
          describe: {
            text: {
              title: "相关资料:",
              dec: describeBattery,
            },
            textImgList: {
              title: "产品相关信息:",
              textImg: [],
            },
          }, //右上角按钮
          treeStructure: false, //是否有树结构
          imgStructure: false, //是否有左侧图解
          fromList: [
            [
              {
                type: "select",
                name: "单个蓄电池电压",
                dataList: ["1V", "2V", "3V", "4V"],
                answer: "",
              },
              {
                type: "select",
                name: "蓄电池数量",
                dataList: ["6", "12", "24", "48"],
                answer: "",
              },
            ],
          ],
        },
        {
          num: 7,
          show: false,
          equipmentName: "equipment_7",
          name: "交流电源柜配置界面", //弹框标题
          pageID: "acCabinetConfiguration",
          title: "请选择正确的配置", //题干描述
          describe: {
            text: {
              title: "相关资料:",
              dec: describeAcCabinet,
            },
            textImgList: {
              title: "产品相关信息:",
              textImg: [],
            },
          }, //右上角按钮
          treeStructure: false, //是否有树结构
          imgStructure: false, //是否有左侧图解
          fromList: [
            [
              {
                type: "select",
                name: "市电接入电缆",
                dataList: ["三相三线制", "三相四线制", "三相五线制"],
                answer: "",
              }
            ],
          ],
        },
        {
          num: 2,
          show: false,
          equipmentName: "equipment_2",
          name: "5G-BBU配置界面", //弹框标题
          pageID: "5G_BBU_Configuration",
          title: "完成5G-BBU设备配置", //题干描述
          describe: {
            text: {
              title: "相关资料:",
              dec: describe5G,
            },
            textImgList: {
              title: "产品相关信息:",
              textImg: ["5G_textImg"],
            },
          }, //右上角按钮
          treeStructure: true, //是否有树结构
          imgStructure: false, //是否有左侧图解
          /* 有 tree结构 */
          machineRoomTreeID: "0",
          machineRoomTreeData: common_machineRoomTreeData,
          // 一个数组代表一个分支page,子数组里面可以包含多条数据，每条数据里面可以多个答案
          fromList: [
            [
              {
                type: "select",
                name: "供电线缆选择",
                dataList: ["6平蓝黑电源线", "10平蓝黑电源线", "16平蓝黑电源线", "25平蓝黑电源线", "30平蓝黑电源线"],
                answer: "",
              },
            ],
            [
              {
                type: "select",
                name: "连接供电设备",
                dataList: ["DCPD", "AAU", "交流电源柜", "直流电源柜"],
                answer: "",
              },
            ],
            [
              {
                type: "radio",
                name: "光纤通信接头",
                dataList: ["LC-FC", "SC-SC", "FC-FC", "2M线", "FC-SC", "LC-LC"],
                imgSrcList: ["LC-FC", "SC-SC", "FC-FC", "2M_line", "FC-SC", "LC-LC"],
                answer: "",
              },
            ],
            [
              {
                type: "radio",
                name: "回传光模块",
                dataList: ["1.25G-1310nm-1.4KM-SM-SFP+",
                  "6G-1310nm-1.4KM-SM-SFP+","10G-1310nm-1.4KM-SM-SFP+","25G-1310nm-1.4KM-SM-SFP+",
                  "10000BASE-T RJ45 100M","10G-QSFP28 LR4 10km",
                  "QSFPDD-LR8-400G QSFPDD","10G-4*25-40km-1310nm-SM-CFP"
                ],
                imgSrcList: ["opticalModule1","opticalModule1","opticalModule1","opticalModule1",
                  "opticalModule2","opticalModule3","opticalModule4","opticalModule5",
                ],
                answer: "",
              },
            ],
            [
              {
                type: "radio",
                name: "前传光模块",
                 dataList: ["1.25G-1310nm-1.4KM-SM-SFP+",
                  "6G-1310nm-1.4KM-SM-SFP+","10G-1310nm-1.4KM-SM-SFP+","25G-1310nm-1.4KM-SM-SFP+",
                  "10000BASE-T RJ45 100M","10G-QSFP28 LR4 10km",
                  "QSFPDD-LR8-400G QSFPDD","10G-4*25-40km-1310nm-SM-CFP"
                ],
                imgSrcList: ["opticalModule1","opticalModule1","opticalModule1","opticalModule1",
                  "opticalModule2","opticalModule3","opticalModule4","opticalModule5",
                ],
                answer: "",
              },
            ],
          ],
        },
        {
          num: 9,
          show: false,
          equipmentName: "equipment_9",
          name: "4G-BBU配置界面", //弹框标题
          pageID: "4G_BBU_Configuration",
          title: "完成4G-BBU设备配置", //题干描述
          describe: {
            text: {
              title: "相关资料:",
              dec: describe4G,
            },
            textImgList: {
              title: "产品相关信息:",
              textImg: ["4G_textImg"],
            },
          }, //右上角按钮
          treeStructure: true, //是否有树结构
          imgStructure: false, //是否有左侧图解
          /* 有 tree结构 */
          machineRoomTreeID: "0",
          machineRoomTreeData: common_machineRoomTreeData,
          fromList: [
            [
              {
                type: "select",
                name: "供电线缆选择",
                dataList: ["6平蓝黑电源线", "10平蓝黑电源线", "16平蓝黑电源线", "25平蓝黑电源线", "30平蓝黑电源线"],
                answer: "",
              },
            ],
            [
              {
                type: "select",
                name: "连接供电设备",
                dataList: ["DCPD", "AAU", "交流电源柜", "直流电源柜"],
                answer: "",
              },
            ],
            [
              {
                type: "radio",
                name: "光纤通信接头",
                dataList: ["LC-FC", "SC-SC", "FC-FC", "2M线", "FC-SC", "LC-LC"],
                imgSrcList: ["LC-FC", "SC-SC", "FC-FC", "2M_line", "FC-SC", "LC-LC"],
                answer: "",
              },
            ],
            [
              {
                type: "radio",
                name: "回传光模块",
                dataList: ["1.25G-1310nm-1.4KM-SM-SFP+",
                  "6G-1310nm-1.4KM-SM-SFP+","10G-1310nm-1.4KM-SM-SFP+","25G-1310nm-1.4KM-SM-SFP+",
                  "10000BASE-T RJ45 100M","10G-QSFP28 LR4 10km",
                  "QSFPDD-LR8-400G QSFPDD","10G-4*25-40km-1310nm-SM-CFP"
                ],
                imgSrcList: ["opticalModule1","opticalModule1","opticalModule1","opticalModule1",
                  "opticalModule2","opticalModule3","opticalModule4","opticalModule5",
                ],
                answer: "",
              },
            ],
            [
              {
                type: "radio",
                name: "前传光模块",
                 dataList: ["1.25G-1310nm-1.4KM-SM-SFP+",
                  "6G-1310nm-1.4KM-SM-SFP+","10G-1310nm-1.4KM-SM-SFP+","25G-1310nm-1.4KM-SM-SFP+",
                  "10000BASE-T RJ45 100M","10G-QSFP28 LR4 10km",
                  "QSFPDD-LR8-400G QSFPDD","10G-4*25-40km-1310nm-SM-CFP"
                ],
                imgSrcList: ["opticalModule1","opticalModule1","opticalModule1","opticalModule1",
                  "opticalModule2","opticalModule3","opticalModule4","opticalModule5",
                ],
                answer: "",
              },
            ],
          ],
        },
        {
          num: 1,
          show: false,
          equipmentName: "equipment_1",
          name: "3G-BBU配置界面", //弹框标题
          pageID: "3G_BBU_Configuration",
          title: "完成3G-BBU设备配置", //题干描述
          describe: {
            text: {
              title: "相关资料:",
              dec: describe3G,
            },
            textImgList: {
              title: "产品相关信息:",
              textImg: ["3G_textImg"],
            },
          }, //右上角按钮
          treeStructure: true, //是否有树结构
          imgStructure: false, //是否有左侧图解
          /* 有 tree结构 */
          machineRoomTreeID: "0",
          machineRoomTreeData: common_machineRoomTreeData,
          fromList: [
            [
              {
                type: "select",
                name: "供电线缆选择",
                dataList: ["6平蓝黑电源线", "10平蓝黑电源线", "16平蓝黑电源线", "25平蓝黑电源线", "30平蓝黑电源线"],
                answer: "",
              },
            ],
            [
              {
                type: "select",
                name: "连接供电设备",
                dataList: ["DCPD", "AAU", "交流电源柜", "直流电源柜"],
                answer: "",
              },
            ],
            [
              {
                type: "radio",
                name: "光纤通信接头",
                dataList: ["LC-FC", "SC-SC", "FC-FC", "2M线", "FC-SC", "LC-LC"],
                imgSrcList: ["LC-FC", "SC-SC", "FC-FC", "2M_line", "FC-SC", "LC-LC"],
                answer: "",
              },
            ],
            [
              {
                type: "radio",
                name: "回传光模块",
                dataList: ["1.25G-1310nm-1.4KM-SM-SFP+",
                  "6G-1310nm-1.4KM-SM-SFP+","10G-1310nm-1.4KM-SM-SFP+","25G-1310nm-1.4KM-SM-SFP+",
                  "10000BASE-T RJ45 100M","10G-QSFP28 LR4 10km",
                  "QSFPDD-LR8-400G QSFPDD","10G-4*25-40km-1310nm-SM-CFP"
                ],
                imgSrcList: ["opticalModule1","opticalModule1","opticalModule1","opticalModule1",
                  "opticalModule2","opticalModule3","opticalModule4","opticalModule5",
                ],
                answer: "",
              },
            ],
            [
              {
                type: "radio",
                name: "前传光模块",
                 dataList: ["1.25G-1310nm-1.4KM-SM-SFP+",
                  "6G-1310nm-1.4KM-SM-SFP+","10G-1310nm-1.4KM-SM-SFP+","25G-1310nm-1.4KM-SM-SFP+",
                  "10000BASE-T RJ45 100M","10G-QSFP28 LR4 10km",
                  "QSFPDD-LR8-400G QSFPDD","10G-4*25-40km-1310nm-SM-CFP"
                ],
                imgSrcList: ["opticalModule1","opticalModule1","opticalModule1","opticalModule1",
                  "opticalModule2","opticalModule3","opticalModule4","opticalModule5",
                ],
                answer: "",
              },
            ],
          ],
        },
        {
          num: 3,
          show: false,
          equipmentName: "equipment_3",
          name: "2G-BTS配置界面", //弹框标题  特殊处理没有光模块
          pageID: "2G_BBU_Configuration",
          title: "请根据场景选择合适的2G-BTS配置界面。", //题干描述
          describe: {
            text: {
              title: "相关资料:",
              dec: describe2G,
            },
            textImgList: {
              title: "产品相关信息:",
              textImg: ["2G_textImg"],
            },
          }, //右上角按钮
          treeStructure: true, //是否有树结构
          imgStructure: false, //是否有左侧图解
          /* 有 tree结构 */
          machineRoomTreeID: "0",
          machineRoomTreeData: common_machineRoomTreeData_2G,
          fromList: [
            // 一个数组代表一个分支page,子数组里面可以包含多条数据，每条数据里面可以多个答案
            [
              {
                type: "select",
                name: "供电线缆选择",
                dataList: ["6平蓝黑电源线", "10平蓝黑电源线", "16平蓝黑电源线", "25平蓝黑电源线", "30平蓝黑电源线"],
                answer: "",
              },
            ],
            [
              {
                type: "select",
                name: "连接供电设备",
                dataList: ["BBU", "AAU", "直流电源柜", "交流电源柜"],
                answer: "",
              },
            ],
            [
              {
                type: "radio",
                name: "光纤通信接头",
                dataList: ["LC-FC", "SC-SC", "FC-FC", "2M线", "FC-SC", "LC-LC"],
                imgSrcList: ["LC-FC", "SC-SC", "FC-FC", "2M_line", "FC-SC", "LC-LC"],
                answer: "",
              },
            ],
          ],
        },
        {
          num: 4,
          show: false,
          equipmentName: "equipment_4",
          name: "DCPD配置界面", //弹框标题  特殊处理没有光模块
          pageID: "DCPD_Configuration",
          title: "请完成DCPD设备正确配置", //题干描述
          describe: {
            text: {
              title: "相关资料:",
              dec: describePDCP,

            },
            textImgList: {
              title: "产品相关信息:",
              textImg: ["DCPD_textImg"],
            },
          }, //右上角按钮
          treeStructure: true, //是否有树结构
          imgStructure: false, //是否有左侧图解
          /* 有 tree结构 */
          machineRoomTreeID: "0",
          machineRoomTreeData: common_machineRoomTreeData_DCPD,
          fromList: [
            // 一个数组代表一个分支page,子数组里面可以包含多条数据，每条数据里面可以多个答案
            [
              {
                type: "select",
                name: "供电线缆选择",
                dataList: ["6平蓝黑电源线", "10平蓝黑电源线", "16平蓝黑电源线", "25平蓝黑电源线", "30平蓝黑电源线"],
                answer: "",
              },
            ],
            [
              {
                type: "select",
                name: "连接供电设备",
                dataList: ["BBU", "AAU", "直流电源柜", "交流电源柜", "市电"],
                answer: "",
              },
            ],
          ],
        },
      ],
      timerShiny: null,
      /* 第一模块 */
      dialogFormTitle: "",
      engineeringItemList: ["信号塔", "基站机房", "传输&核心机房"],
      currentIndex: 0,
      showRobot: true,
      dialogFormVisible: false,
      /* 6个相同弹框  封装 */
      baseStationTowerName: [
        "三边形角钢塔",
        "单管塔",
        "支撑杆",
        "仿生树",
        "楼顶美化天线",
      ],
      baseStationTower_data: [
        { num: 1, show: false, correctAnswer: "三边形角钢塔", saveAnswer: "" }, //信号塔序号 ，下拉数据
        { num: 2, show: false, correctAnswer: "单管塔", saveAnswer: "" },
        { num: 3, show: false, correctAnswer: "支撑杆", saveAnswer: "" },
        { num: 4, show: false, correctAnswer: "仿生树", saveAnswer: "" },
        { num: 5, show: false, correctAnswer: "楼顶美化天线", saveAnswer: "" },
      ],
      treeID: "0",
      treeContentItemIndex: "0",
      treeData: [
        {
          label: "器件库",
          id: "-1",
          children: [
            { label: "GPS", id: "0" },
            { label: "RRU", id: "1" },
            { label: "连接头", id: "2" },
            { label: "天线", id: "3" },
            { label: "线缆", id: "4" },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      /* 初始化所有答案 */
      stationTowerAnswerList:[
        ['','','','',[]],
        ['','','','',[]],
        ['','','','',[]],
        ['','','','',[]],
        ['','','','',[]],
      ],
      baseStationTowerData: [
        {
          title: "GPS",
          name: "GPS",
          type:"radio",
          selectList: ["GPS_1", "GPS_2", "GPS_3", "GPS_4", "GPS_5", "GPS_6","GPS_7"], //img名字
          titleList: ["GPS_1", "GPS_2", "GPS_3", "GPS_4", "GPS_5", "GPS_6","不需要该器件"], //title名字
        },
        {
          title: "RRU",
          name: "RRU",
          type:"radio",
          selectList: ["RRU_1", "RRU_2", "RRU_3","RRU_1","RRU_4"],
          titleList: ["3GRRU", "5GAAU", "5GPRU","4GRRU","不需要该器件"],
        },
        {
          title: "连接线",
          name: "connector",
          type:"radio",
          selectList: ["connector_1","connector_2","connector_3","connector_4","connector_5","connector_5","connector_5","connector_5","connector_6"],
          titleList: ["NK—7/8L","NJ—7/8L","NK—1/2","NJ-1/2","1.25G-1310nm-1.4KM-SM-SFP+",
            "6G-1310nm-1.4KM-SM-SFP+","10G-1310nm-1.4KM-SM-SFP+","25G-1310nm-1.4KM-SM-SFP+","不需要该器件"]
        },
        {
          title: "天线",
          name: "antenna",
          type:"radio",
          selectList: ["antenna_1", "antenna_2", "antenna_3", "antenna_4","antenna_5"],
          titleList: ["2G天线", "3G天线", "4G天线", "射灯天线","不需要该器件"],
          msgList: [
            ["频率范围：790-880MHz  870-960MHz","极化方式：±45°","增益:14.5dbi",
            "电子下倾角：0、3、6、9可选类型","波束宽度：水平面65°、垂直面15°","旁瓣抑制：≥16 dB","前后比：>25dB",
            "驻波比：<1.5","隔离度：>28dB","输入阻抗：50Ω","功率容量：500W","接口类型：2×7/8DIN female",],
            [
"频率范围：1880-1920MHz  2010-2025MHz","电子下倾角范围：0/3/6/9°","电子下倾角精度：±1","极化方式：±45°","输入阻抗: 50Ω","功率容量：50W(单元端口)  2W(校准端口)","接口类型：9×N-Female","产品尺寸：1400×300×95mm","包装尺寸：1670×330×150mm",
            ],
            [
"频率范围：1710-1880MHz  1920-2170MHz  2500-2690 MHz","极化方式：±45°","增益:17.5dbi","电子下倾角：0-10°","驻波比：≤1.4","隔离度：≥28dB","三阶互调：<-107 dBm","输入阻抗：50Ω","功率容量：200W","接口类型：4×7/8DIN female",
            ],
            [
"频率范围：806~960MHz/1710~2700MHz","极化方式：垂直极化","增益：6 dBi/8 dBi","波束宽度：Hor50~70°、Ver60~85°/Hor50~70°、Ver55~70°","前后比：≥18dB","三阶互调：≤-85dBm","驻波比：≤1.5","输入阻抗：50Ω","功率容限：50W","接口类型：1* N-Female",
            ],
          ],
        },
        {
          title: "线缆",
          name: "cable",
          type:"checkbox",
          selectList: ["cable_1","cable_2","cable_3","cable_4","cable_5","cable_6"],
          titleList: ["7/8馈线","1/2馈线","1310 2LC-2LC","野战光缆","天馈跳线","850 2LC-2LC"],
        }
      ],
      property: "",
      overallIndex: 0,//线缆索引
      cableIndex: 0,
      robotTextTips_show:true,
    };
  },
  computed: {},
  watch: {},
  filters: {},
  created: function () {

  this.getTime  = getDayTimer(new Date())

    //随机出来的SC满配 FC满配
    if(this.coreMachineRoomData[0].subpage_ODF.transmissionArea[0] == ""){
      for(let i=0;i<this.coreMachineRoomData.length;i++){
        for(let s=0;s<this.coreMachineRoomData[i].subpage_ODF.transmissionArea.length;s++){
          if(this.coreMachineRoomData[i].subpage_ODF.transmissionArea[s] == ""){
            this.coreMachineRoomData[i].subpage_ODF.transmissionArea[s] = this.transmissionList[Math.floor(Math.random() * 2 + 0)];
          }
        }
        this.coreMachineRoomData[i].subpage_ODF.opticalCableArea[0] = this.transmissionList[Math.floor(Math.random() * 2 + 0)];
      }
    }
    /* 基站机房 */
    let that = this;
    this.timerShiny = setInterval(function () {
      that.machineRoomData.forEach(function (ele) {
        ele.show = !ele.show;
      });
    }, 500);

	setTimeout(function() {
		window.clearInterval(that.timerShiny);
		that.machineRoomData.forEach(function(ele) {
			ele.show = false;
		});
	}, 5000);

	},
		methods: {

			// 弹出窗的
			sunClick(){
				this.equipment_5GC_data.forEach((item,i)=>{
					item.correctAnswer.forEach((box,i)=>{
						let ok = item.answer.includes(box);
						if(ok){
							this.correctOk++;
						}
						
					})
				})
				this.mistakeErr = 12 - this.correctOk;
				
			},
			robotTextTipsFn() {
				this.robotTextTips_show = !this.robotTextTips_show;
			},
			// 
			/* 5GC设备 下落 <5GC>*/
        startEquipmentFall() {
				this.correctOk = 0;
				this.mistakeErr = 0;
				if (this.startEquipmentFallState) {
					let num = -1;
					let that = this;
					let fallArray = this.shuffleSelf(this.applicationScenario, 12);
					/* 清除数据 重新动画 */
					this.equipment_5GC_data.forEach(function(ele) {
						ele.answer = [];
					});
					this.startEquipmentFallState = false;
          animateFn();
					function animateFn() {
						$(".cloudBox").animate({
							top: "100%"
						}, 1500, "linear", function() {
							if (num <= 10) {
								num++;
								if(num >= 0){
									that.cloudBoxShowState = true;
									that.getStyles();
									that.cloudTextItem = fallArray[num];
									$(".cloudBox").css({
										top: "-10%"
									});
                    animateFn01();
								}
							} else {
								that.startEquipmentFallState = true;
								that.$confirm("答题结束！可再次点击“开始”按钮再来一次！", "提示", {
										confirmButtonText: "确定",
										showCancelButton: false,
									})
									.then((_) => {
										done();
									})
									.catch((_) => {});
							}
						});
					}
          function animateFn01() {
						$(".cloudBox").animate({
							top: "100%"
						}, 7000, "linear", function() {
							if (num <= 10) {
								num++;
								if(num >= 0){
									that.cloudBoxShowState = true;
									that.getStyles();
									that.cloudTextItem = fallArray[num];
                  setTimeout(function () {
									$(".cloudBox").css({
										top: "-10%"
									});
                    animateFn01();
                  }, 0);
								}
							} else {
								that.startEquipmentFallState = true;
								that.$confirm("答题结束！可再次点击“开始”按钮再来一次！", "提示", {
										confirmButtonText: "确定",
										showCancelButton: false,
									})
									.then((_) => {
										done();
									})
									.catch((_) => {});
							}
						});
					}
				} else {
					this.$confirm("当前答题未结束，请认真选择！", "提示", {
							confirmButtonText: "确定",
							showCancelButton: false,
						})
						.then((_) => {
							done();
						})
						.catch((_) => {});
				}
			},
			/* 点击设备，云朵消失 */
			sceneEquipmentSelect(sceneName, sceneIndex) {
				if (this.cloudBoxShowState) {
					this.equipment_5GC_data[sceneIndex].answer.push(this.cloudTextItem);
					this.cloudBoxShowState = false;
					//判断当前按钮集合中是否包含了这个选项，如果有就是正确 <5GC>
					// 判断选择的答案是否正确效果
					let offEquipment = this.equipment_5GC_data[sceneIndex].correctAnswer.includes(this.cloudTextItem);
					if(offEquipment){
						this.correctOk++;
						this.incoshow1 = true;
						setTimeout(()=> {
							this.incoshow1 = false;
						}, 2000);
					}else{
						this.mistakeErr++;
						this.incoshow2 = true;
						setTimeout(()=> {
							this.incoshow2 = false;
						}, 2000);
					}
				}
			},
			/* 随机变化 云朵大小，位置 */
			getStyles() {
				let cloudBoxStyle = [{
						width: "15%",
						left: "15%"
					},
					{
						width: "15%",
						left: "50%"
					},
					{
						width: "10%",
						left: "80%"
					},
				];
				return cloudBoxStyle[Math.floor(Math.random() * 3 + 0)];
			},
			/* Fisher–Yates shuffle 洗牌算法 随机5GC设备 场景元素数组 applicationScenario*/
			shuffleSelf(array, size) {
				var index = -1,length = array.length,lastIndex = length - 1;
				size = size === undefined ? length : size;
				while (++index < size) {
					var rand = index + Math.floor(Math.random() * (lastIndex - index + 1)),
						value = array[rand];
					array[rand] = array[index];
					array[index] = value;
				}
				array.length = size;
				return array;
			},
			/* 添加路线 */
			addRouteFn(index) {
				this.coreMachineRoomData[index].subpage_ODF.answer.push({
					"transmissionType": "", //传输类型
					"jumpMachineRoom": "", //跳转机房
					"cableSelectItem": "" //线缆选择
				});
			},
			checkAnswerData() {
				this.$nextTick(() => {
				  let sadata = "49"
				  this.saveData(sadata,0)
				});
				// /* 核心机房是1套题 可随机一套复合就行；汇聚，接入机房是2套题,题号必须对应*/
				// /* PPT 连线正确答案 */
				// let checkAnswerList = [{
				// 		machineRoom_ID: "machineRoom_YanAn",
				// 		machineRoomLine: [ //传输类型 跳转机房
				// 			[{
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "会宁机房"
				// 			}, {
				// 				transmissionType: "传输2",
				// 				jumpMachineRoom: "遵义机房"
				// 			}],
				// 			[{
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "遵义机房"
				// 			}, {
				// 				transmissionType: "传输2",
				// 				jumpMachineRoom: "会宁机房"
				// 			}],
				// 		],
				// 		correctState: [false, false],
				// 	},
				// 	{
				// 		machineRoom_ID: "machineRoom_Huining",
				// 		machineRoomLine: [ //传输类型 跳转机房
				// 			[{
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "延安机房"
				// 			}, {
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "遵义机房"
				// 			}],
				// 			[{
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "延安机房"
				// 			}, {
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "遵义机房"
				// 			}, {
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "瑞金机房"
				// 			}, {
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "井冈山机房"
				// 			}],
				// 		],
				// 		correctState: [false, false],
				// 	},
				// 	{
				// 		machineRoom_ID: "machineRoom_Zunyi",
				// 		machineRoomLine: [ //传输类型 跳转机房
				// 			[{
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "延安机房"
				// 			}, {
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "会宁机房"
				// 			}, {
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "瑞金机房"
				// 			}, {
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "湘江机房"
				// 			}],
				// 			[{
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "延安机房"
				// 			}, {
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "会宁机房"
				// 			}],
				// 		],
				// 		correctState: [false, false],
				// 	},
				// 	{
				// 		machineRoom_ID: "machineRoom_Ruijin",
				// 		machineRoomLine: [ //传输类型 跳转机房
				// 			[{
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "遵义机房"
				// 			}, {
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "井冈山机房"
				// 			}, {
				// 				transmissionType: "传输2",
				// 				jumpMachineRoom: "遵义机房"
				// 			}, {
				// 				transmissionType: "传输2",
				// 				jumpMachineRoom: "井冈山机房"
				// 			}],
				// 			[{
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "会宁机房"
				// 			}, {
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "湘江机房"
				// 			}, {
				// 				transmissionType: "传输2",
				// 				jumpMachineRoom: "会宁机房"
				// 			}, {
				// 				transmissionType: "传输2",
				// 				jumpMachineRoom: "湘江机房"
				// 			}],
				// 		],
				// 		correctState: [false, false],
				// 	},
				// 	{
				// 		machineRoom_ID: "machineRoom_Jinggangshan",
				// 		machineRoomLine: [ //传输类型 跳转机房
				// 			[{
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "瑞金机房"
				// 			}, {
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "湘江机房"
				// 			}, {
				// 				transmissionType: "传输2",
				// 				jumpMachineRoom: "瑞金机房"
				// 			}, {
				// 				transmissionType: "传输2",
				// 				jumpMachineRoom: "湘江机房"
				// 			}],
				// 			[{
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "会宁机房"
				// 			}, {
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "湘江机房"
				// 			}, {
				// 				transmissionType: "传输2",
				// 				jumpMachineRoom: "会宁机房"
				// 			}, {
				// 				transmissionType: "传输2",
				// 				jumpMachineRoom: "湘江机房"
				// 			}],
				// 		],
				// 		correctState: [false, false],
				// 	},
				// 	{
				// 		machineRoom_ID: "machineRoom_Xiangjiang",
				// 		machineRoomLine: [ //传输类型 跳转机房
				// 			[{
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "遵义机房"
				// 			}, {
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "井冈山机房"
				// 			}, {
				// 				transmissionType: "传输2",
				// 				jumpMachineRoom: "遵义机房"
				// 			}, {
				// 				transmissionType: "传输2",
				// 				jumpMachineRoom: "井冈山机房"
				// 			}],
				// 			[{
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "瑞金机房"
				// 			}, {
				// 				transmissionType: "传输1",
				// 				jumpMachineRoom: "井冈山机房"
				// 			}, {
				// 				transmissionType: "传输2",
				// 				jumpMachineRoom: "瑞金机房"
				// 			}, {
				// 				transmissionType: "传输2",
				// 				jumpMachineRoom: "井冈山机房"
				// 			}],
				// 		],
				// 		correctState: [false, false],
				// 	}
				// ];
        // console.log(this.coreMachineRoomData,'2233')

				// for (let i = 0; i < this.coreMachineRoomData.length; i++) {
				// 	let parData = this.coreMachineRoomData[i];
				// 	for (let z = 0; z < checkAnswerList.length; z++) {
				// 		if (parData.machineRoom_ID == checkAnswerList[z].machineRoom_ID && (parData.machineRoom_Type ==
				// 				"接入机房" || parData.machineRoom_Type == "核心机房")) {
				// 			/* 延安机房都可以，不需要对序号 */
				// 			/* 第一步：对随机出来的传输区和光缆区是否匹配 */
				// 			/* 传输 cableSelectItem  赋值 */
				// 			let randomArea1 = parData.subpage_ODF.transmissionArea[0];
				// 			let randomArea2 = parData.subpage_ODF.transmissionArea[1];
				// 			let randomArea3 = parData.subpage_ODF.opticalCableArea[0];
				// 			let transmission1 = "",
				// 				transmission2 = "";

				// 			if (randomArea1 == "FC") {
				// 				transmission1 = randomArea1 + "-" + randomArea3;
				// 			} else if (randomArea1 == "SC") {
				// 				transmission1 = randomArea3 + "-" + randomArea1;
				// 			}
				// 			if (randomArea2 == "FC") {
				// 				transmission2 = randomArea2 + "-" + randomArea3;
				// 			} else if (randomArea2 == "SC") {
				// 				transmission2 = randomArea3 + "-" + randomArea2;
				// 			}

				// 			for (let t = 0; t < checkAnswerList[z].machineRoomLine.length; t++) {
				// 				for (let h = 0; h < checkAnswerList[z].machineRoomLine[t].length; h++) {
				// 					if (checkAnswerList[z].machineRoomLine[t][h].transmissionType == "传输1") {
				// 						checkAnswerList[z].machineRoomLine[t][h].cableSelectItem = transmission1;
				// 					} else if (checkAnswerList[z].machineRoomLine[t][h].transmissionType == "传输2") {
				// 						checkAnswerList[z].machineRoomLine[t][h].cableSelectItem = transmission2;
				// 					}
				// 				}
				// 			}

				// 			/* 匹配正确答案 */
				// 			for (let n = 0; n < checkAnswerList[z].machineRoomLine.length; n++) {
				// 				if (parData.subpage_ODF.answer.length == checkAnswerList[z].machineRoomLine[n].length) {
				// 					for (let d = 0; d < JSON.parse(JSON.stringify(parData.subpage_ODF.answer))
				// 						.length; d++) {
				// 						if (JSON.stringify(checkAnswerList[z].machineRoomLine[n]).indexOf(JSON.stringify(
				// 								JSON.parse(JSON.stringify(parData.subpage_ODF.answer))[d])) > -1) {
				// 							checkAnswerList[z].correctState[n] = true;
				// 						} else {
				// 							checkAnswerList[z].correctState[n] = false;
				// 						}
				// 					}
				// 				} else {
				// 					checkAnswerList[z].correctState[n] = false;
				// 				}
				// 			}

				// 		} else if (parData.machineRoom_ID == checkAnswerList[z].machineRoom_ID && parData
				// 			.machineRoom_Type == "汇聚机房") {
				// 			/* 只有传输1， 且序号得和“接入机房” 得统一 */
				// 			/* 传输 cableSelectItem  赋值 */
				// 			let randomArea1 = parData.subpage_ODF.transmissionArea[0];
				// 			let randomArea3 = parData.subpage_ODF.opticalCableArea[0];
				// 			let transmission1 = "";

				// 			if (randomArea1 == "FC") {
				// 				transmission1 = randomArea1 + "-" + randomArea3;
				// 			} else if (randomArea1 == "SC") {
				// 				transmission1 = randomArea3 + "-" + randomArea1;
				// 			}

				// 			for (let t = 0; t < checkAnswerList[z].machineRoomLine.length; t++) {
				// 				for (let h = 0; h < checkAnswerList[z].machineRoomLine[t].length; h++) {
				// 					if (checkAnswerList[z].machineRoomLine[t][h].transmissionType == "传输1") {
				// 						checkAnswerList[z].machineRoomLine[t][h].cableSelectItem = transmission1;
				// 					}
				// 				}
				// 			}

				// 			/* 匹配正确答案 */
				// 			for (let n = 0; n < checkAnswerList[z].machineRoomLine.length; n++) {
				// 				if (parData.subpage_ODF.answer.length == checkAnswerList[z].machineRoomLine[n].length) {
				// 					for (let d = 0; d < JSON.parse(JSON.stringify(parData.subpage_ODF.answer))
				// 						.length; d++) {
				// 						if (JSON.stringify(checkAnswerList[z].machineRoomLine[n]).indexOf(JSON.stringify(
				// 								JSON.parse(JSON.stringify(parData.subpage_ODF.answer))[d])) > -1) {
				// 							checkAnswerList[z].correctState[n] = true;
				// 						} else {
				// 							checkAnswerList[z].correctState[n] = false;
				// 						}
				// 					}
				// 				} else {
				// 					checkAnswerList[z].correctState[n] = false;
				// 				}
				// 			}
				// 		}

				// 	}
				// }

				// /* 答案判断 */
				// let resultState = false,
				// 	resultState1 = false,
				// 	resultState2 = false;
        //   console.log(checkAnswerList,"22")
				// for (let z = 0; z < checkAnswerList.length; z++) {
				// 	if (checkAnswerList[z].machineRoom_ID == "machineRoom_YanAn") {
        //     console.log(checkAnswerList[z].correctState[0] , checkAnswerList[z].correctState[1])
				// 		if (checkAnswerList[z].correctState[0] || checkAnswerList[z].correctState[1]) {
				// 			resultState = true;
        //       console.log("1")
				// 		} else {
				// 			resultState = false;
        //       console.log("1")
				// 		}
				// 	}
				// }

				// for (let z = 0; z < checkAnswerList.length; z++) {
				// 	if (checkAnswerList[z].machineRoom_ID == "machineRoom_YanAn") {} else {
				// 		if (checkAnswerList[z].correctState[0]) {
				// 			resultState1 = true;
				// 		} else {
				// 			resultState1 = false;
				// 			break;
				// 		}
				// 	}
				// }

				// for (let z = 0; z < checkAnswerList.length; z++) {
				// 	if (checkAnswerList[z].machineRoom_ID == "machineRoom_YanAn") {} else {
				// 		if (checkAnswerList[z].correctState[1]) {
				// 			resultState2 = true;
				// 		} else {
				// 			resultState2 = false;
				// 			break;
				// 		}
				// 	}
				// }


        // console.log(resultState,"1")
        // console.log(resultState1,"2")
        // console.log(resultState2,"3")

        // $(".linrItem").hide();
        // if(resultState && resultState1){//第一套
        //   $(".linrItem_1,.linrItem_2,.linrItem_3,.linrItem_4,.linrItem_5,.linrItem_7,.linrItem_8").show();
        // }else if(resultState && resultState2){//第二套
        //   $(".linrItem_1,.linrItem_2,.linrItem_3,.linrItem_6,.linrItem_7,.linrItem_9,.linrItem_10").show();
        // }else{
          // this.$confirm('校验失败！','提示',{
          //   confirmButtonText: '确定',
          //   showCancelButton: false,
          // }).then(_ => {
          //     done();
          // }).catch(_ => {});
        // }

        setTimeout(() => {
        let dataobj1 = new FormData();
        dataobj1.append('user_id',this.$store.state.user_id);
        dataobj1.append('value_sign','signal_tower');
        dataobj1.append('task_id',this.$store.state.taskId);
        dataobj1.append('template_id',this.$store.state.template_id);
        Engineering(dataobj1).then((res)=>{
          this.tipsObj = res.data.ret_data.ret_msg
      })

        let dataobj2 = new FormData();
        dataobj2.append('user_id',this.$store.state.user_id);
        dataobj2.append('value_sign','base_station_room');
        dataobj2.append('task_id',this.$store.state.taskId);
        dataobj2.append('template_id',this.$store.state.template_id);
          Engineering(dataobj2).then((res)=>{
            this.tipsObj2 = res.data.ret_data.ret_msg
        })

        let dataobj3 = new FormData();
        dataobj3.append('user_id',this.$store.state.user_id);
        dataobj3.append('value_sign','transmission_core_room');
        dataobj3.append('task_id',this.$store.state.taskId);
        dataobj3.append('template_id',this.$store.state.template_id);
        Engineering(dataobj3).then((res)=>{
          this.tipsObj3 = res.data.ret_data.ret_msg
          // $(".winBox").css({ "display": 'block' });
		  this.showOff = true
          this.showdata = false
          setTimeout(() => {
          this.showdata = true
        }, 200);


        if(res.data.ret_data.ret_sign.hui_ning_room==true &&
        res.data.ret_data.ret_sign.jing_gang_shan_room==true &&
        res.data.ret_data.ret_sign.rui_jin_room ==true &&
        res.data.ret_data.ret_sign.xiang_jiang_room ==true &&
        res.data.ret_data.ret_sign.yan_an_room ==true &&
        res.data.ret_data.ret_sign.zun_yi_room ==true
        ){
          if( this.randomTextID == 0){
			this.lineone = true
          }else {
            this.linetwo = true
          }
        }else{
			this.lineone = false
			this.linetwo = false
		}
      })

    }, 500);
      // console.log(this.tipsObj)
    },
    cableSelectBtn(parIndex,curIndex,curValue) {
      this.overallIndex = parIndex;
      this.cableIndex = curIndex;
      this.cableSelectBoxState = true;
      this.cableValue = curValue;
    },
    /* 线缆选择 删除 */
    handleDelete(parIndex,curIndex) {
      this.$confirm("确定删除？",'提示', {
        confirmButtonText: "确定",
        showCancelButton: false,
      }).then(async () => {
        this.coreMachineRoomData[parIndex].subpage_ODF.answer.splice(curIndex, 1);
      });
    },
    /* 线缆选择框 取消 */
    cableSelectBoxState_Cancel(){
      this.coreMachineRoomData[this.overallIndex].subpage_ODF.answer[this.cableIndex].cableSelectItem = "";
      this.cableSelectBoxState = false;
    },
    /* 线缆选择框 确定 赋值 */
    cableSelectBoxState_Determine(){
      this.coreMachineRoomData[this.overallIndex].subpage_ODF.answer[this.cableIndex].cableSelectItem = this.cableValue;
      this.cableSelectBoxState = false;
    },
    itemTitleContent() {
      this.itemTitleContentShow = !this.itemTitleContentShow;
    },
    core_itemTitleContent() {
      this.core_itemTitleContentShow = !this.core_itemTitleContentShow;
    },
    equipmentMouseover(indexFlag) {
      //移进显示
      this.machineRoomData.forEach(function (ele) {
        ele.show = false;
      });
      this.machineRoomData[indexFlag].show = true;
    },
    coreEquipmentMouseover(indexFlag) {
      //移进显示
      this.coreMachineRoomData.forEach(function (ele) {
        ele.show = false;
      });
      this.coreMachineRoomData[indexFlag].show = true;
    },
    equipmentParameterConfig(equipmentIndex, correctEquipmentName, pageNameID) {
      this.machineRoom_dialogFormTitle = correctEquipmentName;
      this.machineRoom_dialogFormVisible = true;
      this.currentPageID = pageNameID;
      this.treeContentItemIndex = "0";
      this.itemTitleContentShowIndex = equipmentIndex;
    },
    coreEquipmentParameterConfig(equipmentIndex,correctEquipmentName,pageNameID) {

      this.core_machineRoom_dialogFormTitle = correctEquipmentName;
      this.core_machineRoom_dialogFormVisible = true;
      this.core_currentPageID = pageNameID;
      this.core_itemTitleContentShowIndex = parseFloat(equipmentIndex);
    },
    core_subpagePage(index, name, type, id, twoIndex) {
      this.core_machineRoom_dialogFormVisible_secondLevel = true;
      this.core_machineRoom_dialogFormTitle_secondLevel = name;
      this.core_currentPageID_secondLevel = id;
      // this.checkboxPageIndex = "0";
	  // 回显内容时根据选择当前的内容加载对应下的选项
		this.$nextTick(()=>{
			try{
				let domText = document.querySelector(".pic" +  id + twoIndex).innerText// 一个dom元素
				switch (domText){
					case "OTN":
						this.checkboxPageIndex = "0"
						break;
					case "PTN":
						this.checkboxPageIndex = "1"
						break;
					case "SDH":
						this.checkboxPageIndex = "2"
						break;
				}
			}catch(e){
				//TODO handle the exception
			}


		})
    },
    /* 点击tree 切换页面 */
    handleNodeClick(data) {
      if (data.id == "-1") {
        this.treeID = "0";
      } else {
        this.treeID = data.id;
      }
    },
    /* 点击tree 切换页面 */
    machineRoomHandleNodeClick(data) {
      if (data.id == "-1") {
        this.treeContentItemIndex = "0";
      } else {
        this.treeContentItemIndex = data.id;
      }
    },
    /* 信号塔配置 */
    parameterConfig(towerIndex, correctTowerName) {
      if (this.baseStationTower_data[towerIndex].saveAnswer != "") {
        this.oneLevelTowerIndex = parseFloat(towerIndex);
        this.dialogFormVisible = true;
        this.dialogFormTitle = this.baseStationTower_data[towerIndex].saveAnswer;
        this.treeID = "0"; // tree 重置
        let descripList = [
          {"title":"支撑杆","descrip":"请完成GPS时钟同步系统室外部分器件选择。"},
          {"title":"单管塔","descrip":"请完成2G室外天馈系统和射频设备器件选择。"},
          {"title":"楼顶美化天线","descrip":"请完成3G室外天馈系统和射频设备器件选择。"},
          {"title":"三边形角钢塔","descrip":"请完成4G室外天馈系统和射频设备器件选择。"},
          {"title":"仿生树","descrip":"请完成5G室外天馈系统和射频设备器件选择。"}]
        for(let i=0;i<descripList.length;i++){
          if(descripList[i].title == this.baseStationTower_data[towerIndex].saveAnswer){
            this.stemDescriptionText = " "+descripList[i].descrip;
          }
        }

      } else {
        this.$confirm("请先选择信号塔匹配的名称！", "提示", {
          confirmButtonText: "确定",
          showCancelButton: false,
        })
          .then((_) => {
            done();
          })
          .catch((_) => {});
      }
    },
    dialogFormVisibleHandleClose() {
		this.dialogFormVisible = false;
	},
    machineRoom_dialogFormVisibleHandleClose() {
      this.machineRoom_dialogFormVisible = false;
    },
    core_machineRoom_dialogFormVisibleHandleClose() {
      this.core_machineRoom_dialogFormVisible = false;
    },
    core_machineRoom_dialogFormVisibleHandleClose_secondLevel() {
      this.core_machineRoom_dialogFormVisible_secondLevel = false;
    },
    mouseover(indexFlag) {
      //移进显示
      this.baseStationTower_data.forEach(function (ele) {
           ele.show = false;
      });
      this.baseStationTower_data[indexFlag].show = true;
    },
    mouseoverG(indexFlag){
    },
    /* 小唐人提示 */
    GC_logo(){
      this.Box_5GCshow = !this.Box_5GCshow
    },
    showOffclick(){
          // $(".winBox").css({ "display": 'none' });
		  this.showOff = false
    },
    showAlert() {
      this.showRobot = !this.showRobot;
    },
    //返回
    backStep() {
		this.saveData();
      this.$router.push({ name: "CognitionView" });
    },
	getProgressBar(){
		// debugger
		this.progressBar();
		this.saveData();
	},
	//进度条动画
	progressBar(){
		if (this.sweepingRobotProgressBarName == 0 && this.sweepingRobotProgressBarName <= 110) {
			this.sweepingrobotTimer = setInterval(() => {
				this.sweepingRobotProgressBarName += 5;
			}, 100);
		}
		setInterval(() => {
			if (this.sweepingRobotProgressBarName >= 105) {
				clearInterval(this.sweepingrobotTimer);
				this.sweepingRobotProgressBarName = 0;
			}
		}, 100);
	},
	getSave() {

		let dataobj = new FormData();
		dataobj.append("answer_data", sessionStorage.getItem("answer_data1"));
		dataobj.append('user_id',this.$store.state.user_id);
		dataobj.append('data_sign','engineering_practice');
		dataobj.append('task_id',this.$store.state.taskId);
		dataobj.append('template_id',this.$store.state.template_id);
		telecommunicationServiceSave(dataobj).then((res)=>{})
	},
    //保存数据
    saveData(val,i) {
		let answer_data = {
			"baseStationTower_data":this.baseStationTower_data,//信号塔
			"stationTowerAnswerList":this.stationTowerAnswerList,//弹框答案
			"machineRoomData":this.machineRoomData,//基站机房
			"coreMachineRoomData":this.coreMachineRoomData,//核心机房
			"EPC_select_data":this.EPC_select_data,//核心机房
			"5GC_select_data":this.equipment_5GC_data,//延安机房5GC数据
			"GC5_select_data":this.equipment_5GC_data,//延安机房5GC数据
			"randomTextID":this.randomTextID, //问题的随机0/1的题
		};

		sessionStorage.setItem("answer_data1",JSON.stringify(answer_data))
		let dataobj = new FormData();
		dataobj.append("answer_data", sessionStorage.getItem("answer_data1"));
		dataobj.append('user_id',this.$store.state.user_id);
		dataobj.append('data_sign','engineering_practice');
		dataobj.append('task_id',this.$store.state.taskId);
		dataobj.append('template_id',this.$store.state.template_id);
		telecommunicationServiceSave(dataobj).then((res)=>{
			if(val != "49"){
				if(res.data.code == 200 && i == 1){
					this.$message({
					  message: '保存成功',
					  type: 'success'
					});
				}
			}
		})
    },
    changeTab(index) {
      this.currentIndex = index;
	   this.saveData()
      if(index == "0"){
        this.robotTipsText = robotTipsTextAry[0];
      }else if(index == "1"){
        this.robotTipsText = robotTipsTextAry[1];
      }else if(index == "2"){
        this.robotTipsText = robotTipsTextAry[2];
      }
      this.robotTextTips_show = true;
    },
    // 信号塔校验
    signal_tower(){
        let dataobj = new FormData();
        dataobj.append('user_id',this.$store.state.user_id);
        dataobj.append('value_sign','signal_tower');
        dataobj.append('task_id',this.$store.state.taskId);
        dataobj.append('template_id',this.$store.state.template_id);
        Engineering(dataobj).then((res)=>{
          this.tipsObj = res.data.ret_data.ret_msg;
					this.showOff = true;
      })
    },
    // 基站机房校验
    basestation_room(){
        let dataobj = new FormData();
        dataobj.append('user_id',this.$store.state.user_id);
        dataobj.append('value_sign','base_station_room');
        dataobj.append('task_id',this.$store.state.taskId);
        dataobj.append('template_id',this.$store.state.template_id);
        Engineering(dataobj).then((res)=>{
          this.tipsObj = res.data.ret_data.ret_msg;
					this.showOff = true;
      })
    }
  },
  mounted() {
    /* 数据回显 */
    let dataobj = new FormData();
    dataobj.append('user_id',this.$store.state.user_id);
    dataobj.append('data_sign','engineering_practice');
    dataobj.append('value_sign','all');
    dataobj.append('task_id',this.$store.state.taskId);
    dataobj.append('template_id',this.$store.state.template_id);
    let that = this
    telecommunicationServiceEcho(dataobj).then((res)=>{
      if(res.data.code == 200 && res.data.data != "null"){
        let resultData = JSON.parse(res.data.data);
        that.baseStationTower_data = resultData.baseStationTower_data;//信号塔
        that.stationTowerAnswerList = resultData.stationTowerAnswerList;//信号塔
        that.machineRoomData = resultData.machineRoomData;//基站机房
        that.coreMachineRoomData = resultData.coreMachineRoomData;//核心机房
        that.EPC_select_data = resultData.EPC_select_data;//EPC 数据
		that.randomTextID = resultData.randomTextID;
		
		that.equipment_5GC_data = resultData.GC5_select_data;//延安机房5GC数据
		console.log(that.equipment_5GC_data);
		this.sunClick()
      }

	  if(this.randomTextID == null){
	  	this.randomTextID = randomTextIndex
	  }
    })


  },
  beforeDestroy() {},
};
</script>


<style lang="scss" scope>
	.checkClassName {
    float: right;
      width: 10%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 5px;
      color: #FFF;
      background: linear-gradient(to bottom, #C2491F 0%, #E17118 100%);
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 2px;
      margin-right: 10px;
	}
	.box{
		width: 100%;
		background-image: url("../assets/img/imgHomePage/give.png");
		background-repeat: no-repeat;
		border-bottom: 2px solid #eee;
		/* background-image:url(../assets/img/imgHomePage/give.png) cover; */
		.header{
			width: 23px;
			height: 23px;
			cursor: pointer;
			z-index: 99;
			margin-top: 15px;
			img{
				width: 20px;
				height: 20px;
				position: absolute;
				top: 10px;
				right: 5px;
			}
		}
	}
	.winBox {
		display: flex;
		flex-direction: column;
	    width: 22%;
	    height: 42%;
	    position: absolute;
	    left: 3%;
	    top: 13%;
	    font-size: 14px;
		border: solid 2px #007cc2;
		background: #fff;
		z-index: 999;

	}
	.conter{
		overflow-y:auto;
		p{
			margin: 10px 26px;
			font-size: calc(100vw * 16 / 1920);
		}
	}
	.colorRed{
		color:red;
	}
	.el-progress-bar__outer {
		background: #c2491f;
		transform: rotate(150deg);
	}
  .scrollbar-demo-item{
    font-size: calc(100vw * 16 / 1920);
    line-height: 30px;
    text-align: left;

  }

	.el-progress__text {
		display: none;
	}
	/* 滚动条样式 */
	div::-webkit-scrollbar {
	  height: 6px;
	  width: 4px;
	}
	div::-webkit-scrollbar-thumb {
	  border-radius: 6px;
	  background: #667bb4;
	}
	div::-webkit-scrollbar-track {
	  background: skyblue;
	}

  .Robot_tips2 {
    position: absolute;
    top: 10%;
    left: 11%;
    z-index: 100;
    display: inline-block;
    width: 24%;
    line-height: 20px;
    background-color: #D7EDFB;
    border-radius: 5px;
    border: 1px solid #EEE;
    padding: 10px;
    font-size: 14px;
    letter-spacing: 1px;
  }
  .cup4_row{
    position: absolute;
    left: 74px;
    top: 60px;
    width: 0px;
    height: 0px;
    border-top: 10px solid #D7EDFB;
    border-left: 10px solid transparent;
    border-right: 10px solid #D7EDFB;
    border-bottom: 10px solid transparent;
  }
  .incoimg{
		position: fixed;
		top: 45%;
		left: 44%;
		width: 15%;
		z-index: 9999;
		animation: example 2.1s linear forwards;
  }
  // 图标展示区域
  .incobox {
	width: 11%;
	height: 20%;
	position: absolute;
	background: #d2d3d8;
	top: 5%;
	right: 3%;
	border-radius: 10px;
	opacity: 0.8;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	div{
		margin: 10px;
		img{
			width: 25px;
		}
		.txt{
			position: relative;
			top: -6px;
			left: 3px;
			.txtBos{
				font-size: 24px;
				color: green;
			}
			.txtBosErr{
				font-size: 24px;
				color: red;
			}
		}
	}

  }
  .incobox > span {
    position: absolute;
    left: 50%;
  }
  @keyframes example {
    0% {
      opacity: 0;
    }
	50%{
		 opacity: 1;
	}
    100% {
      opacity: 0;
    }
  }

  @import "../assets/css/dialogForm.scss";
	@import "../assets/css/engineeringPractice/EngineeringView.scss";
</style>

<style>
.ODF_tableStyle .el-input__wrapper {
    width: 100px;
}
</style>
