<template>
  <div class="cognition">
    <img alt="Vue logo" src="../assets/img/imgHomePage/home.png" />
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="step step1" v-if="step1" @click="chuangLBtn"></div>
    <div class="step step2" v-if="step2" @click="chuangRBtn"></div>
    <!-- <router-link to="/Intelligent" class="step step2" tag="button" >Intelligent</router-link> -->
    <div class="step step3" v-if="step3" @click="toTelecomPage"></div>
    <div class="step step4" v-if="step4" @click="toEngineeringPractice"></div>
  </div>
      <img class="flag1" v-if="flag1"  src="../assets/img/imgdevelopment/flag.png" alt="">
      <img class="flag2" v-if="flag2"  src="../assets/img/imgdevelopment/flag.png" alt="">
      <img class="flag3" v-if="flag3"  src="../assets/img/imgdevelopment/flag.png" alt="">
      <img class="flag4" v-if="flag4"  src="../assets/img/imgdevelopment/flag.png" alt="">

</template>

<script >
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { PageDispense } from "../api/topics.js";
export default {
  data() {
    return {
      diask: sessionStorage.getItem("diask"),
      templete_name:null,
      step1:false,
      step2:false,
      step3:false,
      step4:false,
      flag1:true,
      flag2:true,
      flag3:true,
      flag4:true,
    };
  },
  // name: "CognitionView",
  components: {
    // HelloWorld
  },
  created(){
    let taskId = this.$store.state.taskId;
    let taskInfoName = this.$store.state.template_name;
    // let taskId='qqq';
    // let taskInfoName='通信网络认知-人类通信发展简史';
    let dataPageDispense = {  taskId, taskInfoName};
    if( taskInfoName){
      PageDispense(dataPageDispense).then((res) => {
      this.templete_name = JSON.parse(res.data.taskInfo).templete_name
        if(this.templete_name == "通信网络认知-人类通信发展简史"){
          this.step1 = true;
          this.step2 = false;
          this.step3 = false;
          this.step4 = false;
          this.flag1 = true;   
          this.flag2 = true;      
          this.flag3 = true;
          this.flag4 = false;
        }else if (this.templete_name == "通信网络认知-电信业务"){
          this.step1 = false;
          this.step2 = false;
          this.step3 = true;
          this.step4 = false;
          this.flag1 = true;
          this.flag2 = false;
          this.flag3 = true;
          this.flag4 = true;
        }else if (this.templete_name == "通信网络认知-智能通信系统"){
          this.step1 = false;
          this.step2 = true;
          this.step3 = false;
          this.step4 = false;
          this.flag1 = true;
          this.flag2 = true;
          this.flag3 = false;
          this.flag4 = true;
        }else if (this.templete_name == "通信网络认知-工程实践"){
          this.step1 = false;
          this.step2 = false;
          this.step3 = false;
          this.step4 = true;
          this.flag4 = true;
          this.flag2 = true;
          this.flag3 = true;
          this.flag1 = false;
        }else if(this.templete_name == "通信网络认知"){
          this.step1 = true;
          this.step2 = true;
          this.step3 = true;
          this.step4 = true;
          this.flag1 = false;
          this.flag2 = false;
          this.flag3 = false;
          this.flag4 = false;
        }
      });
    }else{
      // window.location.href="http://www.baidu.com" 
      throw '错误'
    }

  },

  methods: {
    toTelecomPage() {
      this.$router.push({ name: "TelecomView" });
    },
    toEngineeringPractice() {
      this.$router.push({ name: "EngineeringView" });
    },
    chuangRBtn() {
      this.$router.push({ name: "Intelligent" });
    },
    chuangLBtn() {
      this.$router.push({ name: "ancientunication" });
      if (this.diask == 'true') {
        return;
      }
      this.$store.commit("AggregateData");
      this.$store.commit("CommunicationData");
      this.$store.commit("ModernData");
      sessionStorage.setItem("diask", 'true');
    },
    EntryBtn() {
      this.$router.push({ name: "EngineeringPractice" });
    },
  },
  mounted() {
    localStorage.setItem("iserr", 0);
  },
};
</script>

<style lang="scss" scope>
.cognition {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  img {
    display: block;
    display: block;
    width: 100%;
    height: 100%;
  }
  .step {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    left: 0px;
    top: 0px;
    cursor: pointer;
  }
  .step1 {
    top: 68%;
    left: 48%;
  }
  .step2 {
    top: 55%;
    left: 7%;
  }
  .step3 {
    top: 32%;
    left: 12%;
  }
  .step4 {
    top: 7%;
    left: 31%;
  }
}
.flag1{
  position: absolute;
  width: 7%;
  top: 2%;
  left: 33.2%;
}
  .flag2{
  position: absolute;
  width: 7%;
  top: 27.5%;
  left: 11%;
}
  .flag3{
  position: absolute;
  width: 7%;
  top: 56.5%;
  left: 10.5%;
}
  .flag4{
  position: absolute;
  width: 7%;
  top: 64%;
  left: 48.2%;
}
</style>
