<template>
  <div>
    <!-- 导航 -->
    <div class="flexbtw" style="position: relative; top: 18px">
      <div class="flexbtw boxW">
        <div
          class="ItemWidth"
          v-for="(i, index) in listTab"
          :key="index"
          @click="isShowBtn(index)"
          :class="{ active: isActive == index }"
        >
          <span>{{ i }}</span>
        </div>
      </div>
      <img
        class="returnStyle"
        @click="returnBtn"
        src="../../../assets/img/imgdevelopment/return.png"
        alt=""
      />
      <img
        class="keepStyle"
        @click="keepBtn(1)"
        src="../../../assets/img/imgdevelopment/keep.png"
        alt=""
      />
    </div>
    <div class="backFd">
      <!-- <img
        class="bgPic"
        alt="底图"
        src="../../../assets/img/businessProcess/bgPic.jpg"
      /> -->
      <!-- 远古通信 -->

      <ancient v-if="isActive == 0"></ancient>
      <!-- 原始通信 -->
      <original_my v-if="isActive == 1"></original_my>
      <!-- 古代通信 -->
      <gudaipage_my v-if="isActive == 2"></gudaipage_my>
      <!-- 近代通信 -->
      <modern_my v-if="isActive == 3"></modern_my>
      <!-- 近现代通信 -->
      <modernTimes_my v-if="isActive == 4"></modernTimes_my>
      <!-- 现代通信 -->
      <xiandTongx_my v-if="isActive == 5"></xiandTongx_my>
    </div>
    <img
      class="bgPic"
      alt="底图"
      src="../../../assets/img/imgdevelopment/overallbackground.jpg"
    />
  </div>
</template>
<script>
import ancient from "./ancient";
import original_my from "./original";
import gudaipage_my from "./ancientpage";
import modern_my from "./modern";
import modernTimes_my from "./modernTimes";
import xiandTongx_my from "./modernCommuni";
export default {
  data() {
    return {
      listTab: [
        "远古通信",
        "原始通信",
        "古代通信",
        "近代通信",
        "近现代通信",
        "现代通信",
      ],
      isActive: 0,
    };
  },

  components: {
    ancient,
    original_my,
    gudaipage_my,
    modern_my,
    modernTimes_my,
    xiandTongx_my,
  },

  methods: {
    isShowBtn(index) {
      this.isActive = index;
      localStorage.setItem("iserr", index);
    },
    returnBtn() {
      this.$router.push({ name: "CognitionView" });
      localStorage.setItem("iserr", 0);
    },
    //保存数据
    // 调用VUEX保存数据
    keepBtn(i) {
      this.$store.commit("sum_communication");
      // console.log(this.$store.state.code);
      setTimeout(() => {
        if (this.$store.state.code == 200 && i == 1) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        }
      }, 1000);
    },
  },
  mounted() {
    let asd = localStorage.getItem("iserr");
    // console.log(asd);
    this.isActive = asd;
  },
};
</script>
<style  scoped lang="scss">
@import "../../../assets/css/ancientunication.css";
</style>
