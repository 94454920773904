<template>
  <!-- 最外层的div -->
  <div class="basemap" ref="back_box" style="width: 100%">
    <!-- 底图 -->
    <div class="baseimg">
      <img
        class="baseimg"
        src="../../../assets/img/imgdevelopment/original/basemap.jpg"
        alt=""
        draggable="false"
      />
      <!-- 资料库 -->
      <!-- 资料库元素图片 -->
      <img
        v-for="(item, index) in pictureArray"
        :key="item.key"
        class="drag_box"
        draggable="true"
        @dragstart="dragstart($event, index, item.id)"
        @dragend="dragend($event, index, item.id)"
        :style="{ left: item.left, top: item.top }"
        style="position: absolute"
        :src="item.imgsrc"
        alt=""
      />
      <!-- 拖拽元素盒子 -->
      <div class="deldeteclaAll">
        <div
          class="Box"
          v-for="(item, index) in picturelist"
          @dragover="allowDrop($event, index)"
          @drop="drop($event, index)"
          :key="index"
          :style="{ left: item.left, top: item.top }"
        >
          <img
            class="deldetecla"
            @click="delteCliks(index)"
            :src="item.img"
            ref="code"
          />
        </div>
      </div>
    </div>
    <div>
      <!-- 小唐人logo -->
      <img
        class="dialong3"
        @click="showif"
        src="../../../assets/img/imgdevelopment/logo.png"
        draggable="false"
        alt=""
      />
      <!-- 小唐人弹出框 -->
      <div
        class="logodialogue"
        v-show="dialogVisible"
        ref="list"
        style="opacity: 1; display: block"
      >
        <span>
          燧人氏任命小唐为部落祭司负责记录部落生活中的重要事件。于是小唐将绳子按照颜色和物体数量进行部落生活的记录。请将如下重要事件正确记录下来，并思考原始通信的优缺点：
          <p>
            1.为了生存，全体部落成员团结一致，猎取飞禽，愉快的分享共同创造的捕猎红利。<span
              style="color: red"
              >{{ dialog1 }}</span
            >；
          </p>
          <p>
            2.燧明部落最漂亮的女儿出嫁，洞房花烛之夜，男女双方各取一撮黑色长发相结，以誓爱情永恒，小唐记录下了这个神圣的纪念日：7月<span
              style="color: red"
              >{{ dialog2 }}</span
            >；
          </p>

          <p>
            3.燧明部落与其他部落结盟后，祭司小唐用长结、单结等方式记录下了中华文明发祥地的经纬坐标<span
              style="color: red"
              >{{ dialog3 }}</span
            >孕育出炎黄子孙的摇篮。
          </p>
        </span>
        <div class="logodialoguex"></div>
      </div>
      <!-- 结束语 -->
      <!-- <div class="logodialogue" v-show="indialogVisible">
        <span>
          人们为了记录信息，发明了结绳记事的方法，这些大大小小的结则是人们用来回忆过去的唯一线索。结绳记事的出现让我们信息的传递可以突破时间与地域的限制，在漫长的历史长河中保留下来，让我们在今天仍然能够在博物馆中探寻几千年前发生的事情。
          然而仅凭结绳记事并不能进行远距离传输，实时通信仍然只能依靠面对面的近距离通信，距离拉远后就无法传递信，我们要如何进行远距离的信息传递呢？如何进行尽可能快速的远距离传递呢？
        </span>
        <div class="logodialoguex"></div>
      </div> -->
    </div>

    <!-- 记录完毕按钮 -->
    <div>
      <el-button type="primary" @click="Checkbut" class="record"
        >校&ensp;&ensp;&ensp;验</el-button
      >
    </div>

    <!-- 记录完毕弹出框 失败 -->
    <el-dialog v-model="dialogVisiblebox" title="小唐人提示" width="30%">
      <img
        style="width: 50%; margin-left: 24%"
        src="../../../assets/img/imgdevelopment/ancientpeople/failure.png"
        alt=""
      />
      <hr style="width: 90%; background-color: #e4e4e3; margin: 10px auto" />
      <span
        style="#000: red; text-align: left"
        v-for="(item, index) in succeedbox"
        :key="index"
      >
        <ol type="circle">
          <li>
            <h3 style="margin: 10px 10%">{{ index + 1 }}.{{ item }}</h3>
          </li>
        </ol>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            style="
              background: #0074c5;
              width: 30%;
              margin-right: 34%;
              font-size: 16px;
            "
            type="primary"
            @click="confirm('failure')"
            >确&ensp;&ensp;&ensp;定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 记录完毕弹出框 成功 -->
    <el-dialog v-model="dialogVisibleboxking" title="小唐人提示" width="30%">
      <img
        style="width: 50%; margin-left: 24%"
        src="../../../assets/img/imgdevelopment/ancientpeople/succeed.png"
        alt=""
      />
      <hr style="width: 90%; background-color: #e4e4e3; margin: auto" />
      <h3 style="margin: 2%">
        <span
          style="
            color: #ec4515;
            margin-left: 12.5%;
            font-size: calc(100vw * 20 / 1920);
          "
          >原始通信优点：</span
        >
        能突破时间、地域的限制传递信息
      </h3>
      <h3 style="">
        <span
          style="
            color: #40b489;
            margin-left: 14%;
            font-size: calc(100vw * 20 / 1920);
          "
          >原始通信缺点：</span
        >
        <p style="margin-left: 40%; margin-top: -4%">
          不能远距离快速传递信息，受绳结材质影响
        </p>
      </h3>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            style="
              background: #0074c5;
              width: 30%;
              margin-right: 34%;
              font-size: 16px;
            "
            type="primary"
            @click="confirm('success')"
            >确&ensp;&ensp;&ensp;定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getOriginal } from "../../../api/topics";
import { getOriginaltimes } from "../../../api/topics";
import { readCommunication } from "../../../api/topics";
import { PageDispense } from "../../../api/topics";
import { holographic } from "../../../api/topics";
export default {
  data() {
    return {
      task_type: null,
      dialogVisible: true,
      dialogVisibletow: false,
      dialogVisiblebox: false,
      indialogVisible: false,
      dialogVisibleboxking: false,
      indexof: null,
      initWidth: 0, // 父元素的宽-自适应值
      initHeight: 0, // 父元素的高-自适应值
      startclientX: 0, // 元素拖拽前距离浏览器的X轴位置
      startclientY: 0, //元素拖拽前距离浏览器的Y轴位置
      elLeft: 0, // 元素的左偏移量
      elTop: 0, // 元素的右偏移量
      picturelist: [
        {
          top: "42%",
          left: "38.5%",
          img: require("../../../assets/img/imgdevelopment/original/black.png"),
        },
        {
          left: "58.8%",
          top: "38%",
          img: require("../../../assets/img/imgdevelopment/original/red.png"),
        },
        {
          left: "58.8%",
          top: "49.5%",
          img: require("../../../assets/img/imgdevelopment/original/red.png"),
        },
        {
          left: "58.8%",
          top: "61%",
          img: require("../../../assets/img/imgdevelopment/original/red.png"),
        },
        {
          left: "80.5%",
          top: "49%",
          img: require("../../../assets/img/imgdevelopment/original/orange.png"),
        },
        {
          left: "80.5%",
          top: "60.6%",
          img: require("../../../assets/img/imgdevelopment/original/orange.png"),
        },
      ],
      pictureArray: [
        {
          top: "15%",
          left: "4.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/1.png"),
          id: "num1",
        },
        {
          top: "15%",
          left: "4.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/1.png"),
          id: "num1",
        },
        {
          top: "15%",
          left: "4.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/1.png"),
          id: "num1",
        },
        {
          top: "15%",
          left: "9.3%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/2.png"),
          id: "num2",
        },
        {
          top: "15%",
          left: "9.3%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/2.png"),
          id: "num2",
        },
        {
          top: "15%",
          left: "9.3%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/2.png"),
          id: "num2",
        },
        {
          top: "15%",
          left: "13.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/3.png"),
          id: "num3",
        },
        {
          top: "15%",
          left: "13.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/3.png"),
          id: "num3",
        },
        {
          top: "15%",
          left: "13.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/3.png"),
          id: "num3",
        },
        {
          top: "27.8%",
          left: "4.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/4.png"),
          id: "num4",
        },
        {
          top: "27.8%",
          left: "4.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/4.png"),
          id: "num4",
        },
        {
          top: "27.8%",
          left: "4.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/4.png"),
          id: "num4",
        },
        {
          top: "28%",
          left: "9.2%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/5.png"),
          id: "num5",
        },
        {
          top: "28%",
          left: "9.2%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/5.png"),
          id: "num5",
        },
        {
          top: "28%",
          left: "9.2%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/5.png"),
          id: "num5",
        },
        {
          top: "28%",
          left: "13.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/2jie.png"),
          id: "knot2",
        },
        {
          top: "28%",
          left: "13.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/2jie.png"),
          id: "knot2",
        },
        {
          top: "28%",
          left: "13.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/2jie.png"),
          id: "knot2",
        },
        {
          top: "41%",
          left: "4.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/4jie.png"),
          id: "knot4",
        },
        {
          top: "41%",
          left: "4.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/4jie.png"),
          id: "knot4",
        },
        {
          top: "41%",
          left: "4.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/4jie.png"),
          id: "knot4",
        },
        {
          top: "41%",
          left: "9.2%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/8jie.png"),
          id: "knot8",
        },
        {
          top: "41%",
          left: "9.2%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/8jie.png"),
          id: "knot8",
        },
        {
          top: "41%",
          left: "9.2%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/8jie.png"),
          id: "knot8",
        },
        {
          top: "41%",
          left: "13.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/33.png"),
          id: "knot33",
        },
        {
          top: "41%",
          left: "13.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/33.png"),
          id: "knot33",
        },
        {
          top: "41%",
          left: "13.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/33.png"),
          id: "knot33",
        },
        {
          top: "53.6%",
          left: "4.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/112.png"),
          id: "knot112",
        },
        {
          top: "53.6%",
          left: "4.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/112.png"),
          id: "knot112",
        },
        {
          top: "53.6%",
          left: "4.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/112.png"),
          id: "knot112",
        },
        {
          top: "53.6%",
          left: "9.2%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/114.png"),
          id: "knot114",
        },
        {
          top: "53.6%",
          left: "9.2%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/114.png"),
          id: "knot114",
        },
        {
          top: "53.6%",
          left: "9.2%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/114.png"),
          id: "knot114",
        },
        {
          top: "53.6%",
          left: "13.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/1jie.png"),
          id: "knot1",
        },
        {
          top: "53.6%",
          left: "13.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/1jie.png"),
          id: "knot1",
        },
        {
          top: "53.6%",
          left: "13.7%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/1jie.png"),
          id: "knot1",
        },
        {
          top: "66.8%",
          left: "4.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/bat.png"),
          id: "bat",
        },
        {
          top: "66.8%",
          left: "4.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/bat.png"),
          id: "bat",
        },
        {
          top: "66.8%",
          left: "4.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/bat.png"),
          id: "bat",
        },
        {
          top: "66.8%",
          left: "9.1%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/phoenix.png"),
          id: "phoenix",
        },
        {
          top: "66.8%",
          left: "9.1%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/phoenix.png"),
          id: "phoenix",
        },
        {
          top: "66.8%",
          left: "9.1%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/phoenix.png"),
          id: "phoenix",
        },
        {
          top: "66.8%",
          left: "13.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/pigeo.png"),
          id: "pigeon",
        },
        {
          top: "66.8%",
          left: "13.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/pigeo.png"),
          id: "pigeon",
        },
        {
          top: "66.8%",
          left: "13.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/pigeo.png"),
          id: "pigeon",
        },
        {
          top: "79.8%",
          left: "4.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/swallow.png"),
          id: "swallow",
        },
        {
          top: "79.8%",
          left: "4.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/swallow.png"),
          id: "swallow",
        },
        {
          top: "79.8%",
          left: "4.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/swallow.png"),
          id: "swallow",
        },
        {
          top: "79.8%",
          left: "9.1%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/pterosaur.png"),
          id: "pterosaur",
        },
        {
          top: "79.8%",
          left: "9.1%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/pterosaur.png"),
          id: "pterosaur",
        },
        {
          top: "79.8%",
          left: "9.1%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/pterosaur.png"),
          id: "pterosaur",
        },
        {
          top: "79.8%",
          left: "13.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/eagle.png"),
          id: "hawk",
        },
        {
          top: "79.8%",
          left: "13.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/eagle.png"),
          id: "hawk",
        },
        {
          top: "79.8%",
          left: "13.6%",
          imgsrc: require("../../../assets/img/imgdevelopment/original/eagle.png"),
          id: "hawk",
        },
      ],
      listdata: "",
      Imageid: "",
      boxindex: "", //盒子下标
      blackbox: [],
      redbox1: [],
      redbox2: [],
      redbox3: [],
      orangebox1: [],
      orangebox2: [],
      succeedbox: [],
      dialog1: "",
      dialog2: "",
      dialog3: "",
      showcode: true,
      thisindex1: null,
      thisindex2: null,
      thisindex3: null,
      thisindex4: null,
      thisindex5: null,
      thisindex6: null,
    };
  },
  methods: {
    // 点击小唐人后显示隐藏
    showif() {
      if (this.$refs.list.style.opacity == 0) {
        this.$refs.list.style.opacity = "1";
        this.$refs.list.style.display = "block";
      } else if (this.$refs.list.style.opacity == 1) {
        this.$refs.list.style.opacity = "0";
        this.$refs.list.style.display = "none";
      }
    },
    // 删除
    delteCliks(index) {
      const dragBox = document.querySelectorAll(".drag_box");
      const items = {
        top: this.picturelist[index].top,
        left: this.picturelist[index].left,
      };
      const arrdrag = Array.from(dragBox);
      arrdrag.map((item, indexs) => {
        if (dragBox[indexs].style.top === items.top) {
          dragBox[indexs].style.top = this.pictureArray[indexs].top;
          dragBox[indexs].style.left = this.pictureArray[indexs].left;
        }
      });
      this.picturelist[index].indexof = null;
      if (index == 0) {
        this.$refs.code[index].style.opacity = "0";
        this.$refs.code[index].style.display = "none";
        this.thisindex1 = null;
        this.listdata[1].answer[0] = "";
        if (this.task_type != "考试") {
          sessionStorage.removeItem("echoimg1");
          sessionStorage.removeItem("echobox1");
        } else if (this.task_type == "考试") {
          sessionStorage.removeItem("echoimg11");
          sessionStorage.removeItem("echobox11");
        }
      } else if (index == 1) {
        this.$refs.code[index].style.opacity = "0";
        this.$refs.code[index].style.display = "none";
        this.thisindex2 = null;
        this.listdata[0].answer[0] = "";
        if (this.task_type != "考试") {
          sessionStorage.removeItem("echoimg2");
          sessionStorage.removeItem("echobox2");
        } else if (this.task_type == "考试") {
          sessionStorage.removeItem("echoimg22");
          sessionStorage.removeItem("echobox22");
        }
      } else if (index == 2) {
        this.$refs.code[index].style.opacity = "0";
        this.$refs.code[index].style.display = "none";
        this.thisindex3 = null;
        this.listdata[0].answer[1] = "";
        if (this.task_type != "考试") {
          sessionStorage.removeItem("echoimg3");
          sessionStorage.removeItem("echobox3");
        } else if (this.task_type == "考试") {
          sessionStorage.removeItem("echoimg33");
          sessionStorage.removeItem("echobox33");
        }
      } else if (index == 3) {
        this.$refs.code[index].style.opacity = "0";
        this.$refs.code[index].style.display = "none";
        this.thisindex4 = null;
        this.listdata[0].answer[2] = "";
        if (this.task_type != "考试") {
          sessionStorage.removeItem("echoimg4");
          sessionStorage.removeItem("echobox4");
        } else if (this.task_type == "考试") {
          sessionStorage.removeItem("echoimg44");
          sessionStorage.removeItem("echobox44");
        }
      } else if (index == 4) {
        this.$refs.code[index].style.opacity = "0";
        this.$refs.code[index].style.display = "none";
        this.thisindex5 = null;
        this.listdata[2].answer[0] = "";
        if (this.task_type != "考试") {
          sessionStorage.removeItem("echoimg5");
          sessionStorage.removeItem("echobox5");
        } else if (this.task_type == "考试") {
          sessionStorage.removeItem("echoimg55");
          sessionStorage.removeItem("echobox55");
        }
      } else if (index == 5) {
        this.$refs.code[index].style.opacity = "0";
        this.$refs.code[index].style.display = "none";
        this.thisindex6 = null;
        this.listdata[2].answer[1] = "";
        if (this.task_type != "考试") {
          sessionStorage.removeItem("echoimg6");
          sessionStorage.removeItem("echobox6");
        } else if (this.task_type == "考试") {
          sessionStorage.removeItem("echoimg66");
          sessionStorage.removeItem("echobox66");
        }
      }
    },
    // 当拖拽到右边框中触发
    allowDrop(e, index) {
      e.preventDefault();
      if (this.indexof === index) {
        return;
      }
    },
    // 拖拽开始事件
    dragstart(e, index, id) {},
    // 拖拽完成事件
    dragend(e, index, id) {
      // const indcode = document.querySelector(".deldeteclaAll");
      // console.log(id);
      const dragBox = document.querySelectorAll(".drag_box");
      if (this.indexof || this.indexof == 0) {
        if (this.indexof == 0 && this.listdata[1].answer[0] == "") {
          dragBox[index].style.top = this.picturelist[this.indexof].top;
          dragBox[index].style.left = this.picturelist[this.indexof].left;
          this.$refs.code[0].style.opacity = "1";
          this.$refs.code[0].style.display = "block";
          this.thisindex1 = index;
          if (this.task_type != "考试") {
            sessionStorage.setItem("echoimg1", this.thisindex1);
          } else if (this.task_type == "考试") {
            sessionStorage.setItem("echoimg11", this.thisindex1);
          }
        }
        if (this.indexof == 1 && this.listdata[0].answer[0] == "") {
          dragBox[index].style.top = this.picturelist[this.indexof].top;
          dragBox[index].style.left = this.picturelist[this.indexof].left;
          this.$refs.code[1].style.opacity = "1";
          this.$refs.code[1].style.display = "block";
          this.thisindex2 = index;
          if (this.task_type != "考试") {
            sessionStorage.setItem("echoimg2", this.thisindex2);
          } else if (this.task_type == "考试") {
            sessionStorage.setItem("echoimg22", this.thisindex2);
          }
        }
        if (this.indexof == 2 && this.listdata[0].answer[1] == "") {
          dragBox[index].style.top = this.picturelist[this.indexof].top;
          dragBox[index].style.left = this.picturelist[this.indexof].left;
          this.$refs.code[2].style.opacity = "1";
          this.$refs.code[2].style.display = "block";
          this.thisindex3 = index;
          if (this.task_type != "考试") {
            sessionStorage.setItem("echoimg3", this.thisindex3);
          } else if (this.task_type == "考试") {
            sessionStorage.setItem("echoimg33", this.thisindex3);
          }
        }
        if (this.indexof == 3 && this.listdata[0].answer[2] == "") {
          dragBox[index].style.top = this.picturelist[this.indexof].top;
          dragBox[index].style.left = this.picturelist[this.indexof].left;
          this.$refs.code[3].style.opacity = "1";
          this.$refs.code[3].style.display = "block";
          this.thisindex4 = index;
          if (this.task_type != "考试") {
            sessionStorage.setItem("echoimg4", this.thisindex4);
          } else if (this.task_type == "考试") {
            sessionStorage.setItem("echoimg44", this.thisindex4);
          }
        }
        if (this.indexof == 4 && this.listdata[2].answer[0] == "") {
          dragBox[index].style.top = this.picturelist[this.indexof].top;
          dragBox[index].style.left = this.picturelist[this.indexof].left;
          this.$refs.code[4].style.opacity = "1";
          this.$refs.code[4].style.display = "block";
          this.thisindex5 = index;
          if (this.task_type != "考试") {
            sessionStorage.setItem("echoimg5", this.thisindex5);
          } else if (this.task_type == "考试") {
            sessionStorage.setItem("echoimg55", this.thisindex5);
          }
        }
        if (this.indexof == 5 && this.listdata[2].answer[1] == "") {
          dragBox[index].style.top = this.picturelist[this.indexof].top;
          dragBox[index].style.left = this.picturelist[this.indexof].left;
          this.$refs.code[5].style.opacity = "1";
          this.$refs.code[5].style.display = "block";
          this.thisindex6 = index;
          if (this.task_type != "考试") {
            sessionStorage.setItem("echoimg6", this.thisindex6);
          } else if (this.task_type == "考试") {
            sessionStorage.setItem("echoimg66", this.thisindex6);
          }
        }
      }
      this.indexof = null;
      this.Imageid = this.pictureArray[index].id;
    },
    drop(event, index) {
      this.indexof = index;
      this.boxindex = index;
      setTimeout(() => {
        // console.log(this.Imageid);
        if (this.boxindex == 0 && this.listdata[1].answer[0] == "") {
          this.blackbox = this.Imageid;
          this.$refs.code[index].style.opacity = "1";
          this.$refs.code[index].style.display = "block";
          this.listdata[1].answer[0] = this.blackbox;
          if (this.task_type != "考试") {
            sessionStorage.setItem("echobox1", this.blackbox);
          } else if (this.task_type == "考试") {
            sessionStorage.setItem("echobox11", this.blackbox);
          }
        } else if (this.boxindex == 1 && this.listdata[0].answer[0] == "") {
          this.redbox1 = this.Imageid;
          this.$refs.code[index].style.opacity = "1";
          this.$refs.code[index].style.display = "block";
          this.listdata[0].answer[0] = this.redbox1;
          if (this.task_type != "考试") {
            sessionStorage.setItem("echobox2", this.redbox1);
          } else if (this.task_type == "考试") {
            sessionStorage.setItem("echobox22", this.redbox1);
          }
        } else if (this.boxindex == 2 && this.listdata[0].answer[1] == "") {
          this.redbox2 = this.Imageid;
          this.$refs.code[index].style.opacity = "1";
          this.$refs.code[index].style.display = "block";
          this.listdata[0].answer[1] = this.redbox2;
          if (this.task_type != "考试") {
            sessionStorage.setItem("echobox3", this.redbox2);
          } else if (this.task_type == "考试") {
            sessionStorage.setItem("echobox33", this.redbox2);
          }
        } else if (this.boxindex == 3 && this.listdata[0].answer[2] == "") {
          this.redbox3 = this.Imageid;
          this.$refs.code[index].style.opacity = "1";
          this.$refs.code[index].style.display = "block";
          this.listdata[0].answer[2] = this.redbox3;
          if (this.task_type != "考试") {
            sessionStorage.setItem("echobox4", this.redbox3);
          } else if (this.task_type == "考试") {
            sessionStorage.setItem("echobox44", this.redbox3);
          }
        } else if (this.boxindex == 4 && this.listdata[2].answer[0] == "") {
          this.orangebox1 = this.Imageid;
          this.$refs.code[index].style.opacity = "1";
          this.$refs.code[index].style.display = "block";
          this.listdata[2].answer[0] = this.orangebox1;
          if (this.task_type != "考试") {
            sessionStorage.setItem("echobox5", this.orangebox1);
          } else if (this.task_type == "考试") {
            sessionStorage.setItem("echobox55", this.orangebox1);
          }
        } else if (this.boxindex == 5 && this.listdata[2].answer[1] == "") {
          this.orangebox2 = this.Imageid;
          this.$refs.code[index].style.opacity = "1";
          this.$refs.code[index].style.display = "block";
          this.listdata[2].answer[1] = this.orangebox2;
          if (this.task_type != "考试") {
            sessionStorage.setItem("echobox6", this.orangebox2);
          } else if (this.task_type == "考试") {
            sessionStorage.setItem("echobox66", this.orangebox2);
          }
        }
        // console.log(this.listdata);
      }, 10);
      // 调用VUEX保存数据
      this.$store.commit("sum_original", this.listdata);
    },
    //点击记录完毕校验传值
    Checkbut() {
      if (
        this.thisindex1 != null &&
        this.thisindex2 != null &&
        this.thisindex3 != null &&
        this.thisindex4 != null &&
        this.thisindex5 != null &&
        this.thisindex6 != null
      ) {
        // console.log(this.listdata);
        let datatimes = new FormData();
        datatimes.append("data", JSON.stringify(this.listdata));
        //调用接口
        getOriginaltimes(datatimes).then((res) => {
          this.succeedbox = res.data.data.msg;
          // console.log(res.data.data.sign);
          if (res.data.data.sign == false) {
            this.dialogVisiblebox = true;
          }
          if (res.data.data.sign == true) {
            this.dialogVisibleboxking = true;
          }
        });
      } else {
        this.$message.error("请先进行完整的记录！");
      }
    },
    // 点击确定清除数据
    confirm(val) {
      this.dialogVisiblebox = false;
      this.dialogVisibleboxking = false;
      const dragBox = document.querySelectorAll(".drag_box");

      setTimeout(() => {
        this.$nextTick(() => {
          if (this.thisindex1 != null) {
            dragBox[this.thisindex1].style.top = this.picturelist[0].top;
            dragBox[this.thisindex1].style.left = this.picturelist[0].left;
          }
          if (this.thisindex2 != null) {
            dragBox[this.thisindex2].style.top = this.picturelist[1].top;
            dragBox[this.thisindex2].style.left = this.picturelist[1].left;
          }
          if (this.thisindex3 != null) {
            dragBox[this.thisindex3].style.top = this.picturelist[2].top;
            dragBox[this.thisindex3].style.left = this.picturelist[2].left;
          }
          if (this.thisindex4 != null) {
            dragBox[this.thisindex4].style.top = this.picturelist[3].top;
            dragBox[this.thisindex4].style.left = this.picturelist[3].left;
          }
          if (this.thisindex5 != null) {
            dragBox[this.thisindex5].style.top = this.picturelist[4].top;
            dragBox[this.thisindex5].style.left = this.picturelist[4].left;
          }
          if (this.thisindex6 != null) {
            dragBox[this.thisindex6].style.top = this.picturelist[5].top;
            dragBox[this.thisindex6].style.left = this.picturelist[5].left;
          }
        });

        if(val == 'success' ){
          let data = "Replay?2;";
        let obj = new FormData();
        obj.append("data", data);
        holographic(obj).then((res) => {
          console.log(res);
        });
        }
      }, 100);
    },

    //调用接口
    // topically() {
    //   getOriginal().then((res) => {
    //     this.listdata = JSON.parse(res.data.data); //这个是正确的转换
    //     this.listdata[0].answer = ["", "", ""];
    //     this.listdata[1].answer = [""];
    //     this.listdata[2].answer = ["", ""];
    //     //题目
    //     this.dialog1 = this.listdata[0].question_stem;
    //     this.dialog2 = this.listdata[1].question_stem;
    //     this.dialog3 = this.listdata[2].question_stem;
    //     // console.log(this.listdata);
    //   });
    // },

    //调用接口
    interface() {
      let datatimes = new FormData();
      datatimes.append("user_id", this.$store.state.user_id);
      datatimes.append("data_sign", "communication_history");
      datatimes.append("task_id", this.$store.state.taskId);
      datatimes.append("template_id", this.$store.state.template_id);
      datatimes.append("value_sign", "original_communication");
      readCommunication(datatimes).then((res) => {
        // console.log(res)
        // console.log(res.data.data);
        this.listdata = res.data.data; //这个是正确的转换
        // console.log(this.listdata)
        this.listdata[0].answer = ["", "", ""];
        this.listdata[1].answer = [""];
        this.listdata[2].answer = ["", ""];
        //题目
        this.dialog1 = this.listdata[0].question_stem;
        this.dialog2 = this.listdata[1].question_stem;
        this.dialog3 = this.listdata[2].question_stem;
        // console.log(this.listdata);
      });
    },
    echo() {
      const indextim1 = sessionStorage.getItem("echobox1");
      const indextim2 = sessionStorage.getItem("echobox2");
      const indextim3 = sessionStorage.getItem("echobox3");
      const indextim4 = sessionStorage.getItem("echobox4");
      const indextim5 = sessionStorage.getItem("echobox5");
      const indextim6 = sessionStorage.getItem("echobox6");

      setTimeout(() => {
        this.$nextTick(() => {
          if (indextim1 != null) {
            this.listdata[1].answer[0] = indextim1;
            this.$refs.code[0].style.opacity = "1";
            this.$refs.code[0].style.display = "block";
          }
          if (indextim2 != null) {
            this.listdata[0].answer[0] = indextim2;
            this.$refs.code[1].style.opacity = "1";
            this.$refs.code[1].style.display = "block";
          }
          if (indextim3 != null) {
            this.listdata[0].answer[1] = indextim3;
            this.$refs.code[2].style.opacity = "1";
            this.$refs.code[2].style.display = "block";
          }
          if (indextim4 != null) {
            this.listdata[0].answer[2] = indextim4;
            this.$refs.code[3].style.opacity = "1";
            this.$refs.code[3].style.display = "block";
          }
          if (indextim5 != null) {
            this.listdata[2].answer[0] = indextim5;
            this.$refs.code[4].style.opacity = "1";
            this.$refs.code[4].style.display = "block";
          }
          if (indextim6 != null) {
            this.listdata[2].answer[1] = indextim6;
            this.$refs.code[5].style.opacity = "1";
            this.$refs.code[5].style.display = "block";
          }
        });
      }, 1000);
      const dragBox = document.querySelectorAll(".drag_box");
      const indexst1 = sessionStorage.getItem("echoimg1");
      const indexst2 = sessionStorage.getItem("echoimg2");
      const indexst3 = sessionStorage.getItem("echoimg3");
      const indexst4 = sessionStorage.getItem("echoimg4");
      const indexst5 = sessionStorage.getItem("echoimg5");
      const indexst6 = sessionStorage.getItem("echoimg6");
      this.thisindex1 = indexst1;
      this.thisindex2 = indexst2;
      this.thisindex3 = indexst3;
      this.thisindex4 = indexst4;
      this.thisindex5 = indexst5;
      this.thisindex6 = indexst6;
      setTimeout(() => {
        this.$nextTick(() => {
          if (indexst1 != null) {
            dragBox[indexst1].style.top = this.picturelist[0].top;
            dragBox[indexst1].style.left = this.picturelist[0].left;
          }
          if (indexst2 != null) {
            dragBox[indexst2].style.top = this.picturelist[1].top;
            dragBox[indexst2].style.left = this.picturelist[1].left;
          }
          if (indexst3 != null) {
            dragBox[indexst3].style.top = this.picturelist[2].top;
            dragBox[indexst3].style.left = this.picturelist[2].left;
          }
          if (indexst4 != null) {
            dragBox[indexst4].style.top = this.picturelist[3].top;
            dragBox[indexst4].style.left = this.picturelist[3].left;
          }
          if (indexst5 != null) {
            dragBox[indexst5].style.top = this.picturelist[4].top;
            dragBox[indexst5].style.left = this.picturelist[4].left;
          }
          if (indexst6 != null) {
            dragBox[indexst6].style.top = this.picturelist[5].top;
            dragBox[indexst6].style.left = this.picturelist[5].left;
          }
        });
      }, 1000);
    },
    echo2() {
      const indextim1 = sessionStorage.getItem("echobox11");
      const indextim2 = sessionStorage.getItem("echobox22");
      const indextim3 = sessionStorage.getItem("echobox33");
      const indextim4 = sessionStorage.getItem("echobox44");
      const indextim5 = sessionStorage.getItem("echobox55");
      const indextim6 = sessionStorage.getItem("echobox66");

      setTimeout(() => {
        this.$nextTick(() => {
          if (indextim1 != null) {
            this.listdata[1].answer[0] = indextim1;
            this.$refs.code[0].style.opacity = "1";
            this.$refs.code[0].style.display = "block";
          }
          if (indextim2 != null) {
            this.listdata[0].answer[0] = indextim2;
            this.$refs.code[1].style.opacity = "1";
            this.$refs.code[1].style.display = "block";
          }
          if (indextim3 != null) {
            this.listdata[0].answer[1] = indextim3;
            this.$refs.code[2].style.opacity = "1";
            this.$refs.code[2].style.display = "block";
          }
          if (indextim4 != null) {
            this.listdata[0].answer[2] = indextim4;
            this.$refs.code[3].style.opacity = "1";
            this.$refs.code[3].style.display = "block";
          }
          if (indextim5 != null) {
            this.listdata[2].answer[0] = indextim5;
            this.$refs.code[4].style.opacity = "1";
            this.$refs.code[4].style.display = "block";
          }
          if (indextim6 != null) {
            this.listdata[2].answer[1] = indextim6;
            this.$refs.code[5].style.opacity = "1";
            this.$refs.code[5].style.display = "block";
          }
        });
      }, 1000);
      const dragBox = document.querySelectorAll(".drag_box");
      const indexst1 = sessionStorage.getItem("echoimg11");
      const indexst2 = sessionStorage.getItem("echoimg22");
      const indexst3 = sessionStorage.getItem("echoimg33");
      const indexst4 = sessionStorage.getItem("echoimg44");
      const indexst5 = sessionStorage.getItem("echoimg55");
      const indexst6 = sessionStorage.getItem("echoimg66");
      this.thisindex1 = indexst1;
      this.thisindex2 = indexst2;
      this.thisindex3 = indexst3;
      this.thisindex4 = indexst4;
      this.thisindex5 = indexst5;
      this.thisindex6 = indexst6;
      setTimeout(() => {
        this.$nextTick(() => {
          if (indexst1 != null) {
            dragBox[indexst1].style.top = this.picturelist[0].top;
            dragBox[indexst1].style.left = this.picturelist[0].left;
          }
          if (indexst2 != null) {
            dragBox[indexst2].style.top = this.picturelist[1].top;
            dragBox[indexst2].style.left = this.picturelist[1].left;
          }
          if (indexst3 != null) {
            dragBox[indexst3].style.top = this.picturelist[2].top;
            dragBox[indexst3].style.left = this.picturelist[2].left;
          }
          if (indexst4 != null) {
            dragBox[indexst4].style.top = this.picturelist[3].top;
            dragBox[indexst4].style.left = this.picturelist[3].left;
          }
          if (indexst5 != null) {
            dragBox[indexst5].style.top = this.picturelist[4].top;
            dragBox[indexst5].style.left = this.picturelist[4].left;
          }
          if (indexst6 != null) {
            dragBox[indexst6].style.top = this.picturelist[5].top;
            dragBox[indexst6].style.left = this.picturelist[5].left;
          }
        });
      }, 1000);
    },
  },
  mounted() {
    // this.topically();
    this.interface(); //调用接口

    let taskId = this.$store.state.taskId;
    let taskInfoName = this.$store.state.template_name;
    let dataPageDispense = { taskId, taskInfoName };
    PageDispense(dataPageDispense).then((res) => {
      this.task_type = JSON.parse(res.data.taskInfo).task_type;
      if (this.task_type != "考试") {
        this.echo(); //回显接口
      } else if (this.task_type == "考试") {
        this.echo2(); //回显接口
      }
      console.log(this.task_type);
    });
  },
  created() {},
};
</script>

<style scoped lang="scss">
.basemap {
  width: 100%;
  /* height: 120%; */
  // position: relative;
  box-sizing: border-box;
  padding: 10px;
}
.baseimg {
  width: 100%;
  /* height: 100%; */
  position: relative;
  box-sizing: border-box;
  // padding: 15px;
}
.drag_box {
  width: 3.9%;
  position: absolute;
  z-index: 99;
  cursor: pointer;
}
// 元素图片的大小
.dragssimg {
  width: 100%;
}
// 元素盒子
.Box {
  width: 3.9%;
  height: 9%;
  position: absolute;
  z-index: 100;
  display: block;
  // border: 1px solid red;
}
// 清除图标
.deldetecla {
  width: 30%;
  position: absolute;
  left: 90%;
  top: -24%;
  cursor: pointer;
  display: none;
  opacity: 0;
}

.dialong3 {
  position: absolute;
  left: 92%;
  top: 10%;
  width: 6%;
  /* height: 22%; */
  cursor: pointer;
}
//记录完毕按钮
.record {
  cursor: pointer;
  position: absolute;
  left: 84%;
  top: 86%;
  width: 10%;
  height: 6%;
  background: #007cc2;
  border-radius: 6px;
  font-size: 18px;
}
/deep/ .el-dialog__header {
  background: #d7edfb !important;
  margin-right: 0 !important;
}

.backimg {
  width: 18%;
  height: 82%;
  position: absolute;
  left: 3%;
  top: 15%;
  border: 1px solid red;
}
.Checkbutton {
  position: absolute;
  left: 82%;
  top: 12%;
  width: 6%;
  z-index: 1000;
}
/deep/.el-dialog__headerbtn {
  display: none;
}

.logodialogue {
  position: absolute;
  right: 9%;
  top: 14%;
  width: 43%;
  background-color: #d7edfb;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 12px;
  font-size: 16px;
  letter-spacing: 1px;
  z-index: 999;
  text-indent: 2em;
}
.logodialoguex {
  position: absolute;
  top: 30%;
  right: -2%;
  width: 0px;
  height: 0px;
  border-top: 10px solid #d7edfb;
  border-left: 10px solid #d7edfb;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
h3 {
  font-size: calc(100vw * 16 / 1920);
}
h3 > span {
  font-size: 16px;
}
h3 > p {
  font-size: calc(100vw * 16 / 1920);
}
* {
  font-size: calc(100vw * 20 / 1920);
}
</style>
